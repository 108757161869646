export default {
  "wrapper": "ps",
  "badge-trial": "pF",
  "header": "pg",
  "list": "pD body-2",
  "main": "pV",
  "card": "pL",
  "container": "pT",
  "header-button": "pz",
  "error-text": "pa",
  "mt-16": "pH"
};
