/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BudgetsContentComponent extends Component {
  @tracked scrolledTop = true;

  @action updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
