/* import __COLOCATED_TEMPLATE__ from './frequency-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  FREQUENCY_CUSTOM_UNITS,
} from 'qonto/constants/direct-debit-subscriptions';
import {
  getLabelForFrequencyCustomUnit,
  getScheduleTypeLabel,
} from 'qonto/models/direct-debit-subscription';

export default class DirectDebitCollectionsSubscriptionFrequencySelectionComponent extends Component {
  @service intl;

  scheduleTypeOptions = Object.values(DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES)
    .filter(value => value !== DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF)
    .map(value => ({
      value,
      label: getScheduleTypeLabel(value, this.intl),
      clearable: value === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
    }));

  scheduleCustomUnitOptions = Object.values(FREQUENCY_CUSTOM_UNITS).map(value => ({
    value,
    label: getLabelForFrequencyCustomUnit(value, this.intl),
  }));

  get selectedScheduleTypeOption() {
    return this.scheduleTypeOptions.find(
      frequency => frequency.value === this.args.frequency.scheduleType
    );
  }

  get selectedScheduleCustomUnitOption() {
    return this.scheduleCustomUnitOptions.find(
      customUnit => customUnit.value === this.args.frequency.scheduleCustomUnit
    );
  }

  @action
  onUpdateScheduleType(scheduleTypeOption) {
    this.args.onUpdateFrequency({
      scheduleType: scheduleTypeOption?.value || DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY,
      scheduleCustomInterval: null,
      scheduleCustomUnit:
        scheduleTypeOption?.value === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM
          ? FREQUENCY_CUSTOM_UNITS.MONTHS
          : null,
    });
  }

  @action
  onUpdateScheduleCustomInterval(scheduleCustomInterval) {
    this.args.onUpdateFrequency({
      scheduleType: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
      scheduleCustomInterval: Number(scheduleCustomInterval),
      scheduleCustomUnit: this.args.frequency.scheduleCustomUnit,
    });
  }

  @action
  onUpdateScheduleCustomUnit(scheduleCustomUnit) {
    this.args.onUpdateFrequency({
      scheduleType: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
      scheduleCustomInterval: this.args.frequency.scheduleCustomInterval,
      scheduleCustomUnit: scheduleCustomUnit.value,
    });
  }
}
