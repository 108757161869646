/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  LottiePlayer,
  SearchFieldWithDebounce,
  SkeletonLoader,
  Spinner,
} from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { variation } from 'ember-launch-darkly';

import { InvoiceDetailsCard } from 'qonto/react/components/attachments/card/invoice-details-card';
import { InvoiceDetailsCardLoading } from 'qonto/react/components/attachments/loading/invoice-details-card-loading';

export default class AttachmentsAttachmentsSuggestedSidebarComponent extends Component {
  placeholderLine = SkeletonLoader.Line;
  placeholderBlock = SkeletonLoader.Block;
  spinner = Spinner;
  invoiceDetailsCard = InvoiceDetailsCard;
  invoiceDetailsCardLoading = InvoiceDetailsCardLoading;
  lottiePlayer = LottiePlayer;
  searchField = SearchFieldWithDebounce;

  @tracked scrolledTop = true;

  get displayPagination() {
    return this.args.totalCount && this.args.totalCount > 25 && !this.args.isSearchError;
  }

  get showEmptyResultsState() {
    let hasInvoices =
      Boolean(this.args.supplierInvoices?.length) || Boolean(this.args.receivableInvoices?.length);
    let hasFilters = Boolean(this.args.searchQuery) || Boolean(this.args.selectedPeriod);

    return !hasInvoices && hasFilters;
  }

  get maxDate() {
    return dayjs().toDate();
  }

  shouldShowSignus(invoice) {
    if (variation('experiment--boolean-ap-credit-notes') && !invoice?.isCreditNote) return false;
    return true;
  }

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
