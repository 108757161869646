/* import __COLOCATED_TEMPLATE__ from './payment-collection.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { RadioButtonControlled, RadioGroup } from '@repo/design-system-kit';

export default class DirectDebitCollectionsSubscriptionPaymentCollectionComponent extends Component {
  radioButtonControlled = RadioButtonControlled;
  radioGroup = RadioGroup;

  @action
  onChangePaymentOption(useExistingMandate) {
    this.args.onSelectMandate(useExistingMandate ? this.args.mandates[0] : null);
  }
}
