/* import __COLOCATED_TEMPLATE__ from './sort-cell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ClientsTableHeaderSortCellComponent extends Component {
  get sortField() {
    let { sortBy } = this.args;
    return sortBy.includes(':') ? sortBy.split(':')[0] : sortBy;
  }

  get isAscending() {
    let { sortBy } = this.args;
    return sortBy.includes('asc');
  }

  @action
  handleSortBy(sortBy) {
    let { handleSortBy } = this.args;

    //Revert the order when we click on the same column
    if (sortBy === this.sortField) {
      sortBy = this.isAscending ? sortBy.split(':')[0] : sortBy + ':asc';
    }

    return handleSortBy?.(sortBy);
  }
}
