/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import fade from 'ember-animated/transitions/fade';

export default class CounterpartiesFiltersComponent extends Component {
  fadeTransition = fade;

  get missingInformationSelected() {
    let { missingVAT, missingLabels } = this.args;

    return missingVAT && missingLabels;
  }

  get showClearFilters() {
    let { appliedFiltersCount, counterpartiesFound, searchQuery } = this.args;
    return (appliedFiltersCount || searchQuery) && !isNaN(counterpartiesFound);
  }

  @action
  toggleMissingInformationFilters() {
    let value = !this.missingInformationSelected;

    this.args.updateFilters({
      missingVAT: value,
      missingLabels: value,
    });
  }
}
