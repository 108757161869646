/* import __COLOCATED_TEMPLATE__ from './initial-trial.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class DiscoverUpsellInitialTrialCtaComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service subscriptionManager;
  @service organizationManager;
  @service sentry;

  constructor() {
    super(...arguments);
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.initTask.perform();
  }

  initTask = dropTask(async () => {
    if (!this.args.upsellTrigger) {
      return;
    }
    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code },
      } = await this.subscriptionManager.upgradeRecommendation(this.args.upsellTrigger);

      return { code, recurrence };
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get activeTrialRemainingDays() {
    return this.subscriptionManager.currentSubscription?.activeTrialRemainingDays;
  }

  get hasInitialTrialUpsell() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrialUpsell;
  }
}
