/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsSidebarComponent extends Component {
  @service abilities;
  get scopes() {
    return this.args.connection?.grantedScopes.split(' ') || [];
  }

  get showCta() {
    return this.abilities.can('revoke oauth');
  }
}
