/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DirectDebitCollectionsTableComponent extends Component {
  @service intl;

  get headerDateSortField() {
    return this.args.currentTab === 'completed' ? 'executed_at' : 'collection_date';
  }

  get headerDateTitle() {
    return this.args.currentTab === 'completed'
      ? this.intl.t('sdd-collections.table.headers.operation-date')
      : this.intl.t('sdd-collections.table.headers.due-date');
  }
}
