export default {
  "card": "En",
  "header": "Ei",
  "budget-caption": "EA caption",
  "amount-spent-section": "EY",
  "amount-spent-icon-container": "Eh",
  "amount-spent-title": "Ef title-3",
  "amount-budgeted-section": "EK",
  "amount-budgeted-title": "EG title-3"
};
