export default {
  "sidebar": "Fb",
  "sidebar-header": "Fy",
  "close-button": "FU",
  "setup-mode-content": "Fj",
  "edit-mode-content": "FW",
  "reminders-header": "Fu",
  "reminders-title-container": "gS",
  "reminders-title": "gc",
  "reminders-subtitle": "gq",
  "reminders-list": "gZ",
  "reminder": "gR",
  "reminder-due-date": "gQ",
  "upsell-button-container": "ge"
};
