/* import __COLOCATED_TEMPLATE__ from './main-menu.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DualNavMainMenuComponent extends Component {
  @service abilities;
  @service featuresManager;

  get lastTopItemId() {
    let { items } = this.args;

    return items.filter(item => item.position === 'top').at(-1)?.id;
  }

  get displayQontoPilot() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');

    return isQontoPilotFeatureEnabled && canUseQontoPilot;
  }
}
