/* import __COLOCATED_TEMPLATE__ from './supervisors-selection-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class CreateBudgetTeamSelectionForm extends Component {
  @service segment;
  @service intl;

  @tracked selectedMembers = [];
  @tracked selectedTeam = null;
  @tracked managers = [];

  constructor() {
    super(...arguments);
    this.assignSelectedTeam();
  }

  @action
  assignSelectedTeam() {
    let { team } = this.args.context;
    if (team) {
      let selectedTeam = this.args.eligibleTeams.find(t => {
        return t.team.id === team.id;
      });
      this.onTeamSelect(selectedTeam);
    }
  }

  @action
  onTeamSelect(team) {
    this.selectedTeam = team;
    this.selectedMembers = [];

    let managers = this.selectedTeam.memberships.map(member => {
      return {
        key: member.id,
        value: member.fullName,
        status: member.status,
      };
    });

    managers = this.selectedTeam.memberships.sort(sortByKey('fullName'));

    this.managers = managers;
    this.selectedMembers = managers.map(manager => manager.id);
    this.segment.track('budget-creation_team-selection_clicked');
    this.args.setSelectedTeam({ team: this.selectedTeam, members: this.selectedMembers });
  }

  @action
  onMembershipsSelect(membership) {
    let index = this.selectedMembers.find(id => id === membership.id);
    if (index) {
      this.selectedMembers = this.selectedMembers.filter(id => {
        return id !== membership.id;
      });
    } else {
      this.selectedMembers.push(membership.id);
    }

    this.selectedMembers = [...this.selectedMembers];
    this.args.setSelectedTeam({ team: this.selectedTeam, members: this.selectedMembers });
  }

  @action
  teamLabel(teamObject) {
    let membersCount = this.intl.t('team-budgets.creation.team-selection.team.members', {
      count: teamObject.team.activeMembershipsCount ?? 0,
    });

    if (!teamObject.team.activeMembershipsCount) {
      return htmlSafe(membersCount);
    }

    let managers = teamObject.team.hasMany('teamManagers').ids();
    let managersCount = this.intl.t('team-budgets.creation.team-selection.team.managers-label', {
      count: managers.length,
    });

    return htmlSafe(`${membersCount} - ${managersCount}`);
  }
}
