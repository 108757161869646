export default {
  "wrapper": "mW",
  "modal": "mu",
  "close-button": "pS",
  "content": "pc",
  "title": "pq title-1",
  "description": "pZ body-2",
  "slides-container": "pR",
  "slide": "pQ"
};
