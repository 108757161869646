/* import __COLOCATED_TEMPLATE__ from './copy-button.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

export default class CopyButton extends Component {
  @service toastFlashMessages;

  copyTask = dropTask(async () => {
    let { clipboardText, error, success, successLabel } = this.args;
    try {
      await window.navigator.clipboard.writeText(clipboardText);
      success?.();
      if (successLabel) {
        this.toastFlashMessages.toastSuccess(successLabel);
      }
    } catch {
      error?.();
    }
  });
}
