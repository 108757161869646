/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class DiscoverTrialSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service organizationManager;
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('plans_change_confirmation_success', {
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      recurrence: this.args.data.recurrence,
      target_plan: this.args.data.targetPlan.code,
      current_plan: this.args.data.currentPlan.code,
    });
  }
}
