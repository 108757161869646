/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

const POINTER_TYPE_TOUCH = 'touch';

export default class DualNavMainMenuLinkComponent extends Component {
  @tracked previousPointerType;

  constructor() {
    super(...arguments);
    let { item } = this.args;

    assert('<DualNav::MainMenu::Link /> must have an @item', item);
    assert('<DualNav::MainMenu::Link /> must have @item.label', item.label);
    assert('<DualNav::MainMenu::Link /> must have @item.link', item.link);
  }

  get shouldHideTooltip() {
    return (
      this.args.state === DUAL_NAV_STATES.EXPANDED ||
      this.previousPointerType === POINTER_TYPE_TOUCH
    );
  }

  @action
  handleClick(event) {
    let { closeDropdown, item } = this.args;
    event.preventDefault();

    item.link.transitionTo(event);
    if (closeDropdown) {
      closeDropdown();
    }
  }

  @action
  handlePointerUp(event) {
    this.previousPointerType = event.pointerType;
    let { onMainMenuLinkPointerUp, item } = this.args;

    onMainMenuLinkPointerUp(item);
  }
}
