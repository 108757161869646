export default {
  "row": "Tf",
  "active": "TK",
  "cell": "TG",
  "empty-cell": "Tr",
  "counterparty": "Tb",
  "counterparty-info": "Ty",
  "name": "TU body-1",
  "qonto": "Tj",
  "currency": "TW",
  "account-number": "Tu caption",
  "custom-labels": "zS",
  "label": "zc"
};
