export default {
  "wrapper": "wz",
  "active": "wa",
  "expanded": "wH",
  "fade-in": "wO",
  "hidden": "wm",
  "visible": "wp",
  "item-list": "wx",
  "item-list-slide-down": "ww",
  "dropdown-trigger": "wN overlay",
  "settings-dropdown-slide-down": "wv",
  "responsive": "wo",
  "is-selected": "wn",
  "is-active": "wi",
  "dropdown": "wA"
};
