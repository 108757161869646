/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import styles from 'qonto/components/card/color-picker.module.css';
import { CARD_DESIGNS, CARD_LEVELS } from 'qonto/constants/cards';

export default class CardColorPicker extends Component {
  @service abilities;
  @service intl;
  @tracked pickersGuid = guidFor(this);

  CARD_DESIGNS = CARD_DESIGNS;

  get designs() {
    switch (this.args.cardLevel) {
      case CARD_LEVELS.METAL:
        return [
          CARD_DESIGNS.METAL_MINERAL_GRAY_2024,
          CARD_DESIGNS.METAL_SAND_GOLD_2024,
          CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019,
        ];
      case CARD_LEVELS.PLUS:
      default:
        return [
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023,
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023,
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023,
        ];
    }
  }

  get checkedColor() {
    if (!this.args.cardDesign) {
      switch (this.args.cardLevel) {
        case CARD_LEVELS.METAL:
          return this.intl.t('cards.colors.metal.mineral-gray');
        case CARD_LEVELS.PLUS:
        default:
          return this.intl.t('cards.colors.plus.silver');
      }
    }
    switch (this.args.cardDesign) {
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return this.intl.t('cards.colors.metal.sand-gold');
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return this.intl.t('cards.colors.metal.mineral-gray');
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return this.intl.t('cards.colors.metal.graphite-black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return this.intl.t('cards.colors.plus.light-purple');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return this.intl.t('cards.colors.plus.black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023:
      default:
        return this.intl.t('cards.colors.plus.silver');
    }
  }

  getColorClassName(design) {
    switch (design) {
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return 'graphite-black';
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return 'mineral-gray';
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return 'sand-gold';
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return 'black';
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return 'lilac';
      default:
        return '';
    }
  }

  @action
  getColorA11yLabel(design) {
    switch (design) {
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return this.intl.t('cards.colors.metal.graphite-black');
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return this.intl.t('cards.colors.metal.mineral-gray');
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return this.intl.t('cards.colors.metal.sand-gold');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return this.intl.t('cards.colors.plus.black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return this.intl.t('cards.colors.plus.light-purple');
      default:
        return this.intl.t('cards.colors.plus.silver');
    }
  }

  @action
  onPickerClick(design, { currentTarget }) {
    this.pickers = document.getElementById(this.pickersGuid);

    if (this.args.isDisabled) {
      return;
    }

    currentTarget.classList.add(styles.checked);
    currentTarget.setAttribute('aria-current', true);

    this.pickers.querySelectorAll('button').forEach(elem => {
      if (elem !== currentTarget) {
        elem.classList.remove(styles.checked);
        elem.setAttribute('aria-current', false);
      }
    });

    this.args.onColorChange(design);
  }
}
