/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import colorSelector from 'qonto/utils/color-selector';

export default class CapitalDepositStakeholderCard extends Component {
  get avatarStyle() {
    let color = colorSelector(this.args.stakeholder.email);
    return htmlSafe(`background-color: ${color};`);
  }

  @action
  handleEdit(stakeholder, toggle) {
    this.args.onEdit(stakeholder);
    toggle();
  }

  @action
  handleDelete(stakeholder, toggle) {
    this.args.onDelete(stakeholder);
    toggle();
  }
}
