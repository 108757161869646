/* import __COLOCATED_TEMPLATE__ from './quick-access.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class DualNavSubMenuQuickAccessComponent extends Component {
  constructor() {
    super(...arguments);
    let { items } = this.args;

    assert('<DualNav::SubMenu::QuickAccess /> must have @items', items);
  }

  @action
  handleClick(item, event) {
    let { onClick } = this.args;

    item.link.transitionTo?.(event);
    onClick?.(item);
  }
}
