/* import __COLOCATED_TEMPLATE__ from './show-pin.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Spinner } from '@repo/design-system-kit';
import { dropTask, race, rawTimeout } from 'ember-concurrency';

import ENV from 'qonto/config/environment';
import { SmartPinOnBehalfDelivery } from 'qonto/lib/smart-on-delivery';

const issuerReference = 'QONTO';
const language = 'en';
const PIN_MODAL_TIMEOUT = 10000;

class PinError extends Error {
  spinner = Spinner;

  constructor(...params) {
    super(...params);
    this.name = 'PinError';
  }
}

export default class CardSidebarShowPinComponent extends Component {
  spinner = Spinner;

  @service cardsManager;
  @service modals;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sensitiveActions;
  @service sentry;

  #getPinFromProvider(data, signature) {
    let { demand_id, cardholder_identifier, provider_id, service_type, timestamp } = data;
    return new Promise((resolve, reject) => {
      let onPinComplete = pinCode => resolve(pinCode);
      let onPinError = error => reject(new PinError(`smart pin script error code ${error}`));

      SmartPinOnBehalfDelivery.GetPIN(
        // URL
        ENV.smartPinURL,
        // CardholderIdentifier
        cardholder_identifier,
        // RequestReference
        demand_id,
        // IssuerReference
        issuerReference,
        // ProviderID
        provider_id,
        // Base64Signature
        signature,
        // ServiceType
        service_type,
        // Language
        language,
        // TIME
        timestamp.toString(),
        // OnErrorFunction
        onPinError,
        // OnCompleteFunction
        onPinComplete
      );
    });
  }

  pinApiTask = dropTask(async () => {
    return await this.args.card.getPin();
  });

  async #showPinModal(pinCode) {
    let modal = this.modals.open('card/modals/pin-code', {
      pinCode,
    });

    let modalClosedCondition = await race([modal, rawTimeout(PIN_MODAL_TIMEOUT)]);
    // only close the modal if the promise resolved is because of the rawTimeout
    // otherwise we expect the modal to be closed by a user action
    if (modalClosedCondition !== 'modal-pin-code') {
      modal.close();
    }
  }

  showEncryptedPinTask = dropTask(async () => {
    try {
      let { card } = this.args;
      let pinCode = await this.cardsManager.getEncryptedPinTask.perform(card.id);
      this.segment.track('cards_side_panel_view_pin');

      // we don't want to await for the modal
      this.#showPinModal(pinCode);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  showPinTask = dropTask(async () => {
    this.segment.track('cards_side_panel_view_pin');
    try {
      await this.sensitiveActions.runTask.perform(this.pinApiTask);
      // cancelling the sca modal does not seem to cancel the task, so we need to check if it exists
      if (this.pinApiTask.lastSuccessful) {
        let { data, signature } = this.pinApiTask.lastSuccessful.value;
        let pinCode;
        if (data.demand_id === '_FAKE_' && data.cardholder_identifier === '_FAKE_') {
          pinCode = '0000';
        } else {
          pinCode = await this.#getPinFromProvider(data, signature);
        }

        // we don't want to await for the modal
        this.#showPinModal(pinCode);
      }
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let isUnprocessableError = error.status === 422;
      let message = isUnprocessableError
        ? this.intl.t('cards.errors.show-pin')
        : this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
      if (isUnprocessableError || error instanceof PinError) {
        /*
            422 errors in this case tend to mean that the request we're trying to make
            for this specific card was not accepted in some form by our provider, so we
            would like to track these cases to help us investigate and detect issues.

            Any error caused by the script will return with a specific error code, like `107`
            which we can also use to detecting issues.
          */
        this.sentry.captureException(error);
      }
    }
  });
}
