export default {
  "envelope": "zK",
  "email": "zG",
  "logo": "zr",
  "subject": "zb",
  "message": "zy",
  "payment-details": "zU",
  "contact": "zj",
  "detail": "zW",
  "cta": "zu"
};
