/* import __COLOCATED_TEMPLATE__ from './payment-link.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Spinner, ToggleButton } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { apiBaseURL, directDebitCollectionMandatesNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class DirectDebitCollectionsPaymentLinkComponent extends Component {
  spinner = Spinner;

  toggleButton = ToggleButton;

  @service organizationManager;
  @service toastFlashMessages;
  @service networkManager;
  @service sentry;
  @service segment;
  @service intl;

  @tracked activePreviewType = 'email';
  @tracked isLogoUploaded = false;

  @tracked emailSubject = this.intl.t(
    'sdd-collections.creation-flow.payment-link.email.subject.prefill',
    { language: this.clientLanguage, companyName: this.organization.name }
  );
  @tracked emailMessage = this.intl.t(
    'sdd-collections.creation-flow.payment-link.email.message.prefill',
    { language: this.clientLanguage }
  );
  @tracked formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);

  previewTypes = [
    {
      value: 'email',
      label: this.intl.t('sdd-collections.creation-flow.payment-link.tabs.email'),
    },
    {
      value: 'payment-page',
      label: this.intl.t('sdd-collections.creation-flow.payment-link.tabs.payment-page'),
    },
  ];

  get organization() {
    return this.organizationManager.organization;
  }

  get client() {
    return this.mandate.clientSnapshot;
  }

  get mandate() {
    return this.args.mandate;
  }

  get clientLanguage() {
    return this.client?.locale;
  }

  get clientEmail() {
    return this.client?.email;
  }

  get logo() {
    return this.organization.isDefaultAvatar === false ? this.organization.picture : null;
  }

  onCopyPaymentLink = dropTask(async () => {
    await window.navigator.clipboard.writeText(`https://pay.qonto.com/mandates/${this.mandate.id}`);

    this.toastFlashMessages.toastInfo(
      this.intl.t('sdd-collections.toasts.success.payment-link-copied')
    );
    if (this.args.isRecurringInvoices) {
      this.segment.track('recurring-invoices_payment-link_copied');
      return;
    }
    this.segment.track('incoming-direct-debit_payment-link_copied');
  });

  onSendEmailClick = dropTask(async () => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${directDebitCollectionMandatesNamespace}/direct_debit_collection_mandates/${this.mandate.id}/send_email`,
        {
          method: 'POST',
          data: JSON.stringify({
            data: {
              type: 'email',
              attributes: {
                subject: this.emailSubject,
                body: this.emailMessage,
              },
            },
          }),
        }
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);

      this.toastFlashMessages.toastError(
        this.intl.t('sdd-collections.toasts.error.email-not-sent')
      );

      return;
    }

    this.toastFlashMessages.toastSuccess(this.intl.t('sdd-collections.toasts.success.email-sent'));

    if (this.args.isRecurringInvoices) {
      this.segment.track('recurring-invoices_payment-link-email_sent');
    } else {
      this.segment.track('incoming-direct-debit_payment-link-email_sent');
    }

    this.args.onSendEmail();
  });

  onLogoUpload = dropTask(async logo => {
    this.isLogoUploaded = true;

    this.organization.avatar = logo;
    this.organization.isDefaultAvatar = false;

    await this.organization.save();
    await this.organization.getAvatar();
  });

  onLogoRemove = dropTask(async () => {
    this.organization.avatar = null;
    this.organization.isDefaultAvatar = true;

    await this.organization.save();
    await this.organization.getAvatar();
  });
}
