/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

export default class DualNavMainMenuBaseComponent extends Component {
  badge = Badge;

  get shouldShowItemDetails() {
    return this.args.state === DUAL_NAV_STATES.EXPANDED;
  }
}
