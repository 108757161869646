/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { ObfuscatedIban } from 'qonto/react/components/direct-debit-collections';

export default class DirectDebitCollectionsSidebarHeaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  ObfuscatedIban = ObfuscatedIban;
}
