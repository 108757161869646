/* import __COLOCATED_TEMPLATE__ from './category.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

export default class CardSettingsRestrictionCategoryComponent extends Component {
  checkbox = Checkbox;

  get isChecked() {
    let { checkedValues = [], value } = this.args;
    return checkedValues?.includes(value);
  }
}
