/* import __COLOCATED_TEMPLATE__ from './stakeholders-list.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { map } from 'macro-decorators';

import colorSelector from 'qonto/utils/color-selector';

export default class CapitalDepositLegalEntityStakeholdersListComponent extends Component {
  @service store;
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  @map('args.context.stakeholders', function (stakeholder) {
    let colorStyle = htmlSafe(`background-color: ${colorSelector(stakeholder.email)}`);
    return {
      colorStyle,
      stakeholder,
    };
  })
  stakeholders;

  chooseStakeholderTask = dropTask(async selectedStakeholder => {
    let stakeholder = selectedStakeholder ?? this.createStakeholder();
    this.args.context.stakeholder = stakeholder;

    if (!stakeholder.isNew) {
      try {
        await this.args.context.saveLegalEntityTask.linked().perform(this.args.context);
        this.args.transitionToNext();
      } catch (error) {
        if (error instanceof ForbiddenError) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          this.sentry.captureException(error);
        }
      }
    } else {
      this.args.transitionToNext();
    }
  });

  @action
  createStakeholder() {
    return this.store.createRecord('stakeholder', {
      organization: this.capitalIncreaseManager.organization,
      address: this.store.createRecord('address'),
    });
  }
}
