/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ConnectFiltersComponent extends Component {
  get hasFilters() {
    return this.args.selectedCategory || this.args.selectedStakeholder;
  }

  @action
  onCategorySelect(value) {
    this.args.onCategorySelect?.(value);
  }

  @action
  onStakeholderSelect(value) {
    this.args.onStakeholderSelect?.(value);
  }

  @action
  onFilterReset() {
    this.args.onFilterReset?.();
  }
}
