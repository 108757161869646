export default {
  "header-cell": "qx",
  "empty": "qw",
  "header-type": "qN",
  "header-rules": "qv",
  "header-date": "qo",
  "header-action": "qn",
  "header-content": "qi caption-bold",
  "active": "qA",
  "row-sidebar": "qY",
  "hidden": "qh",
  "animated": "qf",
  "fadein-item": "qK",
  "animated-cell": "qG"
};
