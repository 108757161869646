export default {
  "details": "TI",
  "top-details": "Tt",
  "name": "TM",
  "account-infobox": "TP",
  "row": "Tl",
  "icon": "TX",
  "account-number": "TC",
  "currency": "Tk",
  "activity-tag": "TJ",
  "activity-icon": "Ts",
  "edit-btn": "TF",
  "action-buttons-container": "Tg"
};
