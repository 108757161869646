/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class AttachmentsAttachmentViewerModalComponent extends Component {
  @action
  close(reason) {
    if (typeof reason !== 'string') {
      reason = 'close';
    }
    this.args.close(reason);
    this.args.data.transaction.reload();
  }
}
