/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { REQUEST_STATUS_COLORS } from 'qonto/constants/requests';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardRequestsTableItemComponent extends Component {
  @service notifierCounterManager;
  @service organizationManager;
  @service segment;
  @service sentry;

  @reads('organizationManager.organization.activeAccounts') activeAccounts;
  constructor() {
    super(...arguments);
    this.setBankAccountTask.perform().catch(ignoreCancelation);
  }

  get requestComponentName() {
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get requestAmount() {
    let { request } = this.args;
    switch (request.requestType) {
      case 'virtual_card':
        return { value: request.paymentMonthlyLimit, currency: request.currency };
      case 'flash_card':
        return { value: request.paymentLifespanLimit, currency: request.currency };
    }
  }

  setBankAccountTask = dropTask(async () => {
    try {
      let { currentAccount } = this.organizationManager;
      let bankAccount = await this.args.request.belongsTo('bankAccount').load();
      if (!bankAccount) {
        this.args.request.bankAccount = currentAccount;
      }
      return this.args.request.bankAccount;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get canShowAccountSelector() {
    return this.activeAccounts.length > 1;
  }

  get status() {
    let { status, displayedStatus } = this.args.request;

    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status],
    };
  }

  @action
  onBankAccountSelect(account) {
    this.args.onBankAccountSelect(this.args.request, account);
    this.setBankAccountTask.perform().catch(ignoreCancelation);
  }

  @action
  onBankAccountOpen() {
    this.segment.track('bank_account_picker_opened', {
      origin: 'cards',
    });
  }

  @action
  onBankAccountInteraction(event) {
    // Prevent the sidebar to open when interacting with the account select trigger
    event.stopPropagation();
  }

  onCardReject = dropTask(async () => {
    await this.args.handleQuickDecline.perform(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  onCardApprove = dropTask(async () => {
    await this.args.handleQuickApprove.perform(this.args.request);
    await this.notifierCounterManager.updateCounter();
  });

  @action
  onRowClick(id) {
    if (this.onCardApprove.isRunning || this.onCardReject.isRunning) {
      return;
    }

    this.args.highlightItem(id);
  }
}
