/* import __COLOCATED_TEMPLATE__ from './contribution.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class CapitalDepositStakeholderContributionComponent extends Component {
  amountField = AmountField;

  @service toastFlashMessages;
  @service intl;
  @service segment;

  @tracked showError = false;

  submitTask = dropTask(async stakeholder => {
    let { validations } = await stakeholder.validate({ on: ['capitalAmount'] });
    this.showError = true;

    if (validations.isValid) {
      try {
        this.showError = false;
        await this.args.context.saveStakeholderTask.perform(stakeholder);
        this.segment.track('kdeposit_owner_capital_shares_added');
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  @action
  updateCapitalAmount(value) {
    this.showError = false;
    this.args.context.stakeholder.capitalAmount = parseFloat(value);
  }
}
