export default {
  "header-cell": "aF",
  "type": "ag",
  "request-date": "aD",
  "amount": "aV",
  "quick-actions": "aL",
  "empty": "aT",
  "header-content": "az caption-bold",
  "active": "aa"
};
