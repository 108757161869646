/* import __COLOCATED_TEMPLATE__ from './pay-later-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  CONTRACT_STATUS,
  PAY_LATER_ELIGIBILITY_STATUSES,
  PAY_LATER_FLOW_ORIGIN,
  PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY,
} from 'qonto/constants/financing';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class DualNavPayLaterPromoBoxComponent extends Component {
  @service flowLinkManager;
  @service segment;
  @service financing;
  @service abilities;

  @tracked eligibilityResponse = null;

  constructor() {
    super(...arguments);

    this.handleFetchEligibility();
  }

  async handleFetchEligibility() {
    if (
      this.abilities.cannot('create pay later transfer in financing') ||
      safeLocalStorage.getItem(PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY)
    )
      return;

    try {
      this.eligibilityResponse = await this.financing.getLastPayLaterEligibility();
    } catch {
      this.eligibilityResponse = null;
    }
  }

  get shouldDisplayPayLaterPromoBox() {
    if (!this.eligibilityResponse) return false;

    let { eligibility, contractStatus } = this.eligibilityResponse;

    return (
      eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE &&
      contractStatus === CONTRACT_STATUS.SIGNED
    );
  }

  @action
  redirectToDedicatedFlow() {
    this.segment.track('pay-later-promotional-nav');
    this.flowLinkManager.transitionTo({
      name: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: PAY_LATER_FLOW_ORIGIN.PROMOTIONAL_CARD,
      },
    });
    safeLocalStorage.setItem(PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }
}
