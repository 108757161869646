/* import __COLOCATED_TEMPLATE__ from './supervisors-selector.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

import { SupervisorSelector } from 'qonto/react/components/budgets/create/supervisors-selection/supervisor-selector';

export default class BudgetsSupervisorsSelectorComponent extends Component {
  checkbox = Checkbox;
  supervisorSelector = SupervisorSelector;
}
