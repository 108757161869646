export default {
  "envelope": "HZ",
  "payment-page": "HR",
  "header": "HQ",
  "title": "He",
  "logo": "HB",
  "detail": "HE",
  "dummy-field": "Hd",
  "input": "HI",
  "input--select": "Ht",
  "input--readonly": "HM",
  "placeholder": "HP",
  "columns": "Hl",
  "mandate": "HX",
  "mandate-title": "HC",
  "icon-chevron": "Hk",
  "mandate-details": "HJ",
  "checkbox": "Hs",
  "secure-note": "HF",
  "icon-lock": "Hg",
  "cta": "HD"
};
