/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask, waitForProperty } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import window from 'ember-window-mock';

import { GMI_STATUSES_FAILED_STATUSES } from 'qonto/constants/gmi-solution-instance';

export default class ConnectionsInvoicesSidebarComponent extends Component {
  @service intl;
  @service abilities;
  @service gmiSolutionInstanceManager;
  @service store;

  constructor() {
    super(...arguments);
    this.gmiSolutionInstanceManager.subscribe();
  }

  fetchGmiSolutionTask = restartableTask(async () => {
    if (!variation('feature--bolean-gmi-async-flow')) return;

    await waitForProperty(this, 'args.connection.id');

    try {
      return await this.store.findRecord('gmi-solution-instance', this.args.connection.id, {
        reload: true,
      });
    } catch {
      // ignore
    }
  });

  get gmiSolutionInstance() {
    if (!this.args.connection?.id) return null;

    let gmiSolutionInstance = this.store
      .peekAll('gmi-solution-instance')
      .find(({ id }) => id === this.args.connection?.id);

    if (!gmiSolutionInstance) {
      gmiSolutionInstance = this.gmiSolutionTracked.value;
    }

    return gmiSolutionInstance;
  }

  get disclaimer() {
    if (!variation('feature--bolean-gmi-async-flow')) return null;

    if (
      !this.gmiSolutionInstance ||
      !GMI_STATUSES_FAILED_STATUSES.includes(this.gmiSolutionInstance.gmiStatus)
    ) {
      return null;
    }

    return {
      message: this.intl.t(
        'settings.connections.sidebar.qonto-permissions.invoice-retrieval.item1'
      ),
      troubleshootingUrl: this.gmiSolutionInstance.troubleshootingUrl,
      level: 'error',
    };
  }

  @action
  reconnect() {
    if (this.disclaimer) {
      this.popup = window.open(this.disclaimer.troubleShootingUrl, 'gmi', 'popup');
    }
  }

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item2'),
    ];
  }

  gmiSolutionTracked = trackedTask(this, this.fetchGmiSolutionTask, () => [
    this.args.connection?.id,
  ]);
}
