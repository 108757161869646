export default {
  "connection-sidebar-header": "Vn",
  "title": "Vi",
  "close": "VA",
  "api-client": "VY",
  "api-client-avatar": "Vh",
  "api-client-name": "Vf",
  "disclaimer": "VK",
  "expired": "VG",
  "connect-button": "Vr"
};
