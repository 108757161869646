/* import __COLOCATED_TEMPLATE__ from './infos.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

const FIELDS = ['legalName', 'legalNumber', 'capitalAmount'];

export default class CapitalDepositLegalEntityInfosComponent extends Component {
  amountField = AmountField;

  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sentry;

  @tracked isLegalNameValidationEnabled = false;
  @tracked isLegalNumberValidationEnabled = false;
  @tracked isAmountValidationEnabled = false;

  componentStepName = 'legalEntityInfos';

  nextStepTask = dropTask(async legalEntity => {
    let { validations } = await legalEntity.validate({ on: FIELDS });

    if (validations.isValid) {
      this.isLegalNameValidationEnabled = false;
      this.isLegalNumberValidationEnabled = false;
      this.isAmountValidationEnabled = false;

      legalEntity.set('didValidate', false);

      if (this.args.context.submitOnStep === this.componentStepName) {
        try {
          await this.args.context.saveLegalEntityTask.linked().perform(this.args.context);
          this.segment.track('kdeposit_owner_capital_shares_added');
          this.args.transitionToNext();
        } catch (error) {
          if (error instanceof ForbiddenError) {
            this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
          } else {
            this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
            this.sentry.captureException(error);
          }
        }
      } else {
        this.args.transitionToNext();
      }
    } else {
      this.isLegalNameValidationEnabled = true;
      this.isLegalNumberValidationEnabled = true;

      // Verify that the capitalAmount is in error state to properly display the input in error state
      this.isAmountValidationEnabled = validations.errors.some(
        item => item.attribute === 'capitalAmount'
      );
      legalEntity.set('didValidate', true);
    }
  });

  @action
  updateCapitalAmount(value) {
    this.isAmountValidationEnabled = false;
    this.args.context.legalEntity.capitalAmount = parseFloat(value);
  }
}
