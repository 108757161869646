export default {
  "infobox": "sc",
  "account-infobox": "sq sc",
  "check-sidebar-header": "sZ",
  "type": "sR",
  "validated": "sQ",
  "pending": "se",
  "received": "sB",
  "canceled": "sE",
  "declined": "sd",
  "refunded": "sI",
  "date": "st",
  "close": "sM",
  "column-layout": "sP",
  "container": "sl",
  "picto": "sX",
  "avatar": "sC",
  "status": "sk",
  "general": "sJ",
  "amount": "ss",
  "counterparty": "sF"
};
