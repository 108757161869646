export default {
  "row": "HO",
  "cell": "Hm body-2",
  "empty": "Hp",
  "cell-content": "Hx",
  "cell-content--amount": "Hw body-1",
  "icon": "HN",
  "description": "Hv",
  "tertiary": "Ho",
  "details": "Hn"
};
