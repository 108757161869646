/* import __COLOCATED_TEMPLATE__ from './vat.hbs'; */
import Component from '@glimmer/component';

import { VAT_RATES } from 'qonto/constants/vat';

const { NOT_AUTOMATIC, OTHER } = VAT_RATES;

export default class CounterpartiesSidebarVat extends Component {
  notSet = NOT_AUTOMATIC;

  get filteredValues() {
    return this.args.values?.filter(value => value !== NOT_AUTOMATIC && value !== OTHER);
  }
}
