/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardSidebarRestrictionsComponent extends Component {
  @service sentry;
  @service abilities;
  @service segment;
  @service intl;
  @service modals;
  @service subscriptionManager;
  @service toastFlashMessages;

  button = Button;
  badge = Badge;

  BADGE_TYPE = BADGE_TYPE;

  get showActiveDaysOption() {
    let activeDaysOptions = this.args.card?.activeDaysOption;
    let canViewRestrictions = this.abilities.can('update settings card');

    return activeDaysOptions || canViewRestrictions;
  }

  get showCategoryTagsOption() {
    if (this.abilities.cannot('view restrictive categories card')) {
      return false;
    }

    let hasCategoryTags = this.args.card?.hasCategoryTags;
    let canViewRestrictions = this.abilities.can('update settings card');

    return hasCategoryTags || canViewRestrictions;
  }

  get canResetSettings() {
    let canUpdateSettings = this.abilities.can('update settings card');
    let canViewAdvancedSettings = this.abilities.can('view restrictions card');
    return !canViewAdvancedSettings && canUpdateSettings;
  }

  get isCategoriesResetIconShown() {
    return this.args.card?.hasCategoryTags && this.canResetSettings;
  }

  get isDayResetIconShown() {
    return this.args.card?.activeDaysOption && this.canResetSettings;
  }

  get isUpsellShown() {
    return (
      !this.abilities.can('view restrictions card') && this.abilities.can('update subscription')
    );
  }

  @action
  onReset(resetType) {
    this.segment.track('reset_advanced-card-settings_clicked', {
      reset_type: resetType,
    });
    this.modals.open('card/modals/remove-restrictions', {
      card: this.args.card,
      resetType,
      title:
        resetType === 'days-of-use'
          ? this.intl.t('cards.modals.remove-restrictions.days.title')
          : this.intl.t('cards.modals.remove-restrictions.category.title'),
      description:
        resetType === 'days-of-use'
          ? this.intl.t('cards.modals.remove-restrictions.days.description')
          : this.intl.t('cards.modals.remove-restrictions.category.description'),
    });
  }

  onUpsellCardSettingsTask = dropTask(async () => {
    try {
      await this.subscriptionManager.upgradeWithRecommendation('card_advanced_settings');
      this.segment.track('upsell_advanced-card-settings_clicked');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
