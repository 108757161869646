/* import __COLOCATED_TEMPLATE__ from './animation-wait-wrapper.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class AnimationWaitWrapper extends Component {
  @service('-ea-motion') motionService;

  @tracked isEaAnimating = true;

  constructor() {
    super(...arguments);

    if (this.motionService.isAnimating) {
      this.waitUntilIdleTask.perform().catch(ignoreCancelation);
    } else {
      this.isEaAnimating = false;
    }
  }

  waitUntilIdleTask = dropTask(async () => {
    await this.motionService.waitUntilIdle.perform();

    this.isEaAnimating = false;
  });

  get shouldRender() {
    let isEnabled = this.args.isEnabled ?? true;

    return !isEnabled || !this.isEaAnimating;
  }
}
