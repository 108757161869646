export default {
  "hero-gradient": "Xs",
  "hero-wrapper": "XF",
  "container-text": "Xg",
  "title-with-badge": "XD",
  "recycled-badge": "XV",
  "hero-label": "XL",
  "hero-card": "XT",
  "svg-gradient": "Xz",
  "design": "Xa",
  "design-image-container": "XH",
  "design-image-overlay": "XO",
  "design-item": "Xm",
  "hero-title": "Xp",
  "hero-subtitle": "Xx",
  "metal-illustration": "Xw",
  "cards": "XN",
  "metal-illustration-old": "Xv",
  "rotating": "Xo",
  "verticaling": "Xn",
  "card": "Xi",
  "circle": "XA"
};
