export default {
  "sub-menu": "wU",
  "collapsed": "wj",
  "collapse-left": "wW",
  "responsive": "wu",
  "expanded": "NS",
  "no-slide": "Nc",
  "visible": "Nq",
  "hidden": "NZ",
  "group": "NR",
  "promo-box": "NQ",
  "promo-box-cash-flow": "Ne"
};
