/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

export default class BackLink extends Component {
  @action
  onBackButtonClick() {
    window.history.back();
  }
}
