/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Checkbox, Disclaimer } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';

export default class ByLawsUploadComponent extends Component {
  checkbox = Checkbox;

  disclaimerBlock = Disclaimer.Block;

  @service abilities;
  @service capitalIncreaseManager;
  @service modals;
  @service intl;
  @service segment;

  @tracked byLawDoesNotContainBankTerm = false;
  @tracked byLawDoesNotContainBankTermError = false;
  @tracked showError = false;

  get isProviderSourceContactEmailEmpty() {
    return isEmpty(this.capitalIncreaseManager.organization?.providerSourceContactEmail);
  }

  get localFiles() {
    return this.args.organization?.documents?.find(item => item.docType === 'bylaw')?.files;
  }

  get uploadTitle() {
    return `${this.intl.t('labels.upload_msg_address')} <br>
            ${this.intl.t('labels.upload_max_size', {
              maxSize: formatFileSize(this.intl, TEMPLATE_VALUES.byLawMaxSize),
            })}`;
  }

  get canViewEmailAccountantFeatureFlag() {
    return this.abilities.can('view email accountant organization');
  }

  get canViewAndHasProviderEmail() {
    return !this.isProviderSourceContactEmailEmpty && this.canViewEmailAccountantFeatureFlag;
  }

  @action
  toggleByLawDoesNotContainBankTermCheckbox() {
    this.byLawDoesNotContainBankTerm = !this.byLawDoesNotContainBankTerm;
    this.byLawDoesNotContainBankTermError = !this.byLawDoesNotContainBankTerm;
  }

  deleteLocalFileTask = task(async (close, { file }) => {
    await this.args.deleteLocalFile(file);
    close();
  });

  @action
  handleOpenFilePreview(file) {
    let { fileName: title, fileUrl, fileContentType } = file;

    this.modals.open('fullscreen/preview', {
      title,
      isFullScreenModal: true,
      fileUrl,
      fileContentType,
      deleteTask: this.deleteLocalFileTask,
      file,
    });
  }

  confirmTask = task(async () => {
    this.segment.track('kdeposit_bylaws_uploaded');

    let { validations } = await this.args.organization.validate({
      on: 'byLaws',
    });

    if (this.canViewEmailAccountantFeatureFlag) {
      if (this.isProviderSourceContactEmailEmpty && validations.isValid) {
        this.args.goNext();
      } else if (
        !this.isProviderSourceContactEmailEmpty &&
        validations.isValid &&
        this.byLawDoesNotContainBankTerm
      ) {
        this.args.goNext();
      } else {
        this.showError = true;
        this.byLawDoesNotContainBankTermError = true;
      }
    } else {
      if (validations.isValid) {
        this.args.goNext();
      } else {
        this.showError = true;
      }
    }
  });
}
