/* import __COLOCATED_TEMPLATE__ from './confirm-delete-modal.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class ConfirmDeleteModalComponent extends Component {
  @service intl;

  get translations() {
    return {
      title: this.intl.t('transactions.modals.delete-attachment.title'),
      description: this.intl.t('transactions.modals.delete-attachment.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transactions.modals.delete-attachment.delete-attachment'),
    };
  }

  get data() {
    return { ...this.translations, confirmTask: this.confirmTask };
  }

  confirmTask = dropTask(async () => {
    let { confirmTask, model, file } = this.args.data;

    await confirmTask.linked().perform(model, file);
    this.args.close();
  });
}
