/* import __COLOCATED_TEMPLATE__ from './counterparty-avatar.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import colorSelector from 'qonto/utils/color-selector';

export default class CounterpartyAvatar extends Component {
  get counterpartyFirstLetter() {
    return this.args.counterpartyName?.match(/\w/)?.[0];
  }

  get style() {
    return htmlSafe(`background-color: ${colorSelector(this.args.counterpartyName)}`);
  }
}
