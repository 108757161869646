/* import __COLOCATED_TEMPLATE__ from './cancel-subscription-modal.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class DirectDebitCollectionsModalsCancelSubscriptionModalComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;

  title;
  description;
  disclaimer;
  confirmBtnText;

  constructor() {
    super(...arguments);

    if (this.subscription.isPendingMandateSignature) {
      this.title = this.intl.t('sdd-collections.modals.cancel.pending-mandate.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.pending-mandate.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.cancel.pending-mandate');
    } else if (this.subscription.isOneOff) {
      this.title = this.intl.t('sdd-collections.modals.cancel.one-off.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.one-off.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.cancel.one-off');
    } else if (this.subscription.isRecurring) {
      this.title = this.intl.t('sdd-collections.modals.cancel.recurring.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.recurring.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.recurring.cancel');
      this.disclaimer = this.intl.t('sdd-collections.modals.cancel.recurring.disclaimer');
    }
  }

  get subscription() {
    return this.args.data.subscription;
  }

  get popupData() {
    return {
      title: this.title,
      description: this.description,
    };
  }
}
