/* import __COLOCATED_TEMPLATE__ from './insurance.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardComparisonModalInsurance extends Component {
  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get isPlusCard() {
    return this.args.cardLevel === CARD_LEVELS.PLUS;
  }
}
