export default {
  "bank-accounts": "cf l-app-content__wrapper",
  "header-wrapper": "cK",
  "scrolled-top": "cG",
  "header": "cr l-app-content__page-header",
  "statements-button": "cb btn",
  "create-account-button": "cy btn btn--primary",
  "make-transfer-button": "cU btn btn--primary",
  "main": "cj"
};
