/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import { VAT_RATES } from 'qonto/constants/vat';
import { formatIban } from 'qonto/utils/format-input';

export default class CounterpartyListItemComponent extends Component {
  @service intl;

  get isVatRateEmpty() {
    return isEmpty(this.args.counterparty.vatRate);
  }

  get areCustomLabelsEmpty() {
    return isEmpty(this.args.counterparty.labels);
  }

  get accountNumber() {
    let { accountNumber, accountType, iban } = this.args.counterparty;
    let isIban = accountType === ACCOUNT_TYPES.IBAN;

    let displayedAccountNumber = isIban ? iban : accountNumber;

    if (displayedAccountNumber.length <= 12) {
      return isIban ? formatIban(displayedAccountNumber) : displayedAccountNumber;
    }

    // extract the first 8 and last 4 chars of the iban or account number
    // to then format it with an ellipse in between
    return displayedAccountNumber.replace(/^(\S{8})\S*(\S{4})$/, (match, p1, p2) => {
      let firstPart = isIban ? formatIban(p1) : p1;
      return `${firstPart} ... ${p2}`;
    });
  }

  get vatRate() {
    let { vatRate } = this.args.counterparty;

    if (this.isVatRateEmpty) {
      return this.intl.t('counterparties.table.item.placeholder-empty');
    }

    if (vatRate === VAT_RATES.NOT_AUTOMATIC) {
      return this.intl.t('counterparties.table.item.placeholder-not-automatic');
    }

    return this.intl.formatNumber(vatRate / 100, {
      style: 'percent',
      minimumSignificantDigits: 1,
    });
  }

  get remainingLabels() {
    return this.args.counterparty.labels ? this.args.counterparty.labels.length - 1 : 0;
  }
}
