export default {
  "header": "qP",
  "header-type": "ql",
  "header-infos": "qX",
  "title": "qC",
  "close": "qk",
  "close-icon": "qJ",
  "subtitle": "qs",
  "date": "qF",
  "container": "qg",
  "picto": "qD",
  "icon": "qV",
  "general": "qL",
  "amount": "qT",
  "infobox": "qz"
};
