/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

export default class AttachmentsEmptyStateComponent extends Component {
  @service intl;

  get illustrationUrl() {
    return this.args.transaction.isBilling
      ? '/illustrations/attachments/pending.svg'
      : '/illustrations/attachments/empty-state.svg';
  }

  get emptyStateTitle() {
    if (this.args.transaction.isBilling) {
      return this.intl.t('attachment-viewer.bookkeeping.empty-state.invoice-fees.title');
    } else {
      return this.intl.t('attachment-viewer.bookkeeping.empty-state.attachment-not-required.title');
    }
  }

  get emptyStateSubtitle() {
    if (this.args.transaction.isBilling) {
      return `${this.intl.t(
        'attachment-viewer.bookkeeping.empty-state.invoice-fees.subtitle'
      )} ${dateToken({
        date: dayjs(this.args.transaction.emittedAt).endOf('month').add(1, 'day'),
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      })}`;
    } else {
      return this.intl.t(
        'attachment-viewer.bookkeeping.empty-state.attachment-not-required.subtitle'
      );
    }
  }
}
