/* import __COLOCATED_TEMPLATE__ from './multi-accounts.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const featuresOrderByTrigger = ['dedicatedBudget', 'independentExpenses', 'customizedInsights'];

export default class DiscoverBundleMultiAccountsComponent extends Component {
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  name = 'multi-accounts';

  localSlides = {
    customizedInsights: {
      title: this.intl.t('upsell.bundle-multi-accounts.customized-insights.title'),
      description: this.intl.t('upsell.bundle-multi-accounts.customized-insights.text'),
      img: {
        de: '/illustrations/upsell/de/customized-insights.png',
        en: '/illustrations/upsell/en/customized-insights.png',
        es: '/illustrations/upsell/es/customized-insights.png',
        fr: '/illustrations/upsell/fr/customized-insights.png',
        it: '/illustrations/upsell/it/customized-insights.png',
      },
    },
    dedicatedBudget: {
      title: this.intl.t('upsell.bundle-multi-accounts.dedicated-budget.title'),
      description: this.intl.t('upsell.bundle-multi-accounts.dedicated-budget.text'),
      img: {
        de: '/illustrations/upsell/de/dedicated-budget.png',
        en: '/illustrations/upsell/en/dedicated-budget.png',
        es: '/illustrations/upsell/es/dedicated-budget.png',
        fr: '/illustrations/upsell/fr/dedicated-budget.png',
        it: '/illustrations/upsell/it/dedicated-budget.png',
      },
    },
    independentExpenses: {
      title: this.intl.t('upsell.bundle-multi-accounts.independent-expenses.title'),
      description: this.intl.t('upsell.bundle-multi-accounts.independent-expenses.text'),
      img: {
        de: '/illustrations/upsell/de/independent-expenses.png',
        en: '/illustrations/upsell/en/independent-expenses.png',
        es: '/illustrations/upsell/es/independent-expenses.png',
        fr: '/illustrations/upsell/fr/independent-expenses.png',
        it: '/illustrations/upsell/it/independent-expenses.png',
      },
    },
  };

  get slides() {
    return featuresOrderByTrigger.map(key => {
      let { title, description, img } = this.localSlides[key];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
      };
    });
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      empty_state_name: this.name,
      price_plan,
      role,
    });

    this.args.transitionToNext?.();
  }
}
