export default {
  "rule-card": "Ve",
  "rule-content": "VB",
  "rule-options": "VE",
  "visible": "Vd",
  "dropdown-btn": "VI",
  "delete-btn": "Vt",
  "btn--tertiary": "VM",
  "delete-rule-btn": "VP",
  "transaction-estimate": "Vl"
};
