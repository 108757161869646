/* import __COLOCATED_TEMPLATE__ from './rule-card.hbs'; */
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class QontoHubDisplayRuleComponent extends Component {
  @service intl;
  @service toastFlashMessages;

  @tracked showOptionsButton;

  constructor() {
    super(...arguments);
    if (this.args.rule.trigger === 'transaction') {
      this.estimateAlertCountPerDayTask.perform().catch(ignoreCancelation);
    }
  }
  get amount() {
    return this.args.rule.value / 100;
  }

  estimateAlertCountPerDayTask = task(
    waitFor(async () => {
      try {
        let { estimate } = await this.args.rule.estimate();

        return estimate.average;
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

        if (!error.isAdapterError) {
          throw error;
        }
      }
    })
  );
}
