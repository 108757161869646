export default {
  "dual-nav": "xI",
  "header": "xt",
  "expanded": "xM",
  "collapsed": "xP",
  "responsive": "xl",
  "overlay": "xX",
  "visible": "xC",
  "footer": "xk"
};
