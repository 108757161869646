/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import computedDomiciliation from 'qonto/utils/computed-domiciliation';

export default class BankAccountMainComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service featuresManager;
  @service zendeskLocalization;

  @reads('featuresManager.flags.accountDetailsIntermediaryBic') isIntermediaryBicEnabled;

  get addressFirstPart() {
    return ['firstLine', 'secondLine', 'thirdLine']
      .map(addressPart => this.args.organization.address[addressPart])
      .filter(v => v)
      .join(', ');
  }

  get addressSecondPart() {
    let address = this.args.organization.address;
    return address.zipcode + ', ' + address.city + ' - ' + address.country;
  }

  get clipboardRIB() {
    let bankAccount = this.args.bankAccount;

    let parts = [
      { text: this.intl.t('bank_accounts.additional_details.bank'), data: 'bankCode' },
      { text: this.intl.t('bank_accounts.additional_details.agency'), data: 'branchCode' },
      { text: this.intl.t('bank_accounts.additional_details.account'), data: 'accountNumber' },
      { text: this.intl.t('bank_accounts.additional_details.key'), data: 'controlDigits' },
    ];

    return parts
      .map(({ text, data }) => bankAccount[data] && `${text}: ${bankAccount[data]}`)
      .filter(v => v)
      .join(`\n`);
  }

  @computedDomiciliation() bankDomiciliation;

  get correspondentDisclaimerText() {
    return this.intl.t('bank_accounts.correspondent_bank.body', {
      // keep link text on the same line as <a></a> to avoid extra spaces around the link text
      link: htmlSafe(
        `<a
          href=${this.intl.t('bank_accounts.correspondent_bank.link', {
            faqUrl: this.zendeskLocalization.getLocalizedArticle(4359548),
          })}
          target="_blank"
          rel="noopener noreferrer"
          data-test-link
         >${this.intl.t('bank_accounts.correspondent_bank.link-text')}</a>`
      ),
      htmlSafe: true,
    });
  }
}
