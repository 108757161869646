export default {
  "row": "av body-1",
  "cell": "ao",
  "empty": "an",
  "amount": "ai",
  "review": "aA",
  "cell-content": "aY",
  "icon": "ah",
  "name": "af",
  "name-text": "aK",
  "quick-actions": "aG",
  "account-select": "ar"
};
