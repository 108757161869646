/* import __COLOCATED_TEMPLATE__ from './mandate-settings-button.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ChecksMandateSettingsButton extends Component {
  @service router;

  @action
  goToMandateSettings() {
    if (!this.args.isLoading) {
      this.router.transitionTo('checks.mandate-settings');
    }
  }
}
