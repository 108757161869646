/* import __COLOCATED_TEMPLATE__ from './group.hbs'; */
import { assert } from '@ember/debug';
import Component from '@glimmer/component';

export default class DualNavSubMenuGroupComponent extends Component {
  constructor() {
    super(...arguments);
    let { items } = this.args;

    assert('<DualNav::SubMenu::Group /> must have @items', items);
  }
}
