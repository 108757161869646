/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

export default class DualNavSettingsItemComponent extends Component {
  get isHovered() {
    let { item, hoveredMenuItemId } = this.args;

    return hoveredMenuItemId === item.id;
  }

  get shouldShowArrowIcon() {
    let { state } = this.args;

    return state === DUAL_NAV_STATES.EXPANDED && this.isHovered;
  }
}
