/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

const POINTING_DEVICES = ['mouse', 'pen', 'touch'];

export default class DualNavMainMenuButtonComponent extends Component {
  defaultItem;

  constructor() {
    super(...arguments);
    let { item, onPointerEnter, onMainMenuButtonPointerUp, onMainMenuButtonKeyboardEvent } =
      this.args;

    assert('<DualNav::MainMenu::Button /> must have @item', item);
    assert('<DualNav::MainMenu::Button /> must have @item.label', item.label);
    assert(
      '<DualNav::MainMenu::Button /> must have @onMainMenuButtonPointerUp',
      onMainMenuButtonPointerUp
    );
    assert(
      '<DualNav::MainMenu::Button /> must have @onMainMenuButtonKeyboardEvent',
      onMainMenuButtonKeyboardEvent
    );
    assert('<DualNav::MainMenu::Button /> must have @onPointerEnter', onPointerEnter);

    this.defaultItem = this.args.item.groups.flatMap(({ items }) => items)[0];
  }

  get isHovered() {
    let { item, hoveredMenuItemId } = this.args;

    return hoveredMenuItemId === item.id;
  }

  get shouldHideTooltip() {
    let { item, state, activeMenuItemId } = this.args;
    return state !== DUAL_NAV_STATES.COLLAPSED || item.id === activeMenuItemId;
  }

  get shouldShowArrowIcon() {
    let { state } = this.args;

    return state === DUAL_NAV_STATES.EXPANDED && this.isHovered;
  }

  get isSelected() {
    let { item } = this.args;

    return this.args.activeMenuItemId === item.id;
  }

  @action
  handleKeyboardEvent(event) {
    let { onMainMenuButtonKeyboardEvent, item } = this.args;
    let isKeyboardNavigation = !POINTING_DEVICES.includes(event.pointerType);
    if (isKeyboardNavigation) {
      onMainMenuButtonKeyboardEvent(item);
    }
  }

  @action
  handlePointerUp() {
    let { onMainMenuButtonPointerUp, item, state } = this.args;

    if (state !== DUAL_NAV_STATES.RESPONSIVE) {
      this.defaultItem.link.transitionTo();
    }

    onMainMenuButtonPointerUp(item);
  }

  @action
  handlePointerEnter() {
    let { onPointerEnter, item } = this.args;
    onPointerEnter(item);
  }
}
