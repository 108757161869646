export default {
  "shadow": "ey",
  "placeholder-line": "eU",
  "placeholder-square": "ej",
  "loading": "eW",
  "name": "eu title-4",
  "iban": "BS caption",
  "transfers-button": "Bc",
  "cards-button": "Bq",
  "mandates-button": "BZ",
  "menu-item": "BR body-2",
  "close-account": "BQ",
  "kyc-disclaimer": "Be"
};
