/* import __COLOCATED_TEMPLATE__ from './digital-first.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

export default class CardModalsDigitalFirst extends Component {
  badge = Badge;

  @service intl;
  @service zendeskLocalization;

  get description3() {
    return this.intl.t('cards.modals.digital-first.description-3', {
      htmlSafe: true,
      // keep link text on the same line as <a></a> to avoid extra spaces around the link text
      link: htmlSafe(
        `<a
            href=${this.intl.t('cards.modals.digital-first.description-3-link-url', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(6952381),
            })}
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
          >${this.intl.t('cards.modals.digital-first.description-3-link-text')}</a>`
      ),
    });
  }
}
