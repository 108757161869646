export default {
  "row": "mH body-1",
  "active": "mO",
  "cell": "mm body-2",
  "empty": "mp",
  "no-padding": "mx",
  "cell-content": "mw",
  "item-disabled": "mN",
  "item-amount": "mv body-1",
  "centered-cell": "mo",
  "debitor": "mn",
  "item-counterparty-icon": "mi mr-16",
  "avatar": "mA",
  "item-counterparty-name": "mY",
  "item-counterparty-reference": "mh",
  "item-counterparty-name-status": "mf",
  "item-counterparty-name-text": "mK",
  "amount-on-hold": "mG",
  "icon-on-hold": "mr"
};
