export default {
  "header": "sG",
  "result": "sr",
  "result-value": "sb",
  "result-value-key": "sy",
  "result-value-icon": "sU",
  "green": "sj",
  "red": "sW",
  "result-value-description": "su",
  "result-title": "FS",
  "chevron": "Fc",
  "detail": "Fq",
  "detail-title": "FZ",
  "detail-value": "FR",
  "tooltip-wrapper": "FQ",
  "tooltip": "Fe",
  "disclaimer": "FB"
};
