/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CustomLabelsItem extends Component {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service segment;
  @service sentry;
  @service customLabelsManager;

  @tracked errorMessage = null;

  willDestroy() {
    super.willDestroy(...arguments);

    // if the item is destorying or destroyed we can skip cleanup
    // otherwise we will get an error in ember data 4.7 in integration tests:
    // "A record in a disconnected state cannot utilize the store."
    if (this.args.item.isDestroying || this.args.item.isDestroyed) return;
    this.args.item.rollbackAttributes();
  }

  get showInput() {
    return this.args.activeItem === this.args.item || this.args.item.isNew;
  }

  get type() {
    return {
      type: this.args.isCategory ? 'category' : 'label',
    };
  }

  @action
  handleAbort() {
    this.args.onFocus(null);
  }

  @action
  handleEdit() {
    this.errorMessage = null;
    this.args.onFocus(this.args.item);
  }

  saveLabelOnEnterTask = dropTask(async event => {
    if (event.key === 'Enter') {
      await this.saveLabelTask.perform();
    }
  });

  validateLabelTask = dropTask(async () => {
    let { validations } = await this.args.item.validate();
    this._displayValidation(validations);
  });

  saveLabelTask = dropTask(async () => {
    let { validations } = await this.args.item.validate();
    this.errorMessage = null;

    if (validations.isValid) {
      try {
        this.segment.track('custom_labels_create_button_clicked', this.type);
        await this.args.item.save();
        this.args.onFocus(null);
      } catch (error) {
        this.args.onFocus(null);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    } else {
      this._displayValidation(validations);
    }
  });

  modalConfirmTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.args.isCategory
        ? this.intl.t('organizations.analytics.custom-labels.category.delete-modal.title')
        : this.intl.t('organizations.analytics.custom-labels.label.delete-modal.title'),
      description: this.args.isCategory
        ? this.intl.t('organizations.analytics.custom-labels.category.delete-modal.body')
        : this.intl.t('organizations.analytics.custom-labels.label.delete-modal.body'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.customLabelsManager.modalConfirmTask,
      item: this.args.item,
      type: this.type,
      isCategory: this.args.isCategory,
    });
  });

  _displayValidation(validations) {
    if (validations.error?.type === 'presence') {
      this.errorMessage = this.intl.t('organizations.analytics.custom-labels.item.required');
    }

    if (validations.error?.type === 'length') {
      this.errorMessage = this.intl.t('organizations.analytics.custom-labels.item.max-char');
    }
  }
}
