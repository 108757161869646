export default {
  "row": "OU body-1",
  "active": "Oj",
  "cell": "OW body-2",
  "empty": "Ou",
  "no-padding": "mS",
  "cell-content": "mc",
  "debitor": "mq",
  "avatar": "mZ",
  "debitor-details": "mR",
  "debitor-name": "mQ",
  "reference": "me",
  "amount": "mB",
  "row--sidebar-open": "mE",
  "status": "md"
};
