export default {
  "wrapper": "xz",
  "header": "xa",
  "no-separator": "xH",
  "tabs": "xO",
  "tab": "xm body-1 overlay",
  "selected": "xp",
  "organization-list": "xx",
  "content": "xw",
  "empty-state": "xN",
  "illustration": "xv",
  "body": "xo body-1",
  "cta": "xn btn btn--tertiary"
};
