export default {
  "sidebar-header": "OE",
  "sidebar-header__top": "Od",
  "sidebar-header__close-button": "OI",
  "close-button": "Ot",
  "sidebar-header__body": "OM",
  "sidebar-header__content": "OP",
  "sidebar-header__date": "Ol",
  "infobox": "OX",
  "infobox--link": "OC",
  "ghost": "Ok",
  "fail": "OJ",
  "success": "Os"
};
