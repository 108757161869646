/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import ClientHubModel from 'qonto/models/client-hub';

export default class ClientsTableItem extends Component {
  @service segment;

  get hasReminders() {
    return this.args.item.hasReminders;
  }

  get hasMissingDetails() {
    if (this.args.item instanceof ClientHubModel) {
      return !this.args.item.validations.isValid;
    } else {
      return false;
    }
  }
}
