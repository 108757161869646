/* import __COLOCATED_TEMPLATE__ from './toggle-embossed.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CARD_PRINT_TYPES } from 'qonto/constants/cards';

export default class CardUToggleEmbossed extends Component {
  @tracked isEmbossed = this.args.typeOfPrint === CARD_PRINT_TYPES.EMBOSSED;

  @action
  toggleTypeOfPrint(isEmbossed) {
    this.isEmbossed = isEmbossed;
    this.args.onTypeOfPrintChange(isEmbossed ? CARD_PRINT_TYPES.EMBOSSED : CARD_PRINT_TYPES.PRINT);
  }
}
