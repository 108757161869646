/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { METER_AMOUNTS_THRESHOLDS, NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

export default class TeamCardComponent extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;

  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get supervisorList() {
    let managedBy = this.args.managedBy || [];
    let count = managedBy.meta?.total_count || 0;
    let translationVars = {
      count,
      managerNameOne: managedBy[0]?.fullName,
      managerNameTwo: managedBy[1]?.fullName,
      remaining: count - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (count === 2) {
      return this.intl.t('team-budgets.section.card.two-supervisors.label', translationVars);
    }

    return this.intl.t('team-budgets.section.card.supervisors.label', translationVars);
  }

  get isActive() {
    return this.args.budget.activePeriod?.amountBudgetedValue;
  }

  @action
  formatAmount(amount, currency = 'eur') {
    let integer = parseInt(amount, 10);
    let difference = Number(amount) - integer;
    let numberToFormat = difference > 0 ? amount : integer;

    return this.intl.formatNumber(numberToFormat, {
      minimumFractionDigits: difference > 0 ? 2 : 0,
      currency,
      style: 'currency',
    });
  }

  get percent() {
    let { budget } = this.args;
    let percent =
      (budget.activePeriod.amountSettledValue / budget.activePeriod.amountBudgetedValue) * 100;
    return Math.max(0, Math.min(100, percent));
  }

  get overLow() {
    return (
      this.percent >= METER_AMOUNTS_THRESHOLDS.LOW && this.percent < METER_AMOUNTS_THRESHOLDS.HIGH
    );
  }

  get overHigh() {
    return this.percent >= METER_AMOUNTS_THRESHOLDS.HIGH;
  }

  get encouragement() {
    let { budget } = this.args;
    if (budget.isActive) return '';
    if (budget.activePeriod) {
      if (this.abilities.can('update budget')) {
        return this.intl.t('team-budgets.section.card.no-budget-monthly', {
          month: budget.activePeriod.name,
        });
      } else {
        return this.intl.t('team-budgets.section.card.no-budget-manager-monthly', {
          month: budget.activePeriod.name,
        });
      }
    } else if (budget.nextExercise) {
      return this.intl.t('team-budgets.section.card.no-budget-next-period', {
        date: dateToken({
          date: budget.nextExercise.startDate,
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
        }),
      });
    } else if (this.abilities.can('update budget')) {
      return this.intl.t('team-budgets.section.card.no-budget-period-ended');
    } else {
      return this.intl.t('team-budgets.section.card.no-budget-manager-period-ended');
    }
  }

  get amountRemainingDisclaimer() {
    let { budget } = this.args;

    if (budget.activePeriod.amountRemainingValue > 0) {
      let amountRemaining = this.formatAmount(
        budget.activePeriod.amountRemainingValue,
        budget.activePeriod.amountBudgeted.currency
      );
      return this.intl.t('team-budgets.section.card.remaining', { amountRemaining });
    }

    return this.intl.t('team-budgets.section.card.remaining-no-budget');
  }
}
