/* import __COLOCATED_TEMPLATE__ from './account-receivable-onboarding-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const LOCAL_STORAGE_KEY = 'account-receivable-onboarding-promo-box-dismissed';

export default class DualNavAccountReceivableOnboardingPromoBoxComponent extends Component {
  badge = Badge;

  @service accountReceivableOnboardingManager;

  get shouldDisplay() {
    return (
      this.accountReceivableOnboardingManager.isNotEligibleForOnboarding &&
      !safeLocalStorage.getItem(LOCAL_STORAGE_KEY)
    );
  }

  @action
  handleOnClick() {
    safeLocalStorage.setItem(LOCAL_STORAGE_KEY, Date.now());
  }
}
