export default {
  "row": "Oi",
  "icon": "OA",
  "cell": "OY",
  "empty": "Oh",
  "no-padding": "Of",
  "cell-content": "OK",
  "right": "OG",
  "cube": "Or",
  "row--sidebar-open": "Ob",
  "status": "Oy"
};
