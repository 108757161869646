/* import __COLOCATED_TEMPLATE__ from './attachment-preview.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { match } from 'macro-decorators';

import { NonFinancialAttachmentPreview } from 'qonto/react/components/supplier-invoices/non-financial-attachment-preview';

export default class AttachmentPreviewComponent extends Component {
  nonFinancialAttachmentPreview = NonFinancialAttachmentPreview;
  spinner = Spinner;

  @match('args.attachmentType', /^image/) isImagePreview;
  @match('args.attachmentType', /pdf/) isPdfPreview;
  @match('args.attachmentType', /xml/) isXmlPreview;

  @tracked isIframeLoaded;

  get shouldRenderNonFinancialAttachmentPreview() {
    return this.args.isInvalidGermanEInvoice && this.isXmlPreview;
  }
}
