/* import __COLOCATED_TEMPLATE__ from './period-limit.hbs'; */
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class CardSettingsPeriodLimitComponent extends Component {
  @inject intl;

  datePicker = DatePicker;

  #parseDate(date) {
    let parsedDate = dayjs(date);
    return parsedDate.isValid() ? parseDate(parsedDate.format(DATE_PICKER_FIELD_FORMAT)) : null;
  }

  get dateValue() {
    return this.#parseDate(this.args.value);
  }

  get minDate() {
    return this.#parseDate(this.args.minDate);
  }

  get maxDate() {
    return this.#parseDate(this.args.maxDate);
  }

  get formattedValue() {
    return this.dateValue?.toString();
  }

  @action
  onChange(value) {
    this.args.onChange(value);
  }

  @action
  onChipChange(value) {
    this.args.onChange(this.#parseDate(value));
  }
}
