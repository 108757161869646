export default {
  "header-cell": "Oa",
  "empty": "OH",
  "right": "OO",
  "debitor": "Om",
  "frequency": "Op",
  "date": "Ox",
  "status": "Ow",
  "amount": "ON",
  "header-content": "Ov caption-bold",
  "active": "Oo",
  "row--sidebar-open": "On"
};
