/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import isFunction from 'qonto/utils/is-function';

export default class CardUpsellProvider extends Component {
  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  upsellTask = dropTask(
    async (
      card,
      address,
      shipToBusiness,
      cardUpsellLevel,
      onSuccess,
      cardUpsellDesign,
      cardUpsellTypeOfPrint
    ) => {
      try {
        let response = await this.cardsManager.fetchCardUpsellTask.perform(
          card,
          address.serialize(),
          shipToBusiness,
          cardUpsellLevel,
          cardUpsellDesign,
          cardUpsellTypeOfPrint
        );
        if (isFunction(onSuccess)) {
          onSuccess(response.upsellCard.id);
        }
      } catch (error) {
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    }
  );
}
