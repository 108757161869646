/* import __COLOCATED_TEMPLATE__ from './cockpit.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';

export default class DirectDebitCollectionsCockpit extends Component {
  @service intl;
  @service router;
  @service segment;

  get holdSubtitleText() {
    let { onHold } = this.args;

    if (onHold) {
      let fromText = this.intl.t('sdd-collections.cockpit.hold.subtitle.from');

      let mainText = this.intl.t('sdd-collections.cockpit.hold.subtitle.sdd-count-held', {
        count: onHold.count,
      });

      return `${fromText} ${mainText}`;
    }
  }

  get holdSubtitleTooltipText() {
    return this.intl.t('sdd-collections.cockpit.hold.tooltip', {
      guardingPeriodPercentage: this.intl.formatNumber(this.args.guardingPercentage, {
        minimumFractionDigits: 0,
        style: 'percent',
      }),
    });
  }

  @action
  onHoldTileClick() {
    this.router.transitionTo('direct-debit-collections.completed', {
      queryParams: {
        statusFilter: [DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD],
      },
    });

    this.segment.track('incoming-direct-debit_hold-cockpit_filtered');
  }
}
