export default {
  "attachment-viewer": "Zu",
  "sidebar": "RS",
  "attachment-viewer-details": "Rc",
  "attachment-viewer-filename": "Rq body-2",
  "attachment-viewer-actions": "RZ",
  "dropzone": "RR",
  "dropzone-visible": "RQ",
  "attachment-main": "Re"
};
