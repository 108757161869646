export default {
  "sidebar": "Tq",
  "scroll": "TZ",
  "header": "TR",
  "close": "TQ",
  "vat": "Te",
  "labels": "TB",
  "actions": "TE",
  "buttons-container": "Td"
};
