export default {
  "card": "IY",
  "avatar": "Ih",
  "content": "If",
  "content-top": "IK",
  "fullname": "IG",
  "representative": "Ir",
  "icon": "Ib",
  "type": "Iy",
  "menu-container": "IU",
  "dropdown-menu": "Ij",
  "menu-item": "IW body-2",
  "delete": "Iu"
};
