/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { generateConditionString } from 'qonto/utils/approval-workflow/condition';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ApprovalWorkflowsTableItem extends Component {
  @tracked conditionsString = '–';
  @service flowLinkManager;
  @service segment;
  @service intl;
  @service store;
  @service errors;

  constructor() {
    super(...arguments);
    this.generateConditionsStringTask.perform().catch(ignoreCancelation);
  }

  async generateConditionsString(conditions) {
    let allConditionsString = '';

    for (let [index, condition] of conditions.entries()) {
      let singleConditionString = await generateConditionString(
        condition,
        index,
        this.store,
        this.intl,
        this.errors
      );
      if (!singleConditionString) continue;

      allConditionsString = allConditionsString
        ? `${allConditionsString}, ${singleConditionString}`
        : singleConditionString;
    }

    return allConditionsString || '–';
  }

  get cellIcon() {
    let { workflow } = this.args;

    switch (workflow.requestType) {
      case 'transfer':
        return 'feature_transfer_direct-l';
      case 'reimbursement':
        return 'reimbursement';
      case 'supplier_invoice':
        return 'supplier-invoice';
    }
  }

  get cellTitle() {
    let { workflow } = this.args;
    switch (workflow.requestType) {
      case 'transfer':
        return this.intl.t('approval-workflows.table.item.transfer-requests');
      case 'reimbursement':
        return this.intl.t('approval-workflows.table.item.reimbursement-requests');
      case 'supplier_invoice':
        return this.intl.t('approval-workflows.table.item.supplier-invoice-requests');
    }
  }

  generateConditionsStringTask = dropTask(async () => {
    let { workflow } = this.args;
    let conditions = workflow.rulesets?.map(ruleset => ruleset.conditions[0]);

    this.conditionsString = await this.generateConditionsString(conditions);
  });

  get hasErrors() {
    let { workflow } = this.args;
    return workflow.workflowErrors?.length > 0;
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  onRowClick(id) {
    this.args.highlightItem(id);
  }

  @action
  onEditWorkflow() {
    this.segment.track('approval-section_workflow-list-edit-button_clicked');
    this.flowLinkManager.transitionTo({
      name: 'approval-workflows',
      stepId: 'builder',
      queryParams: { id: this.args.workflow.id },
    });
  }

  get shouldDisplayUpdateDate() {
    return !this.args.workflow.isDefault && this.args.workflow.updatedAt;
  }
}
