/* import __COLOCATED_TEMPLATE__ from './beneficiary-upload.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BeneficiaryUploadComponent extends Component {
  @tracked activityTag = null;

  @action
  handleDeleteFile(multiBeneficiary) {
    multiBeneficiary.set('file', null);
  }

  @action
  handleToggleCheck(beneficiary) {
    beneficiary.toggleProperty('selected');
  }

  @action
  handleActivityTagSelection(multiBeneficiary, activityTagCode) {
    this.activityTag = activityTagCode;
    multiBeneficiary
      .get('beneficiaries')
      .forEach(beneficiary => beneficiary.set('activityTag', activityTagCode));
  }
}
