/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

import {
  ApproverPlaceholder,
  RequesterPlaceholder,
} from 'qonto/react/components/card/requests-table/placeholder';

export default class CardRequestsTable extends Component {
  approverPlaceholder = ApproverPlaceholder;
  requesterPlaceholder = RequesterPlaceholder;
}
