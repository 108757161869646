export default {
  "modal": "Lw",
  "features": "LN",
  "close-button": "Lv",
  "header": "Lo title-2",
  "header-text": "Ln body-2",
  "feature": "Li",
  "subheader": "LA title-4",
  "text": "LY body-2",
  "cta": "Lh",
  "container-img": "Lf",
  "img": "LK",
  "link": "LG"
};
