/* import __COLOCATED_TEMPLATE__ from './language-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { sortByOrgLocale } from 'qonto/utils/sorters';

const mapLanguagesToObject = intl => code => ({
  code,
  label: intl.t(`bank_accounts.share.download.link-${code}`),
});

export default class LanguageSelectComponent extends Component {
  @service segment;
  @service intl;
  @service bankAccount;

  @tracked language = null;

  constructor() {
    super(...arguments);
    this.options = this.intl.getLocaleCodes
      .map(mapLanguagesToObject(this.intl))
      .sort((a, b) => sortByOrgLocale(a, b, this.organizationLocale));
  }

  get organizationLocale() {
    return this.args.bankAccount.organization.legalCountry.toLowerCase();
  }

  get selectedLanguage() {
    let languageCode = this.language || this.args.defaultLocale;
    return this.options.find(option => option.code === languageCode);
  }

  trackLocaleDownload(locale) {
    if (locale === this.organizationLocale) {
      this.segment.track('account_details_download_local');
    } else {
      this.segment.track(`account_details_download_${locale}`);
    }
  }

  @action
  handleUpdate(language) {
    this.language = language.code;
  }

  handleIbanDownloadTask = dropTask(async locale => {
    this.trackLocaleDownload(locale);
    await this.bankAccount.downloadIbanPdf(this.args.bankAccount.id, locale);
  });
}
