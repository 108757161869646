/* import __COLOCATED_TEMPLATE__ from './period-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

export default class PeriodSelectorComponent extends Component {
  @service intl;
  @service segment;

  get selectedPeriod() {
    return this.options.find(({ id: periodId }) => periodId === this.args.currentPeriodId);
  }

  @cached
  get options() {
    let { budget } = this.args;

    let sortedPeriods = this.#sortedPeriods(budget);

    return this.#generateFormattedPeriods({
      periods: sortedPeriods,
      isGlobal: budget.isGlobal,
    });
  }

  @action
  selectPeriod(period) {
    this.segment.track('budget-details_date-filter_selected');
    this.args.selectPeriod(period);
  }

  #sortedPeriods(budget) {
    let periods = budget.exercises.reduce(
      (periods, exercise) => [...periods, ...exercise.periods],
      []
    );

    return periods.sort((a, b) => {
      return dayjs(b.startDate).diff(a.startDate);
    });
  }

  #generateFormattedPeriods({ periods, isGlobal }) {
    return periods.map(period => {
      let startDate = dateToken({
        date: period.startDate,
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
      });

      if (isGlobal) {
        let endDate = dateToken({
          date: period.endDate,
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
        });

        let globalPeriod = this.intl.t('team-budgets.detail.header.global-period', {
          startDate,
          endDate,
        });

        return { id: period.id, name: globalPeriod };
      }

      return {
        id: period.id,
        name: startDate,
      };
    });
  }
}
