/* import __COLOCATED_TEMPLATE__ from './details-header.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class DirectDebitCollectionsDetailsHeaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  get shouldAddNoteFullStop() {
    return this.args.multiRequest?.note && this.args.multiRequest.note.slice(-1) !== '.';
  }
}
