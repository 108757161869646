export default {
  "header-cell": "Fs",
  "empty": "FF",
  "emitter": "Fg",
  "date": "FD",
  "amount": "FV",
  "status": "FL",
  "header-content": "FT caption-bold",
  "active": "Fz"
};
