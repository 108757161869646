/* import __COLOCATED_TEMPLATE__ from './email-form-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class AllowedEmailFormModal extends Component {
  @service intl;
  @service segment;

  @tracked email = this.args.data.allowedEmail.email;

  get title() {
    return this.args.data.allowedEmail.isNew
      ? this.intl.t('settings.invoice-receipt-email.add-email-modal.title')
      : this.intl.t('settings.invoice-receipt-email.edit-email-modal.title');
  }

  get hasErrors() {
    return (
      Boolean(this.saveEmailTask.performCount) &&
      this.args.data.allowedEmail.validations.attrs.email.isInvalid
    );
  }

  saveEmailTask = dropTask(async () => {
    this.segment.track('receipt-forward_email-whitelisted_clicked');
    await this.args.data.saveEmail(this.email, this.args.close);
  });

  @action
  valueChanged(event) {
    this.email = event.target.value;
  }

  @action
  willDestroy() {
    super.willDestroy();
    this.args.data.onClose?.();
  }
}
