export default {
  "row": "gV",
  "active": "gL",
  "cell": "gT",
  "name-cell": "gz",
  "email-cell": "ga",
  "actions-cell": "gH",
  "name-container": "gO",
  "quick-actions-wrapper": "gm",
  "empty": "gp",
  "name-icon-container": "gx",
  "icon": "gw",
  "tooltip-container": "gN",
  "missing-details-container": "gv",
  "missing-details-label": "go body-2",
  "warning-icon": "gn gw"
};
