export default {
  "header": "aZ",
  "header__main": "aR",
  "note": "aQ",
  "review-status": "ae",
  "reviewer-wrapper": "aB",
  "reviewer": "aE",
  "decline-note": "ad",
  "decline-note-title": "aI",
  "status-dot": "at",
  "approved": "aM",
  "rejected": "aP"
};
