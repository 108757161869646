/* import __COLOCATED_TEMPLATE__ from './group.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, timeout } from 'ember-concurrency';
import window from 'ember-window-mock';

const ANIMATION_IN_DURATION_MS = 260;
const ANIMATION_OUT_DURATION_MS = 210;

function transitionInSelectedOption(optionElement, startPosition) {
  optionElement.animate(
    [
      { zIndex: 2, transform: `translateX(${startPosition}px)` },
      { zIndex: 2, transform: 'translateX(0)' },
    ],
    {
      duration: ANIMATION_IN_DURATION_MS,
      easing: 'ease-in-out',
    }
  );
}

function transitionOutHiddenOption(optionElement, startPosition) {
  optionElement.animate(
    [
      {
        display: 'block',
        position: 'absolute',
        transform: `translateX(${startPosition}px)`,
        zIndex: 1,
        opacity: 1,
      },
      {
        display: 'block',
        position: 'absolute',
        transform: `translateX(${startPosition}px)`,
        zIndex: 1,
        opacity: 0,
      },
    ],
    {
      duration: ANIMATION_IN_DURATION_MS,
      easing: 'ease-in-out',
    }
  );
}

function transitionInCollapsedContent(collapsedContent) {
  collapsedContent.animate(
    [
      { position: 'relative', zIndex: 1, opacity: 0 },
      { position: 'relative', zIndex: 1, opacity: 1 },
    ],
    {
      duration: ANIMATION_IN_DURATION_MS,
      easing: 'ease-in-out',
    }
  );
}

export default class CollapseOptionsGroupComponent extends Component {
  @tracked clearing = false;
  @tracked showing = false;

  groupId = crypto.randomUUID();

  get element() {
    return document.querySelector(`[data-collapse-group="${this.groupId}"]`);
  }

  get collapsed() {
    let { value, clearable } = this.args.selectedOption || {};

    if (this.args.hideCustomInputs) return false;

    return Boolean(clearable && value);
  }

  get wrap() {
    return this.args.allowWrapping && !this.collapsed;
  }

  selectOptionTask = dropTask(async option => {
    let { value, clearable } = option;
    let { element } = this;

    this.args.update(option);
    let reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (value !== 0 && clearable && !reduceMotion.matches) {
      let options = Array.from(element.querySelectorAll('[data-collapse-option]'));
      let startPositions = options.map(optionElement => optionElement.offsetLeft);

      options.forEach((optionElement, i) => {
        let startPosition = startPositions[i];
        if (optionElement.dataset.collapseOption === String(value)) {
          transitionInSelectedOption(optionElement, startPosition);
        } else {
          transitionOutHiddenOption(optionElement, startPosition);
        }
      });

      let collapsedContent = element.querySelector('[data-collapsed-content]');
      if (collapsedContent) {
        transitionInCollapsedContent(collapsedContent);
      }

      await timeout(ANIMATION_IN_DURATION_MS);
    }
  });

  clearOptionTask = dropTask(async option => {
    let { value } = option;

    if (value !== 0) {
      this.clearing = true;
      await timeout(ANIMATION_OUT_DURATION_MS);
    }

    this.args.update({});

    if (value !== 0) {
      this.showing = true;
      this.clearing = false;

      await timeout(ANIMATION_OUT_DURATION_MS);
      this.showing = false;
    }

    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.args.clear?.();
  });
}
