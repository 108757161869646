export default {
  "not-active": "EB",
  "title": "EE",
  "details": "Ed",
  "detail": "EI",
  "text-secondary": "Et",
  "text-primary-a": "EM",
  "amounts": "EP",
  "contents-container": "El",
  "contents": "EX",
  "menu-item": "EC",
  "archive": "Ek",
  "amount-settled": "EJ",
  "shadow": "Es",
  "name": "EF title-4",
  "tooltip": "Eg"
};
