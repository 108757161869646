/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ACCOUNT_TYPE } from 'qonto/constants/bank-account';

export default class BankAccountsCreateComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;

  @tracked account;
  @tracked value = null;
  @tracked error = null;

  createTask = dropTask(async () => {
    let { account } = this.args.context;
    account.addIdempotencyHeader();

    this.error = this.checkError();
    if (!this.error) {
      try {
        account.name = this.value;
        await account.save();
        this.segment.track('accounts_new_account_created', {
          account_type: ACCOUNT_TYPE.CURRENT,
        });
      } catch (error) {
        let is403Error = error.errors.some(err => err.status === '403');
        let message = is403Error
          ? this.intl.t('toasts.errors.forbidden_error')
          : this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
        return;
      } finally {
        account.removeIdempotencyHeader();
      }
      await this.organizationManager.updateBankAccounts();
      this.args.transitionToNext();
    }
  });

  @action
  updateField(value) {
    this.value = value?.trim();
    this.error = this.checkError();
  }

  checkError() {
    return !this.value?.trim() ? this.intl.t('validations.errors.presence') : null;
  }
}
