export default {
  "header-cell": "mI",
  "empty": "mt",
  "right": "mM",
  "debitor": "mP",
  "date": "ml",
  "status": "mX",
  "amount": "mC",
  "header-amount": "mk",
  "header-content": "mJ caption-bold",
  "active": "ms"
};
