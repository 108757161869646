/* import __COLOCATED_TEMPLATE__ from './withdrawal-limit.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

import { FORMAT_PRICE_OPTIONS, MIN_WITHDRAWAL_LIMIT } from 'qonto/constants/cards';

export default class CardSettingsWithdrawalLimitComponent extends Component {
  @service intl;

  @tracked isAutoFocusDisabledDaily = true;

  changeset = new Changeset(this.args.card);

  get monthlyLimitError() {
    let amount = this.changeset.atmMonthlyLimit;
    let { maxMonthlyLimit } = this;

    if (amount > maxMonthlyLimit) {
      return this.intl.t('cards.settings.withdrawal-limit.error.too-high-limit', {
        amount: this.formatWithdrawalLimit(maxMonthlyLimit),
      });
    }

    if (amount < MIN_WITHDRAWAL_LIMIT) {
      return this.intl.t('cards.settings.withdrawal-limit.error.too-low-limit', {
        amount: this.formatWithdrawalLimit(MIN_WITHDRAWAL_LIMIT),
      });
    }
  }

  get dailyLimitError() {
    let amount = this.changeset.atmDailyLimit;
    let isChecked = this.changeset.atmDailyLimitOption;

    let { maxDailyLimit } = this;
    if (amount > maxDailyLimit) {
      return this.intl.t('cards.settings.error.daily-limit-higher-than-monthly');
    } else if (isChecked && amount === null) {
      return this.intl.t('validations.errors.blank');
    }
  }

  get cardLimitsOptions() {
    return this.args.cardsMaxLimits[this.args.card.cardLevel];
  }

  get maxMonthlyLimit() {
    return this.cardLimitsOptions.atm_monthly_limit_maximum;
  }

  get maxDailyLimit() {
    let { changeset } = this;
    return changeset.atmMonthlyLimit < this.maxMonthlyLimit
      ? changeset.atmMonthlyLimit
      : this.maxMonthlyLimit;
  }

  get hasError() {
    let { changeset } = this;
    let hasInvalidDailyLimit =
      changeset.atmDailyLimitOption &&
      (this.dailyLimitError || typeof changeset.atmDailyLimit !== 'number');

    return (
      hasInvalidDailyLimit ||
      this.monthlyLimitError ||
      typeof changeset.atmMonthlyLimit !== 'number'
    );
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    if (!this.args.disablePersistingState && !this.hasError) {
      this.changeset.execute();
    }
  }

  @action
  setProperty(prop, val) {
    this.changeset.set(prop, val);
    this.persistState();
  }

  @action
  toggleDailyLimit() {
    let { changeset } = this;
    let toggleTo = !this.changeset.atmDailyLimitOption;
    changeset.set('atmDailyLimit', toggleTo ? this.maxDailyLimit : undefined);
    changeset.set('atmDailyLimitOption', toggleTo);
    this.isAutoFocusDisabledDaily = false;
    this.persistState();
  }

  submitTask = dropTask(async () => {
    if (this.hasError) return;

    this.changeset.execute();
    await this.args.onSubmit();
  });

  formatWithdrawalLimit(limit) {
    return this.intl.formatNumber(limit, FORMAT_PRICE_OPTIONS);
  }
}
