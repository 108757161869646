export default {
  "container": "lP",
  "is-loaded": "ll",
  "disabled": "lX",
  "image": "lC",
  "infos": "lk",
  "fully-masked": "lJ",
  "black": "ls",
  "infos-type--virtual": "lF",
  "infos-holder": "lg",
  "infos-pan": "lD",
  "infos-pan-numbers": "lV",
  "infos-exp-date": "lL",
  "infos-cvv": "lT",
  "infos-caption": "lz",
  "copy-btn": "la",
  "description-details": "lH"
};
