/* import __COLOCATED_TEMPLATE__ from './address-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class AddressForm extends Component {
  @service intl;
  @service store;

  address = null;
  initialAddress = {};
  lineMaxLength = 38;

  @reads('address.didValidate') enableAddressValidations;

  @tracked isLine1Valid = true;
  @tracked isLine2Valid = true;
  @tracked isLine1Focused = false;
  @tracked isLine2Focused = false;

  constructor() {
    super(...arguments);
    let alreadyCreatedAddress = this.args.customShippingAddress;
    this.address = alreadyCreatedAddress || this.store.createRecord('address');
    if (alreadyCreatedAddress) {
      this.initialAddress = {
        zipcode: alreadyCreatedAddress.zipcode,
        country: alreadyCreatedAddress.country,
        city: alreadyCreatedAddress.city,
        firstLine: alreadyCreatedAddress.firstLine,
        secondLine: alreadyCreatedAddress.secondLine,
      };
    }
  }

  get line1Message() {
    let { message: validationErrorMessage } = this.address.validations.attrs.firstLine;

    let tooLongErrorMessage =
      !this.isLine1Valid &&
      this.intl.t('cards.steps.choose-delivery-address.new-address.error.too-long');

    if (this.isLine1Focused) {
      if (this.enableAddressValidations && (validationErrorMessage || tooLongErrorMessage)) {
        return {
          message: validationErrorMessage || tooLongErrorMessage,
          messageLevel: 'error',
        };
      } else {
        return {
          message: this.intl.t('cards.steps.choose-delivery-address.new-address.hint.not-too-long'),
          messageLevel: 'hint',
        };
      }
    }

    return {
      message: this.enableAddressValidations && (validationErrorMessage || tooLongErrorMessage),
      messageLevel: 'error',
    };
  }

  get line2Message() {
    if (this.enableAddressValidations && !this.isLine2Valid) {
      return {
        message: this.intl.t('cards.steps.choose-delivery-address.new-address.error.too-long'),
        messageLevel: 'error',
      };
    } else if (this.isLine2Focused) {
      return {
        message: this.intl.t('cards.steps.choose-delivery-address.new-address.hint.not-too-long'),
        messageLevel: 'hint',
      };
    }
  }

  @action
  onLine1FocusIn() {
    this.isLine1Focused = true;
  }

  @action
  onLine1FocusOut() {
    this.isLine1Focused = false;
  }

  @action
  onLine1Input(event) {
    this.isLine1Valid = this._checkLineMaxLength(event.target.value);
  }

  @action
  onLine2FocusIn() {
    this.isLine2Focused = true;
  }

  @action
  onLine2FocusOut() {
    this.isLine2Focused = false;
  }

  @action
  onLine2Input(event) {
    this.isLine2Valid = this._checkLineMaxLength(event.target.value);
  }

  @action
  handleClose(origin) {
    if (origin !== 'submit') {
      this.address.setProperties(this.initialAddress);
    }
    this.args.close();
  }

  @action
  handleSelectAddress(address, _, event) {
    this.address.setProperties({
      firstLine: address.street,
      zipcode: address.postalCode,
      city: address.city,
      country: address.countryCode?.toUpperCase(),
    });

    // to update error message after selecting an address if this one is too long
    if (this.enableAddressValidations) {
      this.isLine1Valid = this._checkLineMaxLength(this.address.firstLine);
    }

    // Prevent form to be submitted on address select
    // `event` is keyboard event when triggered with keyboard and power-select obj when selected with mouse: https://github.com/cibernox/ember-power-select/pull/1386
    event.preventDefault?.();
  }

  handleAddressFormSubmitTask = task(async () => {
    this.address.set('didValidate', false);
    let { validations } = await this.address.validate();

    this.isLine1Valid = this._checkLineMaxLength(this.address.firstLine);
    this.isLine2Valid = this._checkLineMaxLength(this.address.secondLine);

    if (validations.isValid && this.isLine1Valid && this.isLine2Valid) {
      await this.args.confirmTask.perform(this.address);
      this.handleClose('submit');
    } else {
      this.address.set('didValidate', true);
    }
  });

  _checkLineMaxLength(text) {
    return !text || text.length <= this.lineMaxLength;
  }
}
