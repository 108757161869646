export default {
  "wrapper": "Qh",
  "core-information": "Qf",
  "iban": "QK",
  "bic": "QG",
  "full-line": "Qr",
  "secondary-information": "Qb",
  "title": "Qy",
  "rib": "QU",
  "rib-copy": "Qj",
  "flex-column": "QW",
  "label": "Qu body-1",
  "value": "eS body-2",
  "right-spaced": "ec",
  "name": "eq title-3",
  "disclaimer": "eZ"
};
