export default {
  "item": "wk",
  "qonto-pilot-nav-item": "wJ",
  "wrapper": "ws",
  "collapsed": "wF",
  "label": "wg body-1",
  "icon-wrapper": "wD",
  "icon": "wV",
  "key": "wL",
  "keys": "wT"
};
