/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { ApprovalWorkflowTablePlaceholder } from 'qonto/react/components/approval-workflow/table-placeholder';

export default class ApprovalWorkflowsTable extends Component {
  approvalWorkflowTablePlaceholder = ApprovalWorkflowTablePlaceholder;

  get placeholderRowsAmount() {
    return variation('feature--boolean-approval-workflow-for-supplier-invoices') ? 3 : 2;
  }
}
