/* import __COLOCATED_TEMPLATE__ from './billing.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

export default class AttachmentsSidebarBillingComponent extends Component {
  @not('args.transaction.declined') hasAccountingValue;

  @service abilities;

  get showVAT() {
    let { vat } = this.args.transaction;

    if (!vat) return false;

    let filledVAT = vat.totalAmount?.value !== null;

    return this.abilities.can('view vat bookkeeping') && filledVAT;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }
}
