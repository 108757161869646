/* import __COLOCATED_TEMPLATE__ from './choose-button.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardChooseButton extends Component {
  get hasMetalStyle() {
    return !this.isDisabled;
  }

  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }
}
