export default {
  "edit": "PB",
  "upsell": "PE",
  "options": "Pd",
  "option": "PI body-2 mb-16",
  "option-line": "Pt",
  "category-tags": "PM",
  "option-icons": "PP",
  "option-icon": "Pl",
  "valid": "PX"
};
