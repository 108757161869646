export default {
  "row": "JZ",
  "active": "JR",
  "animated": "JQ",
  "fadein-item": "Je",
  "cell": "JB body-2",
  "cell-content": "JE",
  "no-padding": "Jd",
  "hidden": "JI",
  "disabled": "Jt",
  "not-disabled": "JM",
  "card-icon": "JP",
  "card-infos": "Jl",
  "card-digits": "JX",
  "card-digits-warning": "JC",
  "status-puce": "Jk",
  "live": "JJ",
  "waiting-funds": "Js",
  "ghost": "JF",
  "payment-limit-bar": "Jg",
  "align-right": "JD"
};
