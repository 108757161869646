/* import __COLOCATED_TEMPLATE__ from './status-banner.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsStatusBannerComponent extends Component {
  @service abilities;
  @service organizationManager;

  get canDisplay() {
    return this.abilities.can('view connections external-account') && this.hasFailedConnections;
  }

  get hasFailedConnections() {
    return Boolean(this.failedConnectionsCount);
  }

  get hasActionableFailures() {
    return Boolean(this.args.connectionStatuses?.actionableFailures.length);
  }

  get failedConnectionsCount() {
    return this.args.connectionStatuses?.failedConnections.length;
  }

  get failedConnection() {
    return this.args.connectionStatuses?.failedConnections[0];
  }

  get actionableConnection() {
    return this.args.connectionStatuses?.actionableFailures[0];
  }
}
