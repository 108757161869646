/* import __COLOCATED_TEMPLATE__ from './renew.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardRenewProvider extends Component {
  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service sentry;

  renewTask = dropTask(
    async (address, shipToBusiness, cardUpsellLevel, cardId, cardDesign, typeOfPrint) => {
      try {
        let response = await this.cardsManager.fetchCardsRenewTask.perform(
          this.organizationManager.organization.id,
          address,
          shipToBusiness,
          cardUpsellLevel,
          cardId,
          cardDesign,
          typeOfPrint
        );
        await this.cardsManager.fetchCounters();
        return response;
      } catch (error) {
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        let errorInfo = ErrorInfo.for(error);

        // 404 error is legit: it means there is no card to renew
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
          this.sentry.captureException(error);
        }

        if (errorInfo.httpStatus === 404) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.cards.no-card-to-renew'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        }
      }
    }
  );
}
