export default {
  "container": "Jv",
  "content": "Jo",
  "subtitle": "Jn",
  "switch": "Ji",
  "card-details": "JA",
  "asset-container": "JY",
  "asset-container-padded": "Jh",
  "asset": "Jf",
  "right-container": "JK",
  "card-brand-title": "JG",
  "card-brand-badge": "Jr",
  "free-subtitle": "Jb",
  "benefit-container": "Jy",
  "checkmark": "JU",
  "container-metal": "Jj"
};
