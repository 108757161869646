/* import __COLOCATED_TEMPLATE__ from './handler.hbs'; */
import Component from '@glimmer/component';

export default class CardBannersHandler extends Component {
  get countPhysicalCardsToRenew() {
    let { currentUserPhysicalCards } = this.args;
    return currentUserPhysicalCards?.filter(card => card.isRenewable).length || 0;
  }

  get hasCardsEligibleForRenewal() {
    return this.countPhysicalCardsToRenew > 0;
  }
}
