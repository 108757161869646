/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { and } from 'macro-decorators';

import cmc7Splitter from 'qonto/utils/cmc7-splitter';

export default class CheckSidebarHeader extends Component {
  @service intl;

  @and('args.check.bankAccount.name', 'args.check.bankAccount.organization.hasMultipleBankAccounts')
  showAccountName;

  @action
  closeSidebar(checkId) {
    this.args.closeSidebar();
    document.querySelector(`[data-item-id="${checkId}"]`)?.focus();
  }

  get checkCmc7() {
    return cmc7Splitter(this.args.check.cmc7, ' ');
  }

  get checkHeaderTypeWording() {
    let { status } = this.args.check;

    switch (status) {
      case 'canceled':
        return this.intl.t('checks.sidebar.title.canceled');
      case 'declined':
        return this.intl.t('checks.sidebar.title.declined');
      case 'pending':
        return this.intl.t('checks.sidebar.title.pending');
      case 'received':
        return this.intl.t('checks.sidebar.title.received');
      case 'refunded':
        return this.intl.t('checks.sidebar.title.refunded');
      case 'validated':
        return this.intl.t('checks.sidebar.title.validated');
      default:
        return '';
    }
  }
}
