export default {
  "container": "lA",
  "content": "lY",
  "table": "lh",
  "table-body": "lf",
  "title": "lK",
  "title-with-badge": "lG",
  "metal-card-title": "lr",
  "subtitle": "lb",
  "card-image": "ly",
  "button-container": "lU"
};
