/* import __COLOCATED_TEMPLATE__ from './auth-buttons.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { allSettled, dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

/**
 * Track the load state of Google SDK.
 * The following property could have 3 differents states:
 * - "loading"
 * - "loaded"
 * - "not-loaded“
 */
const SDK_LOADING_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  NOT_LOADED: 'not-loaded',
};

export default class AuthButtons extends Component {
  @service authSdkLoader;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sentry;

  sdkLoadingStatus = SDK_LOADING_STATUS;

  constructor() {
    super(...arguments);

    this.loadAuthProviderScriptsTask.perform().catch(ignoreCancelation);
  }

  /**
   * @param {'google' | 'apple'} provider
   * @param {unknown | undefined} error
   */
  _handleAuthErrors(provider, error) {
    this.segment.track('login_failed', {
      authentication_method: provider,
    });

    if (hasMFAError(error?.responseJSON?.errors)) {
      throw error.responseJSON;
    }

    let { status } = error;
    if (status === 401) {
      this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_no_user_match'));
      return;
    }

    if (status === 403) {
      this.toastFlashMessages.toastError(this.intl.t('errors.account_locked'));
      return;
    }

    // Status 500 is already handled by service errors
    if (status !== 500) {
      this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_generic_issue'));
    }

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }

  authGoogleUserTask = dropTask(async ({ jwt }) => {
    try {
      await this.args.onSignInGoogleSuccess({ jwt });
    } catch (error) {
      this._handleAuthErrors('google', error);
    }
  });

  authAppleUserTask = dropTask(async ({ jwt }) => {
    try {
      await this.args.onSignInAppleSuccess({ jwt });
    } catch (error) {
      this._handleAuthErrors('apple', error);
    }
  });

  @action
  onAuthProvidersError(provider, error) {
    if (error.message === 'popup_closed_by_user') {
      return;
    }

    this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_generic_issue'));

    let sdkLoadState = provider === 'google' ? this.googleSDKLoadState : this.appleSDKLoadState;

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && sdkLoadState === SDK_LOADING_STATUS.LOADED) {
      this.sentry.captureException(error);
    }

    this.segment.track('login_failed', {
      authentication_method: provider,
    });
  }

  get googleSDKLoadState() {
    if (this._loadGoogleAuthTask.isRunning) {
      return SDK_LOADING_STATUS.LOADING;
    }

    return this._loadGoogleAuthTask.last.isSuccessful
      ? SDK_LOADING_STATUS.LOADED
      : SDK_LOADING_STATUS.NOT_LOADED;
  }

  get appleSDKLoadState() {
    if (this._loadAppleAuthTask.isRunning) {
      return SDK_LOADING_STATUS.LOADING;
    }

    return this._loadAppleAuthTask.last.isSuccessful
      ? SDK_LOADING_STATUS.LOADED
      : SDK_LOADING_STATUS.NOT_LOADED;
  }

  get shouldShowGoogleButton() {
    return (
      this.googleSDKLoadState === SDK_LOADING_STATUS.LOADED ||
      this.googleSDKLoadState === SDK_LOADING_STATUS.NOT_LOADED
    );
  }

  get shouldShowAppleButton() {
    return (
      this.appleSDKLoadState === SDK_LOADING_STATUS.LOADED ||
      this.appleSDKLoadState === SDK_LOADING_STATUS.NOT_LOADED
    );
  }

  /**
   * Show the separator when at least one of the auth provider is in loaded or not-loaded state.
   */
  get shouldShowSeparator() {
    let sdkStates = [SDK_LOADING_STATUS.LOADED, SDK_LOADING_STATUS.NOT_LOADED];
    return (
      sdkStates.includes(this.appleSDKLoadState) || sdkStates.includes(this.googleSDKLoadState)
    );
  }

  /**
   * Load both auth providers (Google / Apple) SDK in parallel.
   */
  loadAuthProviderScriptsTask = dropTask(async () => {
    await allSettled([this._loadGoogleAuthTask.perform(), this._loadAppleAuthTask.perform()]);
  });

  _loadGoogleAuthTask = dropTask(async () => {
    if (!window.google?.accounts && ENV.environment !== 'test') {
      await this.authSdkLoader.loadGoogleSdk();
    }
  });

  _loadAppleAuthTask = dropTask(async () => {
    if (!window.AppleID?.auth && ENV.environment !== 'test') {
      await this.authSdkLoader.loadAppleSdk();
    }
  });

  /**
   * @param {'google' | 'apple'} provider
   */
  @action trackButtonClick(provider) {
    this.segment.track('login_clicked', { authentication_method: provider });
  }
}
