/* import __COLOCATED_TEMPLATE__ from './conditions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { generateConditionString } from 'qonto/utils/approval-workflow/condition';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component {
  @service intl;
  @service errors;
  @service store;
  @tracked conditions;

  constructor() {
    super(...arguments);
    this.loadConditionsTask.perform().catch(ignoreCancelation);
  }

  loadConditionsTask = dropTask(async () => {
    this.conditions = await generateConditionString(
      this.args.conditions[0],
      this.args.index || 0,
      this.store,
      this.intl,
      this.errors
    );
  });
}
