export default {
  "chip": "DS",
  "chip--hidden": "Dc",
  "chip--collapsed": "Dq",
  "chip--selected": "DZ",
  "chip--disabled": "DR",
  "wrapper": "DQ",
  "content": "De body-1",
  "clear": "DB",
  "clear-icon": "DE"
};
