export default {
  "wrapper": "zd",
  "label": "zI",
  "input": "zt",
  "icon-trash": "zM",
  "hidden": "zP",
  "icon-color": "zl",
  "icon-edit": "zX",
  "icon-edit-save": "zC",
  "button-container": "zk"
};
