/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class CardSidebarValidityPeriod extends Component {
  @service localeManager;

  get expirationDate() {
    let { preExpiresAt } = this.args.card;

    if (!preExpiresAt) {
      return null;
    }

    return dateToken({
      date: preExpiresAt,
      token: DATE_FORMAT_TOKENS.DATE_TIME_S,
      locale: this.localeManager.locale,
    });
  }
}
