/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { STATUS } from 'qonto/constants/checks';

export default class ChecksTableItem extends Component {
  tag = Tag;

  @service intl;

  get isAvatarDisabled() {
    let { status } = this.args.item;

    return [STATUS.CANCELED, STATUS.DECLINED, STATUS.REFUNDED].includes(status);
  }

  get status() {
    let { status, declinedReason } = this.args.item;

    let CHECKS_STATUSES = {
      canceled: {
        color: 'red',
        copy: this.intl.t('checks.status.canceled'),
      },
      declined: {
        color: 'red',
        copy:
          declinedReason === 'not_received'
            ? this.intl.t('checks.status.canceled')
            : this.intl.t('checks.status.declined'),
      },
      pending: {
        color: 'orange',
        copy: this.intl.t('checks.status.pending'),
      },
      received: {
        color: 'orange',
        copy: this.intl.t('checks.status.received'),
      },
      refunded: {
        color: 'red',
        copy: this.intl.t('checks.status.refunded'),
      },
      validated: {
        color: 'green',
        copy: this.intl.t('checks.status.validated'),
      },
    };

    return CHECKS_STATUSES[status] ?? CHECKS_STATUSES['pending'];
  }
}
