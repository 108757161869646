export default {
  "row": "Ck",
  "active": "CJ",
  "status": "Cs",
  "align-right": "CF",
  "quick-actions": "Cg",
  "cell-approve": "CD",
  "account-select": "CV",
  "hidden": "CL",
  "no-padding": "CT",
  "animated": "Cz",
  "fadein-item": "Ca",
  "cell": "CH body-2",
  "cell-content": "CO",
  "amount": "Cm body-1",
  "subtitle": "Cp caption"
};
