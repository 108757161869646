/* import __COLOCATED_TEMPLATE__ from './payment-lifespan-limit.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

import { CARD_LIFESPAN_LIMITS, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';

export default class CardSettingsPaymentLifespanLimitsComponent extends Component {
  @service intl;

  changeset = new Changeset(this.args.card);

  get limitError() {
    let { paymentLifespanSpent } = this.args.card;
    let maxLimit = this.args.cardsMaxLimits.flash.payment_lifespan_limit_maximum;
    let newLimit = this.changeset.paymentLifespanLimit;

    let isSpentAmountOverLowestLimit = paymentLifespanSpent > CARD_LIFESPAN_LIMITS.min;
    let minAllowedLimit = isSpentAmountOverLowestLimit
      ? paymentLifespanSpent
      : CARD_LIFESPAN_LIMITS.min;

    if (newLimit > maxLimit) {
      return this.intl.t('cards.settings.payment-lifespan-limit.error.too-high-limit', {
        amount: this._formatAmount(maxLimit),
      });
    } else if (newLimit < minAllowedLimit) {
      let amount = this._formatAmount(minAllowedLimit);
      return isSpentAmountOverLowestLimit
        ? this.intl.t('cards.settings.payment-lifespan-limit.error.too-low-limit-already-spent', {
            amount,
          })
        : this.intl.t('cards.settings.payment-lifespan-limit.error.too-low-limit', {
            amount,
          });
    }
  }

  get hasError() {
    return this.limitError || typeof this.changeset.paymentLifespanLimit !== 'number';
  }

  get paymentLifespanLimitOptions() {
    return this.args.cardsMaxLimits[this.args.card.cardLevel].payment_lifespan_limit;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    if (!this.args.disablePersistingState && !this.hasError) {
      this.changeset.execute();
    }
  }

  @action
  setProperty(prop, val) {
    this.changeset.set(prop, val);
    this.persistState();
  }

  submitTask = dropTask(async () => {
    if (this.hasError) return;

    this.changeset.execute();
    await this.args.onSubmit();
  });

  _formatAmount(price) {
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }
}
