/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
import Component from '@glimmer/component';

import { QONTO_DEFAULT_LOGO } from 'qonto/constants/bank-account';

export default class BankAccountAvatarComponent extends Component {
  get bankLogo() {
    if (!this.args.bankAccount?.logo) return QONTO_DEFAULT_LOGO;

    let { size } = this.args;
    return this.args.bankAccount.logo?.[size || 'small'];
  }
}
