/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';

const featuresOrderByTrigger = {
  solo: ['customPeriod', 'multiAccounts', 'advancedFilters', 'customLabels'],
  teams: ['teamAnalytics', 'customPeriod', 'advancedFilters', 'multiAccounts'],
};

export default class DiscoverBundleOverviewComponent extends Component {
  @service intl;
  @service flowLinkManager;
  @service localeManager;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  name = 'overview';

  localSlides = {
    advancedFilters: {
      title: this.intl.t('upsell.bundle-overview.advanced-filters.title'),
      description: this.intl.t('upsell.bundle-overview.advanced-filters.body'),
      img: {
        de: '/illustrations/upsell/de/multi-dimensional-filters.png',
        en: '/illustrations/upsell/en/multi-dimensional-filters.png',
        es: '/illustrations/upsell/es/multi-dimensional-filters.png',
        fr: '/illustrations/upsell/fr/multi-dimensional-filters.png',
        it: '/illustrations/upsell/it/multi-dimensional-filters.png',
      },
    },
    customPeriod: {
      title: this.intl.t('upsell.bundle-overview.custom-period.title'),
      description: this.intl.t('upsell.bundle-overview.custom-period.body'),
      img: {
        de: '/illustrations/upsell/de/custom-period.png',
        en: '/illustrations/upsell/en/custom-period.png',
        es: '/illustrations/upsell/es/custom-period.png',
        fr: '/illustrations/upsell/fr/custom-period.png',
        it: '/illustrations/upsell/it/custom-period.png',
      },
    },
    teamAnalytics: {
      title: this.intl.t('upsell.bundle-overview.team-analytics.title'),
      description: this.intl.t('upsell.bundle-overview.team-analytics.body'),
      img: {
        de: '/illustrations/upsell/de/overview-team.png',
        en: '/illustrations/upsell/en/overview-team.png',
        es: '/illustrations/upsell/es/overview-team.png',
        fr: '/illustrations/upsell/fr/overview-team.png',
        it: '/illustrations/upsell/it/overview-team.png',
      },
    },
    customLabels: {
      title: this.intl.t('upsell.bundle-overview.custom-labels.title'),
      description: this.intl.t('upsell.bundle-overview.custom-labels.body'),
      img: {
        de: '/illustrations/upsell/de/custom-labels.png',
        en: '/illustrations/upsell/en/custom-labels.png',
        es: '/illustrations/upsell/es/custom-labels.png',
        fr: '/illustrations/upsell/fr/custom-labels.png',
        it: '/illustrations/upsell/it/custom-labels.png',
      },
    },
    multiAccounts: {
      title: this.intl.t('upsell.bundle-overview.multi-accounts.title'),
      description: this.intl.t('upsell.bundle-overview.multi-accounts.body'),
      img: {
        de: '/illustrations/upsell/de/multi-accounts.png',
        en: '/illustrations/upsell/en/multi-accounts.png',
        es: '/illustrations/upsell/es/multi-accounts.png',
        fr: '/illustrations/upsell/fr/multi-accounts.png',
        it: '/illustrations/upsell/it/multi-accounts.png',
      },
    },
  };

  get slides() {
    return featuresOrderByTrigger[this.lineup].map(orderedKey => {
      let { title, description, img, soon } = this.localSlides[orderedKey];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
        soon,
      };
    });
  }

  get upsellTrigger() {
    return this.args.data.upsellTrigger;
  }

  get lineup() {
    return this.subscriptionManager.currentPricePlan.lineup || PRICE_PLAN_LINEUPS.SOLO;
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      empty_state_name: this.name,
      price_plan,
      role,
    });

    if (this.args.transitionToNext) {
      this.args.transitionToNext();
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'discover-overview',
        queryParams: { upsellTrigger: this.upsellTrigger },
      });
    }
  }
}
