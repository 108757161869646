export default {
  "card": "Is",
  "row": "IF",
  "icon": "Ig",
  "unrevokable": "ID",
  "active": "IV",
  "kyc-pending": "IL",
  "shareholder-info": "IT",
  "name": "Iz",
  "iban": "Ia",
  "tooltip": "IH",
  "shareholder-capital": "IO",
  "shareholder-capital-amount": "Im",
  "deposit-capital": "Ip",
  "valid": "Ix",
  "pending": "Iw",
  "promised-capital-valid": "IN",
  "promised-capital": "Iv"
};
