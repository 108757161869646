/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CounterpartiesSidebarDetailsComponent extends Component {
  @service intl;

  @tracked readMoreIsOpen = false;
  @tracked showActionBtns = false;

  activities = {
    atm: this.intl.t('activities.atm'),
    fees: this.intl.t('activities.fees'),
    finance: this.intl.t('activities.finance'),
    food_and_grocery: this.intl.t('activities.food_and_grocery'),
    gas_station: this.intl.t('activities.gas_station'),
    hardware_and_equipment: this.intl.t('activities.hardware_and_equipment'),
    hotel_and_lodging: this.intl.t('activities.hotel_and_lodging'),
    insurance: this.intl.t('activities.insurance'),
    it_and_electronics: this.intl.t('activities.it_and_electronics'),
    legal_and_accounting: this.intl.t('activities.legal_and_accounting'),
    logistics: this.intl.t('activities.logistics'),
    manufacturing: this.intl.t('activities.manufacturing'),
    marketing: this.intl.t('activities.marketing'),
    office_rental: this.intl.t('activities.office_rental'),
    office_supply: this.intl.t('activities.office_supply'),
    online_service: this.intl.t('activities.online_service'),
    other_expense: this.intl.t('activities.other_expense'),
    other_income: this.intl.t('activities.other_income'),
    other_service: this.intl.t('activities.other_service'),
    refund: this.intl.t('activities.refund'),
    restaurant_and_bar: this.intl.t('activities.restaurant_and_bar'),
    salary: this.intl.t('activities.salary'),
    sales: this.intl.t('activities.sales'),
    subscription: this.intl.t('activities.subscription'),
    tax: this.intl.t('activities.tax'),
    transport: this.intl.t('activities.transport'),
    treasury_and_interco: this.intl.t('activities.treasury_and_interco'),
    utility: this.intl.t('activities.utility'),
    voucher: this.intl.t('activities.voucher'),
  };

  get activityLabel() {
    return this.activities[this.args.counterparty.activityTag] || '';
  }

  @action onMouseEnter() {
    this.showActionBtns = true;
  }

  @action onMouseLeave() {
    this.showActionBtns = false;
  }

  get activityIconThemedAsset() {
    return this.args.counterparty.activityTag + '-xs';
  }
}
