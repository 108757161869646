export default {
  "main": "Rl",
  "spinner": "RX",
  "error": "RC",
  "error-illustration": "Rk",
  "wrapper": "RJ",
  "content": "Rs",
  "illustration": "RF mb-32",
  "title": "Rg mb-16 title-2",
  "subtitle": "RD body-2"
};
