/* import __COLOCATED_TEMPLATE__ from './teams.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const featuresOrderByTrigger = {
  default: ['teamAccess', 'managerRole', 'requests', 'cards', 'flashCard'],
  requests: ['requests', 'teamAccess', 'managerRole', 'flashCard', 'cards'],
  additionalUsers: ['teamAccess', 'managerRole', 'requests', 'flashCard', 'cards'],
  managerRole: ['managerRole', 'teamAccess', 'requests', 'flashCard', 'cards'],
  flashCard: ['flashCard', 'teamAccess', 'managerRole', 'requests', 'cards'],
};

export default class DiscoverBundleTeamsComponent extends Component {
  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  name = 'access management';
  lineup = 'teams';

  localSlides = {
    teamAccess: {
      title: this.intl.t('upsell.bundle-teams.team-access.title'),
      description: this.intl.t('upsell.bundle-teams.team-access.text'),
      img: {
        de: '/illustrations/upsell/de/team-access.png',
        en: '/illustrations/upsell/en/team-access.png',
        es: '/illustrations/upsell/es/team-access.png',
        fr: '/illustrations/upsell/fr/team-access.png',
        it: '/illustrations/upsell/it/team-access.png',
      },
    },
    managerRole: {
      title: this.intl.t('upsell.bundle-teams.manager-role.title'),
      description: this.intl.t('upsell.bundle-teams.manager-role.text'),
      img: {
        de: '/illustrations/upsell/de/manager-roles.png',
        en: '/illustrations/upsell/en/manager-roles.png',
        es: '/illustrations/upsell/es/manager-roles.png',
        fr: '/illustrations/upsell/fr/manager-roles.png',
        it: '/illustrations/upsell/it/manager-roles.png',
      },
    },
    requests: {
      title: this.intl.t('upsell.bundle-teams.transfer-approval.title'),
      description: this.intl.t('upsell.bundle-teams.transfer-approval.text'),
      img: {
        de: '/illustrations/upsell/de/requests.png',
        en: '/illustrations/upsell/en/requests.png',
        es: '/illustrations/upsell/es/requests.png',
        fr: '/illustrations/upsell/fr/requests.png',
        it: '/illustrations/upsell/it/requests.png',
      },
    },
    cards: {
      title: this.intl.t('upsell.bundle-teams.cards.title'),
      description: this.intl.t('upsell.bundle-teams.cards.text'),
      img: {
        de: '/illustrations/upsell/de/cards.png',
        en: '/illustrations/upsell/en/cards.png',
        es: '/illustrations/upsell/es/cards.png',
        fr: '/illustrations/upsell/fr/cards.png',
        it: '/illustrations/upsell/it/cards.png',
      },
    },
    flashCard: {
      title: this.intl.t('upsell.bundle-teams.flash.title'),
      description: this.intl.t('upsell.bundle-teams.flash.text'),
      img: {
        de: '/illustrations/upsell/card-flash-upsell.png',
        en: '/illustrations/upsell/card-flash-upsell.png',
        es: '/illustrations/upsell/card-flash-upsell.png',
        fr: '/illustrations/upsell/card-flash-upsell.png',
        it: '/illustrations/upsell/card-flash-upsell.png',
      },
    },
  };

  get slides() {
    let slidesByKey = this.upsellTrigger ?? 'default';

    return featuresOrderByTrigger[slidesByKey].map(orderedKey => {
      let { title, description, img, soon } = this.localSlides[orderedKey];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
        soon,
      };
    });
  }

  get upsellTrigger() {
    return this.args.data?.upsellTrigger;
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      empty_state_name: this.name,
      price_plan,
      role,
    });

    if (this.args.transitionToNext) {
      this.args.transitionToNext();
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'discover-teams',
        queryParams: { lineup: this.lineup, upsellTrigger: this.upsellTrigger },
      });
    }
  }
}
