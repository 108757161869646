export default {
  "row": "dE body-1",
  "cell": "dd",
  "counterparty": "dI",
  "counterparty-icon": "dt",
  "avatar": "dM",
  "icon": "dP",
  "counterparty-content": "dl",
  "item-counterparty-name": "dX",
  "counterparty-name": "dC body-1",
  "counterparty-status": "dk",
  "member": "dJ",
  "method": "ds",
  "operation-date": "dF",
  "amount": "dg",
  "remove-transaction": "dD",
  "menu-item": "dV",
  "remove": "dL",
  "tooltip": "dT",
  "empty": "dz"
};
