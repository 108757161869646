/* import __COLOCATED_TEMPLATE__ from './solution-wizard.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import { TRAY_WIZARD_EVENTS } from 'qonto/constants/listeners';

export default class QontoHubSetupSolutionWizardComponent extends Component {
  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('message', this.handleMessage);
  }

  @action
  addEventListener() {
    window.addEventListener('message', this.handleMessage);
  }

  @action
  handleMessage(evt) {
    if (evt.origin === new URL(this.args.url).origin || window.location.origin) {
      if (evt.data.type === TRAY_WIZARD_EVENTS.CANCEL) {
        this.args.onCancel?.();
      }
      if (evt.data.type === TRAY_WIZARD_EVENTS.FINISH) {
        this.args.onFinish?.();
      }
    }
  }
}
