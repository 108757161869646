/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class CheckSidebarStatus extends Component {
  @service featuresManager;
  @service intl;
  showDynamicDelay = this.featuresManager.isEnabled('checkAmountAndTimeImprovements');

  get shippingAddress() {
    return this.args.check.shippingAddress ?? this.intl.t('checks.descriptions.pending_address');
  }
}
