/* import __COLOCATED_TEMPLATE__ from './sub-menu.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
import { PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY } from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class DualNavSubMenuComponent extends Component {
  @service abilities;
  @service router;
  @service intl;
  @service invoiceSubscriptionManager;
  @service subscriptionManager;
  @service flowLinkManager;
  @service organizationManager;
  @service segment;
  @service localeManager;
  @service userManager;
  @tracked hasClickedSurveyLink = Boolean(
    safeLocalStorage.getItem(PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY)
  );

  constructor() {
    super(...arguments);

    let { label, groups, state } = this.args;

    assert('<DualNav::SubMenu /> must have @label', label);
    assert('<DualNav::SubMenu /> must have @groups', groups);
    assert('<DualNav::SubMenu /> must have @groups.length > 0', groups.length);

    if (this.isVisible && state !== DUAL_NAV_STATES.COLLAPSED) {
      document.body.classList.remove('prevent-nav-animation');
    }
  }

  get isVisible() {
    let { activeMenuItemId, currentMenuItemId, hoveredMenuItemId, state } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE) {
      return hoveredMenuItemId === currentMenuItemId;
    }
    return (hoveredMenuItemId ?? activeMenuItemId) === currentMenuItemId;
  }

  get shouldDisplayAccountAggregationPromoBox() {
    let { state, hoveredMenuItemId } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'business-account') {
      return false;
    }

    return (
      this.abilities.can('import external-account') &&
      this.router.currentRouteName === 'accounts.index'
    );
  }

  @action
  handleReimbursementsPromoBoxCTAClick() {
    this.segment.track('product-discovery_user-action_clicked', {
      name: 'approval-workflow-action',
      price_plan_code: this.subscriptionManager.currentPricePlan?.code,
    });
    this.router.transitionTo('settings.approval-workflows');
  }

  get shouldShowCashFlowPromoBox() {
    let transactionsRoute = 'transactions.index';

    return (
      this.abilities.can('see promotional card transaction') &&
      this.router.currentRouteName === transactionsRoute
    );
  }

  @action
  handleCashFlowPromoBoxCTAClick() {
    this.segment.track('product-discovery_user-action_clicked', {
      name: 'cash-flow-survey-clicked',
      price_plan_code: this.subscriptionManager.currentPricePlan?.code,
    });
    window.open(this.generateCashFlowTypeformUrl(), '_blank', 'noopener,noreferrer');
  }

  generateCashFlowTypeformUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    let currentUserEmail = this.userManager.currentUser?.email;
    let typeFormBaseUrl = `https://qonto.typeform.com/to`;
    let typeFormUrlPath = `#email=${currentUserEmail}&slug=${organization?.slug}`;

    let typeFormUrl = `${typeFormBaseUrl}/fDp4TT5j/${typeFormUrlPath}`;

    switch (currentLocale) {
      case 'en':
        typeFormUrl = `${typeFormBaseUrl}/fDp4TT5j/${typeFormUrlPath}`;
        break;

      case 'fr':
        typeFormUrl = `${typeFormBaseUrl}/K1gmzCzK/${typeFormUrlPath}`;
        break;

      case 'de':
        typeFormUrl = `${typeFormBaseUrl}/Bexr2PE3/${typeFormUrlPath}`;
        break;

      case 'es':
        typeFormUrl = `${typeFormBaseUrl}/cmbkJTG1/${typeFormUrlPath}`;
        break;

      case 'it':
        typeFormUrl = `${typeFormBaseUrl}/Uo9rxjI5/${typeFormUrlPath}`;
        break;

      case 'pt':
        typeFormUrl = `${typeFormBaseUrl}/nQN6nqnr/${typeFormUrlPath}`;
        break;

      default:
        typeFormUrl = `${typeFormBaseUrl}/fDp4TT5j/${typeFormUrlPath}`;
        break;
    }

    return typeFormUrl;
  }

  get shouldShowRecurringInvoicesPromoBox() {
    return (
      this.invoiceSubscriptionManager.shouldShowPromoBox &&
      this.router.currentRouteName === 'invoice-subscriptions.index'
    );
  }

  get shouldDisplayAccountReceivableOnboardingPromoBox() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      (this.router.currentRouteName === 'receivable-invoices.index' ||
        this.router.currentRouteName === 'invoice-subscriptions.index')
    );
  }

  get shouldDisplayInternationalOutPromoBox() {
    let { state, hoveredMenuItemId, activeSubMenuItem, hasInternationalOutPromotion } = this.args;

    if (!hasInternationalOutPromotion) {
      return false;
    }

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'business-account') {
      return false;
    }

    return activeSubMenuItem?.id === 'transfers';
  }

  get shouldDisplayPayLaterPromoBox() {
    let { state, hoveredMenuItemId, activeSubMenuItem } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'financing') {
      return false;
    }

    return (
      variation('feature--boolean-pay-later-promotion-installments') &&
      activeSubMenuItem?.id === 'pay-later'
    );
  }

  get shouldShowSupplierInvoiceArchivePromoBox() {
    let { state, hoveredMenuItemId } = this.args;

    return !(state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'invoices');
  }

  get shouldShowPaymentLinksSurveyPromoBox() {
    let isReceivableInvoicesIndexRoute =
      this.router.currentRouteName === 'receivable-invoices.index';
    let isFrenchOrGermanOrganization = ['DE', 'FR'].includes(
      this.organizationManager.organization?.legalCountry
    );

    let isVisible =
      variation('feature--invoices-payment-links-survey') &&
      isFrenchOrGermanOrganization &&
      isReceivableInvoicesIndexRoute &&
      !this.hasClickedSurveyLink;

    return isVisible;
  }

  @action
  recurringInvoicePromoBadgeClick() {
    this.flowLinkManager.transitionTo({
      name: 'sdd-activation',
      stepId: 'intro',
      queryParams: { origin: 'invoice-subscriptions.index' },
    });
    safeLocalStorage.setItem(PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }

  @action
  onAnimationEnd() {
    document.body.classList.remove('prevent-nav-animation');
  }

  @action
  paymentLinksSurveyRedirectClick() {
    let typeFormUrl = this.generateFormUrl();
    safeLocalStorage.setItem(PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY, true);
    this.segment.track('payment-link_promo-card_clicked');
    this.hasClickedSurveyLink = true;

    window.open(typeFormUrl, '_blank');
  }

  generateFormUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    let currentUserEmail = this.userManager?.currentUser?.email;
    let typeFormBaseUrl = `https://qonto.typeform.com/to`;
    let typeFormUrlPath = `#email=${currentUserEmail}&legal_country=${organization?.legalCountry}&organisation_id=${organization?.id}`;

    let typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;

    switch (currentLocale) {
      case 'en':
        typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;
        break;

      case 'fr':
        typeFormUrl = `${typeFormBaseUrl}/fvpBUcmx/${typeFormUrlPath}`;
        break;

      case 'de':
        typeFormUrl = `${typeFormBaseUrl}/hlsWTOHB/${typeFormUrlPath}`;
        break;

      default:
        typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;
        break;
    }

    return typeFormUrl;
  }
}
