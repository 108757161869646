/* import __COLOCATED_TEMPLATE__ from './beneficiary-trust-popup.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class BeneficiaryTrustPopup extends Component {
  @service segment;

  @action
  handleClick() {
    this.args.close();
    this.segment.track('beneficiary_trust_modal_cancel');
  }
}
