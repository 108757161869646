/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

export default class CounterpartiesSidebar extends Component {
  @service modals;
  @service intl;
  @service toastFlashMessages;

  get selectedVatRate() {
    return this.args.selectedVatRate ?? this.args.counterparty.vatRate;
  }

  get selectedLabels() {
    let { selectedLabels, counterparty } = this.args;
    return selectedLabels || counterparty.labels;
  }

  get hasDirtyLabels() {
    let { labels } = this.args.counterparty;

    if (!this.selectedLabels || this.selectedLabels.length !== labels.length) {
      return true;
    }

    // check if the set labels match the existing set of labels
    return !this.selectedLabels.every(({ id }) => labels.some(label => id === label.id));
  }

  get hasDirtyVat() {
    return this.selectedVatRate !== this.args.counterparty.vatRate;
  }

  get showActionsBar() {
    let hasDirtyAttributes = this.hasDirtyLabels || this.hasDirtyVat;
    return hasDirtyAttributes && this.args.saveCounterpartyTask.isIdle;
  }

  @action
  closeSidebar(counterpartyId) {
    this.args.closeSidebar();
    document.querySelector(`[data-item-id="${counterpartyId}"]`)?.focus();
  }

  @action
  updateVat(amount) {
    this.args.updateCounterpartyAttributes({ vatRate: amount, labels: this.selectedLabels });
  }

  @action
  setSelectedLabels(labelList, label) {
    let labels = getLabelsToUpdate(this.selectedLabels, labelList, label);
    this.args.updateCounterpartyAttributes({ vatRate: this.selectedVatRate, labels });
  }

  saveTask = dropTask(async () => {
    let counterpartyChanges = {
      labels: this.selectedLabels,
      vatRate: this.selectedVatRate,
      transferTransactionsCount: this.args.counterparty.transferTransactionsCount,
    };

    await this.args.saveCounterpartyTask.perform(counterpartyChanges);
  });

  confirmDeleteTask = dropTask(async () => {
    try {
      await this.modals.open('popup/destructive', {
        title: this.intl.t('counterparties.sidebar.delete-modal.title'),
        description: this.intl.t('counterparties.sidebar.delete-modal.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('btn.delete'),
        confirmTask: this.deleteCounterpartyTask,
      });
    } catch {
      // Show error toast message
      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  deleteCounterpartyTask = dropTask(async closeModal => {
    // `unlinked()` is needed here because this sidebar can be destroyed
    // causing a cascading effect, halting the passed in task prematurely
    await this.args.deleteCounterpartyTask.unlinked().perform(this.args.counterparty, closeModal);
  });

  editBeneficiaryTask = dropTask(async () => {
    let { counterparty, onBeneficiaryUpdated } = this.args;
    await this.modals.open('beneficiary-form-modal', {
      isFullScreenModal: true,
      beneficiary: counterparty,
      isSupplierHub: true,
      refreshBeneficiariesList: onBeneficiaryUpdated,
    });
  });

  @action
  discardChanges() {
    this.args.updateCounterpartyAttributes({ vatRate: null, labels: null });
  }
}
