/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ClientsTable extends Component {
  @service segment;

  @action
  handleClick(itemId) {
    this.args.highlightItem(itemId);
  }
}
