export default {
  "content": "ep",
  "balance": "ex",
  "balance-failed": "ew",
  "bank-infos": "eN",
  "bank-name": "ev",
  "bank-identifier": "eo",
  "bank-iban": "en",
  "footer-container": "ei",
  "links": "eA",
  "links--start-verification": "eY",
  "link": "eh",
  "start-verification-link": "ef",
  "last-sync": "eK"
};
