/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class AccountsLayoutComponent extends Component {
  @service abilities;

  constructor() {
    super(...arguments);

    if (this.args.maybeScrollToLast) {
      next(() => {
        this.args.maybeScrollToLast(document.getElementById('bank-account-list'));
      });
    }
  }

  get scrolledTop() {
    return this.args.scrolledTop;
  }

  @action onCreateAccount() {
    if (this.abilities.cannot('create account')) return;

    this.args.onCreateAccount();
  }

  @action onUpdateScrolledTop(value) {
    return this.args.onUpdateScrolledTop?.(value);
  }
}
