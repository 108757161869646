export default {
  "panel-container": "IZ",
  "selector-container": "IR",
  "container-title": "IQ",
  "panel-title": "Ie",
  "panel-title-text": "IB",
  "panel-icon": "IE",
  "icon": "Id",
  "animate-in": "II",
  "panel-content": "It",
  "error-message": "IM"
};
