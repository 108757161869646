/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class CustomLabelsList extends Component {
  @action
  resetNewItem() {
    let newLabel = this.args.labelList?.labels?.at(-1);
    if (newLabel?.isNew) {
      newLabel.destroyRecord();
    }
  }
}
