/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ClientsSidebarCardComponent extends Component {
  @service intl;

  get clientCountryName() {
    let { countryCode } = this.args.client.billingAddress;
    return countryCode ? this.intl.formatCountry(countryCode) : '';
  }
}
