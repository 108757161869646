export default {
  "container": "Pv",
  "container-metal": "Po",
  "pickers": "Pn",
  "picker-wrapper": "Pi",
  "picker": "PA",
  "checked": "PY",
  "lilac": "Ph",
  "black": "Pf",
  "mineral-gray": "PK",
  "sand-gold": "PG",
  "graphite-black": "Pr"
};
