export default {
  "container": "tQ",
  "title": "te",
  "subtitle": "tB",
  "form": "tE",
  "uploader": "td",
  "submit-button": "tI",
  "error-message": "tt",
  "inputs": "tM"
};
