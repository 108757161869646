/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class ByLawsUploadComponent extends Component {
  checkbox = Checkbox;

  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service featuresManager;
  @service sentry;

  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;

  @tracked isFundsOpen = false;
  @tracked isStatementOpen = false;
  @tracked isConfirmationChecked = false;
  @tracked showAnnexCheckboxError = false;

  transition = fade;

  @action
  toggleFundsPanel() {
    this.isFundsOpen = !this.isFundsOpen;
  }

  @action
  toggleStatementsPanel() {
    this.isStatementOpen = !this.isStatementOpen;
  }

  @action
  toggleConfirmationChecked() {
    this.isConfirmationChecked = !this.isConfirmationChecked;

    if (this.isConfirmationChecked) {
      this.showAnnexCheckboxError = false;
    }
  }

  saveByLawsTask = task(async () => {
    this.segment.track('kdeposit_bylaws_annex_confirmed');

    if (this.isConfirmationChecked) {
      this.segment.track('kdeposit_bylaws_annex_checked');
      try {
        await this.args.saveDocuments();
        this.args.goNext();
      } catch (error) {
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
        this.sentry.captureException(error);
      }
    } else {
      this.showAnnexCheckboxError = true;
    }
  });
}
