/* import __COLOCATED_TEMPLATE__ from './email-form.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer, TextAreaField, TextField } from '@repo/design-system-kit';

export default class DirectDebitCollectionsEmailFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;
  textField = TextField;
}
