export default {
  "wrapper": "eR",
  "title": "eQ",
  "button-icon": "ee",
  "download-button": "eB",
  "specification-list": "eE",
  "certified-details-wrapper": "ed",
  "details-wrapper": "eI",
  "certified-specification-list": "et",
  "specification": "eM",
  "icon": "eP",
  "cross": "el",
  "checkmark": "eX",
  "pit-link": "eC"
};
