export default {
  "budget-creation-form": "ET",
  "period-label": "Ez",
  "periods": "Ea",
  "yearly": "EH",
  "yearly-toggle": "EO",
  "toggle-tooltip": "Em",
  "tooltip": "Ep",
  "year": "Ex",
  "total-budget": "Ew",
  "total-budget-value": "EN",
  "divider": "Ev"
};
