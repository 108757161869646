/* import __COLOCATED_TEMPLATE__ from './international-out-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { EVENTS } from 'qonto/constants/international-out/tracking';

export default class DualNavInternationalOutPromoBoxComponent extends Component {
  @service flowLinkManager;
  @service segment;

  @action
  navigate() {
    this.segment.track(EVENTS.PROMOTIONAL_CARD_CLICKED);
    this.flowLinkManager.transitionTo({
      name: 'international-out',
      stepId: 'quote',
      queryParams: {
        voucher: true,
      },
    });
  }
}
