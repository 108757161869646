export default {
  "row": "CA",
  "active": "CY",
  "status": "Ch",
  "align-right": "Cf",
  "animated": "CK",
  "fadein-item": "CG",
  "cell": "Cr body-2",
  "cell-content": "Cb",
  "amount": "Cy body-1",
  "subtitle": "CU caption",
  "no-padding": "Cj",
  "note": "CW"
};
