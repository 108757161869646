/* import __COLOCATED_TEMPLATE__ from './new.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';

export default class BeneficiaryUploadNewComponent extends Component {
  @service router;
  @service abilities;

  abortRoute = 'transfers.new';
  confirmRoute = 'beneficiaries.multi.review';

  fileUploadTask = dropTask(async multiBeneficiary => {
    let mb = await multiBeneficiary.save();

    let beneficiaries = await mb.beneficiaries;

    if (this.abilities.can('assign category')) {
      for (let beneficiary of beneficiaries) {
        beneficiary.activityTag = ACTIVITY_TAGS.OTHER_EXPENSE;
      }
    }

    this.router.transitionTo(this.confirmRoute, mb);
  });

  @action
  handleGoBack() {
    this.router.transitionTo(this.abortRoute);
  }
}
