/* import __COLOCATED_TEMPLATE__ from './copy-to-clipboard.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class CopyToClipboard extends Component {
  @service toastFlashMessages;
  @service segment;

  @action
  onCopySuccess() {
    let { toastMessage, trackEvent, trackParams } = this.args;
    this.toastFlashMessages.toastInfo(toastMessage);

    if (trackEvent) {
      this.segment.track(trackEvent, trackParams);
    }
  }
}
