/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

export default class CardSettingsOptionsComponent extends Component {
  changeset = new Changeset(this.args.card);

  get isCtaDisabled() {
    return this.submitTask.isRunning;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  @action
  toggleCardProperty(property) {
    let { changeset } = this;
    changeset.set(property, !changeset.get(property));
    this.persistState();
  }

  submitTask = dropTask(async () => {
    this.changeset.execute();
    await this.args.onSubmit();
  });
}
