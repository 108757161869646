/* import __COLOCATED_TEMPLATE__ from './beneficiary-form-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { dropTask, restartableTask, task, timeout, waitForProperty } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

const SWITCH_DELAY = 600;

export default class BeneficiaryFormModal extends Component {
  spinner = Spinner;

  @service toastFlashMessages;
  @service intl;
  @service store;
  @service abilities;
  @service segment;

  @tracked beneficiary = null;
  @tracked initialLabelsList = null;
  @tracked existingBeneficiary = null;
  @tracked isBeneficiaryUpdate = false;

  constructor() {
    super(...arguments);

    let { beneficiary } = this.args.data;

    this.beneficiary = beneficiary || this._createBeneficiaryRecord();
    this.initialLabelsList = [...this.beneficiary.labels];
    this.isBeneficiaryUpdate = Boolean(beneficiary);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.rollbackAttributes();
  }

  get isErrorShown() {
    return this.saveBeneficiaryTask.performCount > 0;
  }

  get isNameInvalid() {
    return this.isErrorShown && this.beneficiary.validations.attrs.name.isInvalid;
  }

  get isIbanInvalid() {
    return this.isErrorShown && this.beneficiary.validations.attrs.iban.isInvalid;
  }

  get isActivityTagInvalid() {
    return this.isErrorShown && this.beneficiary.validations.attrs.activityTag.isInvalid;
  }

  get isEmailInvalid() {
    return this.isErrorShown && this.beneficiary.validations.attrs.email.isInvalid;
  }

  get isSwitchingFormType() {
    return (
      this.switchToAddBeneficiaryTask.isRunning || this.switchToUpdateBeneficiaryTask.isRunning
    );
  }

  get confirmClickEventName() {
    return this.isBeneficiaryUpdate
      ? 'transfer_rolodex_save_beneficiary_confirmed'
      : 'transfer_rolodex_create_beneficiary_confirmed';
  }

  get hasDirtyLabels() {
    if (this.initialLabelsList.length !== this.beneficiary.labels.length) {
      return true;
    }

    return !this.initialLabelsList.every(({ id }) =>
      this.beneficiary.labels.some(l => l.id === id)
    );
  }

  get saveDisabled() {
    let {
      switchToAddBeneficiaryTask,
      switchToUpdateBeneficiaryTask,
      isBeneficiaryUpdate: isEdition,
      beneficiary,
      hasDirtyLabels,
    } = this;

    let tasksRunning =
      switchToAddBeneficiaryTask.isRunning || switchToUpdateBeneficiaryTask.isRunning;
    let hasChangesToSave = !isEdition || beneficiary.hasDirtyAttributes || hasDirtyLabels;

    return tasksRunning || !hasChangesToSave;
  }

  @action
  triggerTrackingEvents() {
    if (this.abilities.can('view bookkeeping')) {
      this.segment.track(this.confirmClickEventName, {
        vat_rate_selected: this.beneficiary.vatRate === null ? false : true,
        custom_labels_added: this.beneficiary.labels.length > 0,
      });
    } else {
      this.segment.track(this.confirmClickEventName);
    }
  }

  rollbackAttributes() {
    if (!(this.beneficiary.isDestroyed || this.beneficiary.isDestroying)) {
      this.beneficiary.rollbackAttributes();

      let { initialLabelsList, hasDirtyLabels } = this;

      if (hasDirtyLabels) {
        this.beneficiary.set('labels', initialLabelsList);
      }
    }
  }

  @action
  updateBeneficiary(attribute, value) {
    if (value === '') {
      this.beneficiary[attribute] = null;
    } else {
      this.beneficiary[attribute] = value;
    }
  }

  @action
  updateVatRate(beneficiary, { vatRate }) {
    beneficiary.vatRate = vatRate;
  }

  @action
  saveLabels(labelList, label) {
    this.beneficiary.labels = getLabelsToUpdate(this.beneficiary.labels, labelList, label);
  }

  onIbanChangeTask = restartableTask(async value => {
    this.beneficiary.iban = value;

    await timeout(DEBOUNCE_MS);
    await this.checkExistingIbanTask.perform(value);
  });

  switchToAddBeneficiaryTask = task(async () => {
    this.isBeneficiaryUpdate = false;
    await timeout(SWITCH_DELAY);

    let { activityTag, email, name } = this.beneficiary;

    this.beneficiary = this._createBeneficiaryRecord({ activityTag, email, name });
  });

  switchToUpdateBeneficiaryTask = task(async () => {
    this.isBeneficiaryUpdate = true;
    await timeout(SWITCH_DELAY);
    this.beneficiary = this.existingBeneficiary;
    this.existingBeneficiary = null;
  });

  saveBeneficiaryTask = dropTask(async () => {
    this.triggerTrackingEvents();

    try {
      if (await this.canSaveBeneficiaryTask.linked().perform()) {
        let beneficiary = await this.beneficiary.save();
        this.initialLabelsList = [...beneficiary.labels];
        this.args.close();

        let message = this.isBeneficiaryUpdate
          ? this.intl.t('transfers.beneficiaries.edit-modal.success')
          : this.intl.t('transfers.beneficiaries.add-modal.success');

        this.toastFlashMessages.toastInfo(message);

        this.args.data.refreshBeneficiariesList?.();
      }
    } catch (error) {
      await this.handleBeneficiarySaveError(error);
    }
  });

  canSaveBeneficiaryTask = dropTask(async () => {
    if (!this.beneficiary.validations.isValid) {
      return false;
    }

    if (!this.isBeneficiaryUpdate) {
      // BE validation for IBAN, throws exception if invalid
      await this.beneficiary.confirm();
    }

    // make sure existing IBAN validation has run
    await waitForProperty(this, 'checkExistingIbanTask.isIdle');
    return !this.existingBeneficiary;
  });

  async handleBeneficiarySaveError() {
    // check for ds errors on model returned from BE
    await this.beneficiary.validate();
    if (this.beneficiary.validations.isValid) {
      // if no model validation errors then show generic toast message
      this.toastFlashMessages.toastError(this.intl.t('transfers.errors.add-edit-beneficiary'));
    }
  }

  checkExistingIbanTask = dropTask(async value => {
    try {
      let { transfer } = this.args.data;

      // we request the beneficiaries by IBAN which cannot be duplicated between several beneficiaries
      // so we always get an array with only one element or an empty array
      let beneficiaries = await this.store.query('beneficiary', {
        iban: value,
        organization_id: transfer.bankAccount.organization.id,
      });

      this.existingBeneficiary = beneficiaries[0];
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('transfers.errors.add-edit-beneficiary'));
    }
  });

  _createBeneficiaryRecord(options = {}) {
    let { transfer } = this.args.data;

    return this.store.createRecord('beneficiary', {
      currency: CURRENCIES.default,
      vatRate: null,
      organization: transfer.bankAccount.organization,
      ...options,
    });
  }
}
