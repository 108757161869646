/* import __COLOCATED_TEMPLATE__ from './google-auth-button.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, waitForQueue } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class GoogleAuthButton extends Component {
  @service localeManager;
  @service segment;
  btnContainerId = `btn-container-${guidFor(this)}`;

  constructor() {
    super(...arguments);
    this.initGoogleAuthTask.perform().catch(ignoreCancelation);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('message', this._onHandleMessagesFromGoogleIframe);
  }

  @action
  _onHandleMessagesFromGoogleIframe(event) {
    let { channelId, type } = event?.data ?? {};
    if (channelId && type === 'readyForConnect') {
      this.segment.track('login_clicked', { authentication_method: 'google' });
    }
  }

  @action
  _handleCredentialResponse(response) {
    this.args.onSignInSuccess?.({ jwt: response?.credential });
  }

  @action
  _onHandleInitFailureFallback() {
    this.args.onError?.(new Error('Failed to load Google SDK'));
  }

  initGoogleAuthTask = dropTask(async () => {
    await waitForQueue('afterRender');
    let btnContainer = document.getElementById(this.btnContainerId);
    if (this.args.shouldUseInitFailureFallback) {
      return;
    }
    try {
      window.addEventListener('message', this._onHandleMessagesFromGoogleIframe);
      window.google?.accounts?.id?.initialize?.({
        client_id: ENV.googleAuth.clientID,
        callback: this._handleCredentialResponse,
        context: 'signin',
      });
      window.google?.accounts?.id?.renderButton?.(btnContainer, {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        locale: this.localeManager.locale,
        shape: 'square',
        logo_alignment: 'center',
        width: 350,
      });
    } catch (error) {
      this.args.onError?.(error);
    }
  });
}
