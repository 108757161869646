/* import __COLOCATED_TEMPLATE__ from './empty.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class CardsTabsEmptyComponent extends Component {
  lottiePlayer = LottiePlayer;

  animationReady = false;
}
