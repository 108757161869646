export default {
  "tile-layout": "ek",
  "focus": "eJ",
  "no-shadow": "es",
  "tile-layout--start-verification": "eF",
  "header": "eg",
  "headings": "eD",
  "management": "eV",
  "logo": "eL",
  "title": "eT title-4",
  "name": "ez title-4",
  "loading": "ea",
  "menu-item": "eH body-2",
  "close-account": "eO",
  "content": "em"
};
