export default {
  "cell": "Eu caption-bold",
  "empty": "dS",
  "transaction": "dc",
  "member": "dq",
  "method": "dZ",
  "operation-date": "dR",
  "amount": "dQ",
  "label": "de caption-bold",
  "active": "dB"
};
