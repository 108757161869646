/* import __COLOCATED_TEMPLATE__ from './integration-compact-header.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

import { INTEGRATION_SOURCES } from 'qonto/constants/connect';

export default class ConnectIntegrationCompactHeaderComponent extends Component {
  source = INTEGRATION_SOURCES.MARKETPLACE;

  badgeHighlight = BadgeHighlight;
}
