export default {
  "row": "LQ body-1",
  "active": "Le",
  "cell": "LB",
  "empty": "LE",
  "connection": "Ld",
  "connection-avatar": "LI",
  "connection-name": "Lt",
  "member-name": "LM body-2",
  "member-team": "LP caption",
  "status": "Ll",
  "failure": "LX",
  "error": "LC",
  "hidden": "Lk"
};
