/* import __COLOCATED_TEMPLATE__ from './hero.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { Recycled } from 'qonto/react/components/badge/recycled';

export default class CardComparisonModalHero extends Component {
  Recycled = Recycled;

  @service intl;
  @service abilities;

  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get subtitle() {
    switch (this.args.cardLevel) {
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.details.subtitle.standard');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.details.subtitle.plus');
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.details.subtitle.metal');
      default:
        return null;
    }
  }

  get title() {
    switch (this.args.cardLevel) {
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.details.title.standard');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.details.title.plus');
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.details.title.metal');
      default:
        return null;
    }
  }

  get metalAssets() {
    return {
      hero: '/illustrations/cards/metal/card-inclined.webp',
      design: '/illustrations/cards/metal/metal-card-design.webp',
    };
  }

  @action
  handleIntersect(entries) {
    for (let entry of entries) {
      this.footerVisible = !entry.isIntersecting;
    }
  }
}
