/* import __COLOCATED_TEMPLATE__ from './permissions-details.hbs'; */
import { assert } from '@ember/debug';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsPermissionsDetailsComponent extends Component {
  @service intl;

  constructor() {
    super(...arguments);

    assert('@scopes is required', this.args.scopes);
    assert('@scopes must be an array', Array.isArray(this.args.scopes));
  }

  get membershipReadLabel() {
    let { organization } = this.args;

    return organization?.legalCountry === 'ES'
      ? this.intl.t('oauth.abilities.read-membership-spanish-market')
      : this.intl.t('oauth.abilities.read-membership');
  }

  get permissions() {
    let { scopes } = this.args;

    let labelsLookup = [
      { 'membership.read': this.membershipReadLabel },
      { 'organization.read': this.intl.t('oauth.abilities.read-organisation') },
      { 'attachments.write': this.intl.t('oauth.abilities.write-attachment') },
      { 'internal_transfers.write': this.intl.t('oauth.abilities.write-internal-transfer') },
      { 'payment.write': this.intl.t('oauth.abilities.mark-trusted') },
      { 'transfer_request.write': this.intl.t('oauth.abilities.write-transfer-request') },
      {
        'payment.write': [
          this.intl.t('oauth.abilities.create-payments-trusted'),
          this.intl.t('oauth.abilities.create-payments-any-beneficiary'),
        ],
      },
      { 'request_review.write': this.intl.t('oauth.abilities.approve-refuse-requests') },
      { 'supplier_invoice.read': this.intl.t('oauth.abilities.read-supplier-invoices') },
      { 'supplier_invoice.write': this.intl.t('oauth.abilities.write-supplier-invoices') },
      { 'team.read': this.intl.t('oauth.abilities.read-team') },
      { 'team.create': this.intl.t('oauth.abilities.write-team') },
      { 'membership.write': this.intl.t('oauth.abilities.write-membership') },
    ];

    let filteredLabels = labelsLookup.flatMap(entry => {
      let [scope, labels] = Object.entries(entry)[0];

      // Permission labels are displayed if their scope is included in the list of scopes
      return scopes.includes(scope) ? labels : [];
    });

    if (
      ['client_invoices.read', 'client_invoice.write', 'client.write', 'client.read'].every(scope =>
        scopes.includes(scope)
      )
    ) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-client-invoices-section'),
        this.intl.t('oauth.abilities.read-info-client-invoices-section'),
        this.intl.t('oauth.abilities.write-client-invoices-clients')
      );
    } else if (['client_invoices.read', 'client.read'].every(scope => scopes.includes(scope))) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-client-invoices-section'),
        this.intl.t('oauth.abilities.read-info-client-invoices-section')
      );
    } else if (['client_invoice.write', 'client.write'].every(scope => scopes.includes(scope))) {
      filteredLabels.push(this.intl.t('oauth.abilities.write-client-invoices-clients'));
    } else if (scopes.includes('client_invoices.read')) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-client-invoices-section'),
        this.intl.t('oauth.abilities.read-info-client-invoices-section')
      );
    } else if (scopes.includes('client_invoice.write')) {
      filteredLabels.push(this.intl.t('oauth.abilities.write-client-invoices-clients'));
    } else if (scopes.includes('client.write')) {
      filteredLabels.push(this.intl.t('oauth.abilities.write-invoicing-clients'));
    } else if (scopes.includes('client.read')) {
      filteredLabels.push(this.intl.t('oauth.abilities.read-info-invoicing-clients'));
    }

    if (scopes.includes('insurance_contract.read') && scopes.includes('insurance_contract.write')) {
      filteredLabels.push(this.intl.t('oauth.abilities.sync-insurance-contract'));
    }

    return filteredLabels;
  }

  get restrictions() {
    let { scopes } = this.args;

    let defaultLabels = [];

    if (!scopes.includes('membership.read')) {
      defaultLabels.push(this.intl.t('oauth.abilities.store-login-password'));
    } else {
      defaultLabels.push(this.intl.t('oauth.abilities.store-password'));
    }

    let labelsLookup = [
      {
        'payment.write': [
          this.intl.t('oauth.abilities.mark-trusted'),
          this.intl.t('oauth.abilities.create-payments'),
        ],
      },
      { 'request_review.write': this.intl.t('oauth.abilities.approve-refuse-requests') },
      { 'membership.read': this.membershipReadLabel },
    ];

    let filteredLabels = labelsLookup.flatMap(entry => {
      let [scope, labels] = Object.entries(entry)[0];

      // Restriction labels are displayed if their scope is *not* included in the list of scopes
      return !scopes.includes(scope) ? labels : [];
    });

    if (
      ['supplier_invoice.read', 'supplier_invoice.write'].every(scope => !scopes.includes(scope))
    ) {
      filteredLabels.push(
        this.intl.t('oauth.consent.read-write-list-info-supplier-invoices-section')
      );
    } else {
      if (!scopes.includes('supplier_invoice.read')) {
        filteredLabels.push(this.intl.t('oauth.abilities.read-supplier-invoices'));
      }

      if (!scopes.includes('supplier_invoice.write')) {
        filteredLabels.push(this.intl.t('oauth.abilities.write-supplier-invoices'));
      }
    }

    if (
      ['client_invoices.read', 'client_invoice.write', 'client.write', 'client.read'].every(scope =>
        scopes.includes(scope)
      )
    ) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-info-quotes'),
        this.intl.t('oauth.abilities.write-credit-notes-quotes')
      );
    } else if (['client_invoices.read', 'client.read'].every(scope => scopes.includes(scope))) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.write-client-invoices-section'),
        this.intl.t('oauth.abilities.read-list-info-quotes')
      );
    } else if (['client_invoice.write', 'client.write'].every(scope => scopes.includes(scope))) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.write-credit-notes-quotes'),
        this.intl.t('oauth.abilities.read-list-info-client-invoices-section')
      );
    } else if (scopes.includes('client_invoices.read')) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-info-quotes'),
        this.intl.t('oauth.abilities.write-credit-notes-quotes'),
        this.intl.t('oauth.abilities.write-client-invoices-clients')
      );
    } else if (scopes.includes('client_invoice.write')) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.read-list-client-invoices-section'),
        this.intl.t('oauth.abilities.read-info-client-invoices-section'),
        this.intl.t('oauth.abilities.write-credit-notes-quotes'),
        this.intl.t('oauth.abilities.read-list-info-quotes')
      );
    } else if (scopes.includes('client.read')) {
      filteredLabels.push(this.intl.t('oauth.abilities.write-invoicing-clients'));
    } else if (scopes.includes('client.write')) {
      filteredLabels.push(
        this.intl.t('oauth.abilities.write-client-invoices-section'),
        this.intl.t('oauth.abilities.read-list-client-invoices-section'),
        this.intl.t('oauth.abilities.read-info-client-invoices-section')
      );
    } else {
      filteredLabels.push(
        this.intl.t('oauth.consent.read-write-list-info-client-invoices-section')
      );
    }

    return [...defaultLabels, ...filteredLabels];
  }
}
