export default {
  "header-cell": "CB",
  "header-content": "CE caption-bold",
  "active": "Cd",
  "hidden": "CI",
  "empty": "Ct",
  "align-right": "CM",
  "no-padding": "CP",
  "request-type": "Cl",
  "amount": "CX",
  "approval": "CC"
};
