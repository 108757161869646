export default {
  "card": "ts",
  "avatar": "tF",
  "owner": "tg",
  "content": "tD",
  "identity": "tV",
  "fullname": "tL",
  "type": "tT",
  "menu-container": "tz",
  "dropdown-menu": "ta",
  "menu-item": "tH body-2",
  "delete": "tO"
};
