/* import __COLOCATED_TEMPLATE__ from './apple-auth-button.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';

/**
 * Events we listen on
 * AppleIDSignInOnSuccess payload -> https://developer.apple.com/documentation/sign_in_with_apple/signinresponsei
 * AppleIDSignInOnFailure payload -> https://developer.apple.com/documentation/sign_in_with_apple/signinerrori
 */
export const APPLE_SIGN_IN_SUCCESS = 'AppleIDSignInOnSuccess';
export const APPLE_SIGN_IN_ERROR = 'AppleIDSignInOnFailure';

/**
 * A string used we have to send to Apple when signing-in.
 * We get back that value when the user has been authentificated,
 * can be used to know if the request we send is expected one we receive.
 * https://developer.apple.com/documentation/sign_in_with_apple/clientconfigi/3230956-state
 */
export const APPLE_STATE_REQUEST = 'SignInUserAuthenticationRequest';

export default class AppleAuthButton extends Component {
  constructor() {
    super(...arguments);

    this._initAppleAuthTask.perform().catch(error => {
      this.args.onError?.(error);
    });
    document.addEventListener(APPLE_SIGN_IN_SUCCESS, this._onAppleSignInOnSuccess);
    document.addEventListener(APPLE_SIGN_IN_ERROR, this._onAppleSignInOnFailure);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    document.removeEventListener(APPLE_SIGN_IN_SUCCESS, this._onAppleSignInOnSuccess);
    document.removeEventListener(APPLE_SIGN_IN_ERROR, this._onAppleSignInOnFailure);
  }

  @action
  _onAppleSignInOnSuccess(event) {
    let { state } = event.detail.authorization;
    // We are checking that the request we send match the one we receive.
    if (APPLE_STATE_REQUEST === state) {
      let { code, id_token } = event.detail.authorization;
      this.args.onSignInSuccess?.({ jwt: id_token, code });
    } else {
      this.args.onError?.(new Error('state property is not the one expected'));
    }
  }

  @action
  _onAppleSignInOnFailure(event) {
    let { error } = event.detail;
    this.args.onError?.(new Error(error));
  }

  _initAppleAuthTask = dropTask(async () => {
    // If the SDK is not loaded no need to call Apple code.
    if (this.args.shouldUseInitFailureFallback) {
      return;
    }
    try {
      await window.AppleID?.auth?.init?.({
        clientId: ENV.appleAuth.clientID,
        redirectURI: window.location.origin,
        scope: 'email',
        state: APPLE_STATE_REQUEST,
        usePopup: true,
      });
    } catch (error) {
      this.args.onError?.(error);
    }
  });

  authWithAppleAuthTask = dropTask(async () => {
    try {
      if (this.args.shouldUseInitFailureFallback) {
        this.args.onError?.(new Error('SDK not loaded'));
      } else {
        await window.AppleID?.auth?.signIn?.();
      }
    } catch (error) {
      // Failure in authenticating with Apple may or may not result in an actual `Error` object
      let errorInstance = error instanceof Error ? error : new Error(error.error);
      this.args.onError?.(errorInstance);
    }
  });
}
