/* import __COLOCATED_TEMPLATE__ from './supplier-invoice-archive-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class DualNavSupplierInvoiceArchivePromoBoxComponent extends Component {
  @service abilities;
  @service errors;
  @service intl;
  @service modals;
  @service networkManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service supplierInvoicesInsights;
  @service toastFlashMessages;

  get overdueInvoices() {
    return this.supplierInvoicesInsights.currentInsights?.total_overdue_counter;
  }

  get shouldDisplay() {
    return (
      this.abilities.can('update supplierInvoice') &&
      this.overdueInvoices > 14 &&
      this.router.currentRouteName === 'supplier-invoices.index'
    );
  }

  get archiveCTA() {
    return this.intl.t('supplier-invoices.bulk-archive.cta', {
      count: this.overdueInvoices,
    });
  }

  @action
  showBulkArchivePopup() {
    this.segment.track('supplier-invoices_archive_clicked', { action_type: 'bulk_suggested' });

    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.archive.title', {
        count: this.overdueInvoices,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.archive.subtitle', {
        count: this.overdueInvoices,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.archive.confirm', {
        count: this.overdueInvoices,
      }),
      confirmTask: this.bulkArchiveTask,
    });
  }

  @action
  fetchAllSupplierInvoices() {
    return this.fetchSupplierInvoicesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.clearToastMessages();
        this.errors.handleError(error);
      });
  }

  fetchSupplierInvoicesTask = restartableTask(async () => {
    let invoices = [];
    invoices = await this.store.query('supplier-invoice', {
      page: 1,
      per_page: 100,
      filter: {
        status: [INVOICE_STATUSES.toReview],
        due_date: 'past_and_today',
      },
    });

    let pages = invoices?.meta?.total_pages || 1;

    for (let page = 2; page <= pages; page++) {
      invoices = [
        ...invoices,
        ...(await this.store.query('supplier-invoice', {
          page,
          per_page: 100,
          filter: {
            status: [INVOICE_STATUSES.toReview],
            due_date: 'past_and_today',
          },
        })),
      ];
    }

    return invoices;
  });

  get peekedOverdueSupplierInvoices() {
    let allInvoices = this.fetchSupplierInvoicesTask.lastSuccessful?.value || [];
    let idsArray = [];

    let overdueInvoices = allInvoices.filter(invoice => invoice.totalAmount !== null);

    overdueInvoices.map(invoice => idsArray.push(invoice.id));

    return idsArray;
  }

  bulkArchiveTask = dropTask(async close => {
    await this.fetchAllSupplierInvoices();

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.peekedOverdueSupplierInvoices,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.success', {
          count: this.overdueInvoices,
        })
      );
      this.router.replaceWith('supplier-invoices.index');
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });
}
