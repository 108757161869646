/* import __COLOCATED_TEMPLATE__ from './duration-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const DURATION_TYPE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

export default class DirectDebitCollectionsNewDurationSelectionComponent extends Component {
  @service intl;

  durationOptions = [
    {
      value: DURATION_TYPE.DEFAULT,
      label: this.intl.t('sdd-collections.duration.no-end-date'),
      clearable: false,
    },
    {
      value: DURATION_TYPE.CUSTOM,
      label: this.intl.t('sdd-collections.duration.number-of-payments'),
      clearable: true,
    },
  ];

  get selectedDurationOption() {
    return this.args.duration.isCustomDuration ? this.durationOptions[1] : this.durationOptions[0];
  }

  @action
  onUpdateDuration(durationOption) {
    this.args.onUpdateDuration({
      scheduleTotalCollectionCount: null,
      isCustomDuration: durationOption.value === DURATION_TYPE.CUSTOM,
    });
  }

  @action
  onUpdateScheduleTotalCollectionCount(scheduleTotalCollectionCount) {
    this.args.onUpdateDuration({
      scheduleTotalCollectionCount,
      isCustomDuration: this.args.duration.isCustomDuration,
    });
  }
}
