/* import __COLOCATED_TEMPLATE__ from './capital-deposit-item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { gt, reads } from 'macro-decorators';

export default class CapitalDepositItem extends Component {
  @service intl;

  @reads('args.shareholder.capitalAmount') capitalPromised;
  @reads('args.shareholder.bankAccount.balance') accountBalance;

  @gt('accountBalance', 0) isCapitalUnderDepositProcess;

  get isCapitalDeposited() {
    return this.capitalPromised === this.accountBalance;
  }

  get pickTooltipInfo() {
    switch (true) {
      case this.accountBalance > this.capitalPromised:
        return this.intl.t('on_boarding.tooltip.exceeding-amount', {
          deposit_account_exceeding_amount: this.intl.formatMoney(this.accountBalance),
          amount_back: this.intl.formatMoney(this.accountBalance - this.capitalPromised),
        });
      case this.isCapitalDeposited:
        return this.intl.t('on_boarding.tooltip.complete', {
          amount: this.intl.formatMoney(this.accountBalance),
        });
      case this.isCapitalUnderDepositProcess:
        return this.intl.t('on_boarding.tooltip.mismatch', {
          amount_remained: this.intl.formatMoney(this.capitalPromised - this.accountBalance),
        });
      default:
        return this.intl.t('on_boarding.tooltip.start', {
          amount: this.intl.formatMoney(this.capitalPromised),
        });
    }
  }
}
