export default {
  "cockpit-list": "zv",
  "cockpit-item": "zo",
  "cockpit-item-tile": "zn",
  "guarding-title": "zi",
  "guarding-title-icon": "zA",
  "subtitle-link": "zY",
  "subtitle-wrapper": "zh",
  "subtitle-tooltip": "zf"
};
