/* import __COLOCATED_TEMPLATE__ from './accounting-hub-switcher.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import { findInList, isAccountant, not } from 'qonto/components/navigation-dropdown/org-list';
import { registerJsURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { NavigationDropdownFooter } from 'qonto/react/components/navigation-dropdown/footer';

const TABS = Object.freeze({
  MY_CLIENTS: 'my-clients',
  MY_BUSINESS: 'my-business',
});

export default class DualNavAccountingHubSwitcherComponent extends Component {
  @service organizationManager;
  @service userManager;

  @tracked selectedTab =
    this.organizationManager.membership.role === ROLES.REPORTING
      ? TABS.MY_CLIENTS
      : TABS.MY_BUSINESS;

  TABS = TABS;
  registerJsURL = registerJsURL;
  Footer = NavigationDropdownFooter;

  @action
  selectTab(tab) {
    this.selectedTab = tab;
  }

  get isMyClientsSelected() {
    return this.selectedTab === TABS.MY_CLIENTS;
  }

  get isMyBusinessSelected() {
    return !this.isMyClientsSelected;
  }

  get orgListFilterMode() {
    return this.isMyClientsSelected ? 'include' : 'exclude';
  }

  get selectedTabId() {
    return this.isMyClientsSelected ? TABS.MY_CLIENTS : TABS.MY_BUSINESS;
  }

  get userHasOnlyClients() {
    if (this.isMyClientsSelected) return false;

    if (variation('feature--boolean-login-improvement')) {
      return this.organizationManager.organizations.every(
        organization => organization.membershipRole === ROLES.REPORTING
      );
    } else {
      let organizations = this.userManager.currentUser.organizationsEnabled;

      let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);
      let findMatchingMember = findInList(userMembershipsIds);
      let ownBusinesses = organizations.filter(organization =>
        organization.get('memberships').find(findMatchingMember(not(isAccountant)))
      );
      return ownBusinesses.length === 0;
    }
  }
}
