export default {
  "tooltip": "xu",
  "wrapper": "wS",
  "collapsed": "wc",
  "label": "wq body-1",
  "selected": "wZ",
  "icon-wrapper": "wR",
  "icon": "wQ",
  "locked-icon": "we",
  "notification-count": "wB",
  "fade-in": "wE",
  "responsive": "wd"
};
