/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class CapitalDepositLegalEntityCard extends Component {
  @action
  handleDelete(toggle) {
    this.args.onDelete();
    toggle();
  }
}
