/* import __COLOCATED_TEMPLATE__ from './pagopa.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class AttachmentsSidebarBookkeepingPagopaComponent extends Component {
  @service abilities;

  get showAnalyticLabels() {
    return this.abilities.can('access custom-label') && this.abilities.can('read custom-label');
  }
}
