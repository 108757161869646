/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import { GMI_STATUSES_FAILED_STATUSES } from 'qonto/constants/gmi-solution-instance';

import styles from './disclaimer.module.css';

export default class GmiSolutionInstanceDisclaimerComponent extends Component {
  @service gmiSolutionInstanceManager;
  @service store;

  styles = styles;

  disclaimerBlock = Disclaimer.Block;

  constructor() {
    super(...arguments);
    this.gmiSolutionInstanceManager.subscribe();
  }

  get disclaimerType() {
    let gmiSolutionDetails = this.store.peekAll('gmi-solution-instance').find(({ gmiStatus }) => {
      return GMI_STATUSES_FAILED_STATUSES.includes(gmiStatus);
    });

    if (!gmiSolutionDetails) return null;

    return {
      troubleshootingUrl: gmiSolutionDetails.troubleshootingUrl,
      status: gmiSolutionDetails.gmiStatus,
    };
  }

  @action
  handleClick() {
    if (this.disclaimerType?.troubleshootingUrl) {
      this.popup = window.open(this.disclaimerType.troubleshootingUrl, 'gmi', 'popup');
    }
  }
}
