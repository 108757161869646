export default {
  "row": "qr body-1",
  "active": "qb",
  "cell": "qy",
  "empty": "qU",
  "ellipsis": "qj",
  "text-secondary": "qW",
  "row-sidebar": "qu",
  "cell-conditions": "ZS",
  "animated": "Zc",
  "fadein-item": "Zq",
  "animated-cell": "ZZ",
  "pr-24": "ZR",
  "rule-with-error": "ZQ",
  "icon": "Ze"
};
