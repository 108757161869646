export default {
  "container": "Mx",
  "is-sidebar-content": "Mw",
  "no-sep": "MN",
  "details": "Mv",
  "detail": "Mo",
  "name": "Mn",
  "content": "Mi",
  "content-text": "MA",
  "break-word": "MY",
  "primary": "Mh",
  "links-container": "Mf",
  "infos-container": "MK",
  "infos-disclaimer": "MG",
  "upsell-container": "Mr"
};
