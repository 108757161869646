/* import __COLOCATED_TEMPLATE__ from './association-kyb-pending-modal.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { alias } from 'macro-decorators';

export default class AssociationKybPendingModal extends Component {
  @service organizationManager;

  @alias('organizationManager.organization.associationTypeformUrl') associationTypeformUrl;
}
