/* import __COLOCATED_TEMPLATE__ from './custom-period.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

export default class BookkeepingPeriodSelectorCustomPeriodComponent extends Component {
  @tracked startDate = this.args.startDate;
  @tracked endDate = this.args.endDate;

  @action
  updateStartDate(newValue) {
    this.startDate = newValue;
    if (this.startDate && this.endDate && !dayjs(this.startDate).isBefore(this.endDate)) {
      this.endDate = dayjs(this.startDate).add(1, 'day').format('YYYY-MM-DD');
    }
  }

  @action
  updateEndDate(newValue) {
    this.endDate = newValue;
  }

  @action
  handleSubmit(e) {
    e.preventDefault();
    this.args.onUpdate({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  get canSubmit() {
    if (!this.startDate || !this.endDate) {
      return false;
    }
    if (this.startDate === this.args.startDate && this.endDate === this.args.endDate) {
      return false;
    }
    return true;
  }

  get minEndDate() {
    if (this.startDate) {
      return dayjs(this.startDate).add(1, 'day').format('YYYY-MM-DD');
    } else {
      return null;
    }
  }
}
