/* import __COLOCATED_TEMPLATE__ from './checkpoint.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class CheckpointComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;

  get translationStrings() {
    let { creditorIdentifierRequested } = this.args;

    if (creditorIdentifierRequested) {
      return {
        title: this.intl.t('sdd-collections.activation-request-checkpoint.title'),
        subtitle: this.intl.t('sdd-collections.activation-request-checkpoint.subtitle'),
      };
    }

    return {
      title: this.intl.t('sdd-collections.activation-verification-checkpoint.title'),
      subtitle: this.intl.t('sdd-collections.activation-verification-checkpoint.subtitle'),
    };
  }
}
