export default {
  "budget-edit-form": "da",
  "period-label": "dH",
  "periods": "dO",
  "yearly": "dm",
  "tooltips": "dp",
  "year": "dx",
  "total-budget": "dw",
  "total-budget-value": "dN",
  "divider": "dv",
  "buttons": "do"
};
