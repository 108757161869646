export default {
  "status-avatar": "Fa mr-16",
  "row": "FH",
  "active": "FO",
  "animated": "Fm",
  "fadein-item": "Fp",
  "cell": "Fx",
  "cell-content": "Fw",
  "disabled": "FN",
  "not-disabled": "Fv",
  "cell-amount": "Fo",
  "ghost": "Fn",
  "validated": "Fi",
  "status-pin": "FA",
  "item-emitter-name": "FY",
  "item-emitter-name-text": "Fh"
};
