/* import __COLOCATED_TEMPLATE__ from './card-features.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardComparisonModalCardFeatures extends Component {
  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }
}
