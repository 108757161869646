/* import __COLOCATED_TEMPLATE__ from './beneficiary-iban-field.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class BankAccountBeneficiaryFormComponent extends Component {
  @service segment;

  @action
  handleAddBeneficiary() {
    this.segment.track('transfer_create_beneficiary_edit_redirection');
    this.args.switchToAddBeneficiaryTask.perform().catch(ignoreCancelation);
  }

  @action
  handleUpdateBeneficiary() {
    this.segment.track('transfer_edit_beneficiary_existing_benef_redirection');
    this.args.switchToUpdateBeneficiaryTask.perform().catch(ignoreCancelation);
  }
}
