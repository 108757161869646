/* import __COLOCATED_TEMPLATE__ from './managers-card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';

export default class BudgetDetailsManagersCardComponent extends Component {
  @service intl;

  get managersList() {
    let { memberships, count } = this.args.managers;
    let translationVars = {
      count,
      managerNameOne: memberships[0]?.fullName,
      managerNameTwo: memberships[1]?.fullName,
      remaining: count - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (count === 2) {
      return this.intl.t('team-budgets.section.card.two-supervisors.label', translationVars);
    }

    return this.intl.t('team-budgets.section.card.supervisors.label', translationVars);
  }
}
