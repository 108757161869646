/* import __COLOCATED_TEMPLATE__ from './account-close-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ACCOUNT_TYPE } from 'qonto/constants/bank-account';

export default class AccountCloseModal extends Component {
  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service sensitiveActions;
  @service segment;

  @reads('args.data.remuneratedAccountDetails') remuneratedAccountDetails;

  closeAccountTask = task(async () => {
    await this.args.data.bankAccount.close();
    let successMessage = this.intl.t('bank-accounts.close.success-toast');
    this.toastFlashMessages.toastSuccess(successMessage);
  });

  get title() {
    if (this.args.data.bankAccount.accountType === ACCOUNT_TYPE.REMUNERATED) {
      return this.intl.t('accounts.remunerated.closure.review.title');
    }

    return this.intl.t('bank-accounts.close-modal.title');
  }

  get noRemuneration() {
    return this.remuneratedAccountDetails.currentMaturity < this.noRemunerationDay;
  }

  get noRemunerationDay() {
    return this.remuneratedAccountDetails.interestRates.at(1).start_day;
  }

  get currentMaturityRate() {
    let currentMaturity = this.remuneratedAccountDetails.currentMaturity;
    return this.remuneratedAccountDetails.interestRates.find(
      ({ start_day, end_day }) => start_day <= currentMaturity && end_day >= currentMaturity
    )?.rate;
  }

  checkAccountCanBeClosedTask = dropTask(async () => {
    try {
      let { closure_restrictions: closureRestrictions } =
        await this.args.data.bankAccount.closureRestrictions();

      if (closureRestrictions.length) {
        this.modals.open('bank-accounts/account-close-error-modal', {
          closureRestrictions,
        });
        this.args.close();
      } else {
        await this.sensitiveActions.runTask.perform(this.closeAccountTask);
        let { slug, accountType: account_type } = this.args.data.bankAccount;
        this.segment.track('bank_account_closed', {
          slug,
          account_type,
        });
        this.args.close();
      }
    } catch {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      this.args.close();
    }
  });

  @action
  cancel() {
    if (this.checkAccountCanBeClosedTask.isRunning) {
      return;
    }
    this.args.close();
  }
}
