export default {
  "aside": "Zv",
  "aside-section": "Zo",
  "aside-content": "Zn",
  "aside-footer": "Zi",
  "aside-title": "ZA caption-bold",
  "accountant-upsell": "ZY",
  "file-status": "Zh",
  "file-status-icon": "Zf",
  "file-status-text": "ZK",
  "file-status-label": "ZG body-1",
  "file-status-description": "Zr body-2"
};
