export default {
  "row": "mF",
  "icon": "mg",
  "cell": "mD",
  "empty": "mV",
  "no-padding": "mL",
  "cell-content": "mT",
  "right": "mz",
  "cube": "ma"
};
