/* import __COLOCATED_TEMPLATE__ from './attachments-suggested.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

const INVOICE_ALREADY_MATCHED_ERROR_CODE = 'transaction_attachment_exists';

export default class AttachmentsSuggestedComponent extends Component {
  @service intl;
  @service store;
  @service segment;
  @service toastFlashMessages;
  @service sentry;
  @service networkManager;
  @service supplierInvoicesInsights;
  @service abilities;

  @tracked selectedReceivableInvoiceId;
  @tracked selectedSupplierInvoiceId;

  @tracked invoicesCurrentPage = 1;
  @tracked invoicesTotalCount;
  @tracked invoicesTotalPages;
  @tracked invoicesPerPage;

  @tracked selectedPeriod;

  @tracked invoicesSearchQuery = '';
  @tracked dateFrom;
  @tracked dateTo;
  @tracked receivableInvoicesStats = null;

  constructor() {
    super(...arguments);
    /* eslint-disable ember-concurrency/no-perform-without-catch */
    this.fetchInvoicesTask.perform();
    if (
      this.abilities.can('access supplier-invoice') &&
      this.abilities.can('view supplier-invoice') &&
      this.abilities.can('read receivable-invoice')
    ) {
      this.fetchInvoicesStatsTask.perform();
    }
    /* eslint-enable ember-concurrency/no-perform-without-catch */
  }

  /**
   * @experimental
   * Do not reuse this getter. It is experimental and it is not tested
   */
  get showCreditNoteExperimentalSidebar() {
    return variation('experiment--boolean-ap-credit-notes') && this.args.creditNote?.isCreditNote;
  }

  fetchInvoicesStatsTask = dropTask(async () => {
    try {
      await this.supplierInvoicesInsights.fetchInsights();
      this.receivableInvoicesStats = await this.store
        .modelFor('receivable-invoice')
        .getStats(this.store);
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasInvoices() {
    if (this.isFetchingStats) {
      return false;
    }

    if (this.isDebitTransaction) {
      return this.supplierInvoicesStats;
    } else {
      return this.receivableInvoicesStats?.created?.total > 0;
    }
  }

  @action supplierInvoicesStats() {
    let {
      inbox_status_counter,
      paid_status_counter,
      pending_status_counter,
      scheduled_status_counter,
    } = this.supplierInvoicesInsights.currentInsights;

    return (this.hasSupplierInvoices =
      inbox_status_counter > 0 ||
      paid_status_counter > 0 ||
      pending_status_counter > 0 ||
      scheduled_status_counter > 0);
  }

  get isDebitTransaction() {
    return this.args.transaction.side === 'debit';
  }

  get supplierInvoices() {
    if (this.showCreditNoteExperimentalSidebar) {
      return this.fetchCreditNoteRelatedInvoicesTask.lastSuccessful?.value || [];
    }
    return this.fetchSupplierInvoicesTask.lastSuccessful?.value || [];
  }

  get receivableInvoices() {
    return this.fetchReceivableInvoicesTask.lastSuccessful?.value || [];
  }

  get file() {
    if (!this.selectedSupplierInvoiceId && !this.selectedReceivableInvoiceId) {
      return null;
    }

    if (this.isDebitTransaction || this.showCreditNoteExperimentalSidebar) {
      return this.supplierInvoices
        .find(invoice => invoice.id === this.selectedSupplierInvoiceId)
        ?.get('attachment.file');
    } else {
      return this.receivableInvoices
        .find(invoice => invoice.id === this.selectedReceivableInvoiceId)
        ?.attachment?.get('file');
    }
  }

  get isSearchError() {
    return Boolean(
      (this.invoicesSearchQuery || this.selectedPeriod) &&
        (this.fetchSupplierInvoicesTask.last?.isError ||
          this.fetchReceivableInvoicesTask.last?.isError)
    );
  }

  get hasNoInvoiceSelected() {
    return (
      this.hasInvoices &&
      ((this.isDebitTransaction && !this.selectedSupplierInvoiceId) ||
        (!this.isDebitTransaction && !this.selectedReceivableInvoiceId))
    );
  }

  get isLoading() {
    return this.fetchInvoicesTask.isRunning || this.fetchInvoicesStatsTask.isRunning;
  }

  searchTask = dropTask(async query => {
    this.invoicesSearchQuery = query;

    this.invoicesCurrentPage = 1;
    this.selectedSupplierInvoiceId = null;
    this.selectedReceivableInvoiceId = null;

    await this.fetchInvoicesTask.perform();
  });

  updatePeriodSelectionTask = dropTask(
    waitFor(async period => {
      this.selectedPeriod = period;
      this.dateFrom = period?.startDate || null;
      this.dateTo = period?.endDate || null;

      await this.fetchInvoicesTask.perform();
    })
  );

  cancelPeriodSelectionTask = dropTask(
    waitFor(async () => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(null);
    })
  );

  handlePeriodUpdateTask = dropTask(
    waitFor(async value => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(value);
    })
  );

  fetchSupplierInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (
        this.hasSuggestedAttachments &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            attachment_id: this.args.transaction.attachmentSuggestionIds,
          },
        });

        supplierInvoices = response.toArray();
        this.selectedSupplierInvoiceId = supplierInvoices[0]?.id;
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'created_at:desc',
        };

        if (this.invoicesSearchQuery !== '') {
          params.query = this.invoicesSearchQuery;
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.dateFrom && this.dateTo) {
          params.filter = {
            created_at_from: dayjs(this.dateFrom).startOf('day').toISOString(),
            created_at_to: dayjs(this.dateTo).endOf('day').toISOString(),
          };
        }

        let response = await this.store.query('supplier-invoice', params);

        supplierInvoices = response.toArray();

        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchReceivableInvoicesTask = dropTask(
    waitFor(async () => {
      let receivableInvoices;

      if (
        this.hasSuggestedAttachments &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('receivable-invoice', {
          filter: {
            attachment_id: this.args.transaction.attachmentSuggestionIds.join(),
          },
        });

        receivableInvoices = response.toArray();
        this.selectedReceivableInvoiceId = receivableInvoices[0]?.id;
        this.updatePagination(response.meta);
      } else {
        if (this.invoicesSearchQuery || (this.dateFrom && this.dateTo)) {
          let searchParams = {
            query: this.invoicesSearchQuery,
            query_fields: 'customer_name,amount,file_name,invoice_number',
            sorts: [{ field: 'due_date', direction: 'asc' }],
            pagination: {
              page: this.invoicesCurrentPage,
              per_page: 25,
            },
          };

          if (this.dateFrom && this.dateTo) {
            searchParams.filters = [
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateFrom).startOf('day').toISOString()],
                operator: 'gte',
              },
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateTo).endOf('day').toISOString()],
                operator: 'lte',
              },
            ];
          }

          let response = await this.store.adapterFor('receivable-invoice').search(searchParams);
          receivableInvoices = response.invoices;
          this.updatePagination(response.meta);
        } else {
          let response = await this.store.query('receivable-invoice', {
            page: { number: this.invoicesCurrentPage, size: 25 },
            sort: 'due_date,-number',
          });

          receivableInvoices = response.toArray();
          this.updatePagination(response.meta);
        }
      }
      return receivableInvoices;
    })
  );

  fetchCreditNoteRelatedInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (
        this.hasSuggestedAttachments &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            supplier_id: this.args.creditNote.supplierSnapshot.id,
          },
        });

        supplierInvoices = response.toArray();
        this.selectedSupplierInvoiceId = supplierInvoices[0]?.id;
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'created_at:desc',
          filter: {
            supplier_id: this.args.creditNote.supplierSnapshot.id,
            exclude_credit_notes: true,
          },
        };

        if (this.invoicesSearchQuery !== '') {
          params.query = this.invoicesSearchQuery;
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.dateFrom && this.dateTo) {
          params.filter = {
            ...params.filter,
            created_at_from: dayjs(this.dateFrom).startOf('day').toISOString(),
            created_at_to: dayjs(this.dateTo).endOf('day').toISOString(),
          };
        }

        let response = await this.store.query('supplier-invoice', params);

        supplierInvoices = response.toArray();

        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchInvoicesTask = dropTask(async () => {
    try {
      // To be removed with experiment--boolean-ap-credit-notes
      if (this.args.creditNote) {
        return await this.fetchCreditNoteRelatedInvoicesTask.perform();
      }
      if (this.isDebitTransaction) {
        await this.fetchSupplierInvoicesTask.perform();
      } else {
        await this.fetchReceivableInvoicesTask.perform();
      }
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  handleChangeInvoicesPageTask = dropTask(
    waitFor(async pageNumber => {
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      this.invoicesCurrentPage = pageNumber;

      await this.fetchInvoicesTask.perform();
    })
  );

  linkSuggestedAttachmentTask = dropTask(
    waitFor(async () => {
      if (this.showCreditNoteExperimentalSidebar) {
        try {
          // For the example this API always return 200
          await this.networkManager.request(
            `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${this.args.creditNote.id}/link_related_invoice`,
            {
              method: 'POST',
              body: JSON.stringify({
                related_invoice_id: this.selectedSupplierInvoiceId,
              }),
            }
          );
          // eslint-disable-next-line no-empty
        } catch {}
        let selectedSupplierInvoice = await this.store.findRecord(
          'supplier-invoice',
          this.selectedSupplierInvoiceId
        );

        // Optimistically update the credit note status
        this.args.creditNote.relatedInvoices[0] = {
          id: this.selectedSupplierInvoiceId,
          invoice_number: selectedSupplierInvoice.invoiceNumber,
          total_amount: selectedSupplierInvoice.totalAmount,
        };

        this.args.creditNote.status = INVOICE_STATUSES.paid;

        this.args.onClose();
        return;
      }
      this.segment.track('find_on_qonto_button_match_clicked');

      try {
        let attachment;

        if (this.isDebitTransaction) {
          let selectedSupplierInvoice = await this.store.findRecord(
            'supplier-invoice',
            this.selectedSupplierInvoiceId
          );

          attachment = await selectedSupplierInvoice.attachment;
        } else {
          let selectedReceivableInvoice = await this.store.findRecord(
            'receivable-invoice',
            this.selectedReceivableInvoiceId
          );

          attachment = await selectedReceivableInvoice.attachment;
        }

        if (!this.args.transaction.attachments.includes(attachment)) {
          this.args.transaction.attachments.push(attachment);
        }

        await this.args.transaction.linkAttachment([attachment]);

        this.args.onClose();

        this.toastFlashMessages.toastInfo(this.intl.t('find-invoice-modal.match.success.toast'));
      } catch (error) {
        let isInvoiceAlreadyMatchedError =
          error.status === 422 &&
          error.errors?.some(({ detail }) => detail?.code === INVOICE_ALREADY_MATCHED_ERROR_CODE);

        if (isInvoiceAlreadyMatchedError) {
          this.segment.track('find_on_qonto_button_attachments-link-re-attempted');

          this.toastFlashMessages.toastError(
            this.intl.t('find-invoice-modal.match.error-already-matched.toast')
          );
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    })
  );

  updatePagination(meta) {
    this.invoicesTotalCount = meta.total_count || meta.total;
    this.invoicesCurrentPage = meta.current_page;
    this.invoicesTotalPages = meta.total_pages;
    this.invoicesPerPage = meta.per_page;
  }
}
