export default {
  "card-sidebar-header": "MM",
  "content": "MP",
  "card-image-container": "Ml",
  "card-image": "MX",
  "legacy": "MC",
  "type": "Mk",
  "type-container": "MJ",
  "type-close": "Ms",
  "type-status-awaiting": "MF",
  "type-status-live": "Mg",
  "type-status-paused": "MD",
  "type-status-ghost": "MV",
  "actions-panel": "ML",
  "actions-panel-header": "MT",
  "action-btn": "Mz",
  "disclaimer": "Ma",
  "infobox": "MH",
  "btn-panel": "MO",
  "required-action-btn": "Mm",
  "kyc-disclaimer": "Mp"
};
