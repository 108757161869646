export default {
  "header-cell": "kK",
  "border-top": "kG",
  "header-content": "kr caption-bold",
  "active": "kb",
  "empty": "ky",
  "card": "kU",
  "card-holder": "kj",
  "limit": "kW",
  "align-right": "ku",
  "status": "JS",
  "hidden": "Jc",
  "no-padding": "Jq"
};
