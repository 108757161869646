export default {
  "wrapper": "lj",
  "content-wrapper": "lW",
  "no-fee": "lu",
  "concierge": "XS",
  "no-fee-item": "Xc",
  "concierge-item": "Xq",
  "no-fee-icon": "XZ",
  "lounge-icon": "XR",
  "insurance-conditions": "XQ",
  "description": "Xe",
  "footer-detail": "XB",
  "title": "XE",
  "footer-price": "Xd",
  "footer-content": "XI",
  "lounge-text": "Xt",
  "lounge": "XM",
  "lounge-images": "XP",
  "img": "Xl",
  "footer-sticky-bar": "XX",
  "visible": "XC",
  "content": "Xk",
  "content-card": "XJ"
};
