/* import __COLOCATED_TEMPLATE__ from './remove-restrictions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { apiAction } from '@mainmatter/ember-api-actions';
import { task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardModalsRemoveRestrictionsComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service store;
  @service segment;

  confirmTask = task(async () => {
    let { card, resetType } = this.args.data;
    this.segment.track('reset_advanced-card-settings_confirmed', {
      reset_type: resetType,
    });
    let data = { card: {} };
    if (resetType === 'days-of-use') {
      data.card.active_days = [0, 1, 2, 3, 4, 5, 6];
    } else if (resetType === 'spending-categories') {
      data.card.category_tags = [];
    }
    try {
      let response = await apiAction(card, { method: 'PATCH', path: 'restrictions', data });
      this.store.pushPayload(response);
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.success.restrictions-removed'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
    this.args.close();
  });
}
