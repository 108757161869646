/* import __COLOCATED_TEMPLATE__ from './compare.hbs'; */
import Component from '@glimmer/component';

import { CARD_FEES, CARD_LEVELS, CARD_WITHDRAW_FEE } from 'qonto/constants/cards';
import { Recycled } from 'qonto/react/components/badge/recycled';

export default class CardComparisonModalCompare extends Component {
  Recycled = Recycled;

  CARD_LEVELS = CARD_LEVELS;
  CARD_FEES = CARD_FEES;
  CARD_WITHDRAW_FEE = CARD_WITHDRAW_FEE;
}
