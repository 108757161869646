/* import __COLOCATED_TEMPLATE__ from './amount-limit.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, waitForQueue } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardSettingsAmountLimitComponent extends Component {
  @service sentry;

  constructor() {
    super(...arguments);
    this.setAutoFocusTask.perform().catch(ignoreCancelation);
  }

  setAutoFocusTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      if (!this.args.autofocusDisabled) {
        let inputElement = document.getElementById('amount-input');
        next(() => inputElement.focus());
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  onChange(value) {
    let amountLimit;

    if (value === '') {
      amountLimit = null;
    } else {
      amountLimit = Number(value);
    }

    if (amountLimit >= 0) {
      this.args.onChange(amountLimit);
    }
  }
}
