/* import __COLOCATED_TEMPLATE__ from './application-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import { ApplicationCardContent } from 'qonto/react/components/connect/application-card-content';

export default class ApplicationCardComponent extends Component {
  BADGE_TYPE = BADGE_TYPE;

  @service segment;
  @service intl;

  applicationData = null;
  applicationCardContent = ApplicationCardContent;

  constructor() {
    super(...arguments);
    this.applicationData = this.args.application?.data;
  }

  @action fireTrackingEvent() {
    let { filteredBy, appliedFilters, organization } = this.args;
    let { name, integration_type, tray_solution_id } = this.applicationData;

    this.segment.track('connect_app_card_clicked', {
      legalCountry: organization.legalCountry,
      integrationName: name,
      integrationType: integration_type,
      solutionId: tray_solution_id,
      filteredBy,
      ...(appliedFilters && {
        category: appliedFilters.category?.slug,
        type: appliedFilters.type?.slug,
        designedFor: appliedFilters.stakeholder?.slug,
      }),
    });
  }
}
