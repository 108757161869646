/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';
import window from 'ember-window-mock';

export default class DualNavSubMenuLinkComponent extends Component {
  badge = Badge;

  isHoverSupported = window.matchMedia?.('(any-hover: hover)').matches;

  @service segment;

  constructor() {
    super(...arguments);
    let { label, link } = this.args;

    assert('<DualNav::SubMenu::Link /> must have a @label', label);
    assert('<DualNav::SubMenu::Link /> must have a @link', link);
  }

  get shouldShowQuickAction() {
    return this.isHoverSupported && Boolean(this.args.quickAction);
  }

  @action
  handleClick(event) {
    /**
     * link: @type {ember UILink}
     */
    let { link, onClick } = this.args;

    link.transitionTo?.(event);
    onClick?.();
  }

  @action
  handleQuickActionTracking(eventName) {
    this.segment.track(eventName);
  }
}
