/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class BeneficiaryUploadConfirmComponent extends Component {
  @service router;
  @service toastFlashMessages;
  @service intl;

  abortRoute = 'beneficiaries.multi.review';
  confirmRoute = 'transfers.new';

  submitTask = dropTask(async (multiBeneficiary, closeModal) => {
    await multiBeneficiary.submit();

    let message = this.intl.t('toasts.upload_beneficiaries_success', {
      count: multiBeneficiary.selectedBeneficiariesCount,
    });
    this.toastFlashMessages.toastSuccess(message);
    closeModal('submit');
    this.router.transitionTo(this.confirmRoute);
  });
}
