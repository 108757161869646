/* import __COLOCATED_TEMPLATE__ from './periodic-update-banner.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PeriodicUpdateBanner extends Component {
  @service('segment') segment;

  @action
  ctaClicked() {
    this.segment.track('kyc_update_banner_clicked', { action: 'cta' });
    this.args.buttonAction?.();
  }

  @action
  close() {
    this.segment.track('kyc_update_banner_clicked', { action: 'close' });
    this.args.close();
  }
}
