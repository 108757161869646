/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsBanksSidebarComponent extends Component {
  @service intl;

  get showCTA() {
    let { showDisconnect, showReconnect } = this.args;

    return Boolean(showDisconnect) || Boolean(showReconnect);
  }

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item2'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item3'),
    ];
  }
}
