export default {
  "header": "Pu",
  "hidden": "lS",
  "header-inline": "lc",
  "header-inline__search-bar": "lq",
  "header-bottom": "lZ",
  "margin-top": "lR",
  "header-bottom-loading": "lQ",
  "header-bottom-loading-state": "le",
  "actions__filters": "lB",
  "actions__filters__toggle-btn": "lE",
  "tabs": "ld",
  "header-inline__filters": "lI",
  "filter-ml": "lt",
  "filter-clear": "lM"
};
