export default {
  "wrapper": "Hi",
  "header": "HA",
  "status": "HY",
  "close-button": "Hh",
  "slug": "Hf",
  "category": "HK",
  "category-title": "HG",
  "activity-tag-select": "Hr",
  "footer": "Hb"
};
