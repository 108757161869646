/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class AttachmentsAttachmentViewerSidebarComponent extends Component {
  @service abilities;
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service sentry;
  @service intl;

  get hasAccountingValue() {
    let { completed, pending } = this.args.transaction;
    return completed || pending;
  }

  get canDisplayBookkeeping() {
    let { isBilling, isPagoPaPayment, reversed } = this.args.transaction;
    return (
      this.args.context === 'bookkeeping' ||
      ((this.hasAccountingValue || reversed) && !(isBilling || isPagoPaPayment))
    );
  }

  get canDisplayAccounting() {
    return (
      this.args.context === 'bookkeeping' ||
      (this.hasAccountingValue && this.abilities.can('qualify for accounting transactions'))
    );
  }

  get canDisplayAccountingReview() {
    return this.canDisplayAccounting && !this.args.transaction.isPagoPaPayment;
  }

  @action
  saveLabel(labelList, label) {
    this.args.saveLabel(labelList, label, 'bookkeeping_details');
  }

  @action
  trackToggleShowMore() {
    this.segment.track('transaction_analytic_label_expand_clicked', {
      source: 'bookkeeping_details',
    });
  }

  submitQualifiedTask = dropTask(async () => {
    let transactionIds = [this.args.transaction.id];
    let qualified = !this.args.transaction.qualifiedForAccounting;

    try {
      await this.args.markAsReviewTask(qualified, transactionIds);

      if (qualified) {
        this.segment.track('attachment_modale_transaction_mark_as_verified_succeeded');
        this.args.close();

        return this.toastFlashMessages.toastSuccess(
          this.intl.t('attachment-viewer.verified.footer.qualified.toast.success')
        );
      } else {
        this.segment.track('attachment_modale_transaction_cancel_verification_succeeded');

        return this.toastFlashMessages.toastSuccess(
          this.intl.t('attachment-viewer.verified.footer.disqualified.toast.success')
        );
      }
    } catch (error) {
      this.sentry.captureException(
        new Error(`Transaction submit qualified triggered an error`, { cause: error })
      );

      await this.toastFlashMessages.toastError(
        this.intl.t('attachment-viewer.bookkeeping.generic-error.toast')
      );
    }
  });
}
