/* import __COLOCATED_TEMPLATE__ from './client-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

import { CLIENT_KINDS } from 'qonto/constants/clients';
import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
} from 'qonto/constants/countries-to-locales';
import italianProvinces from 'qonto/constants/it-provinces';

export default class DirectDebitCollectionsClientFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service segment;
  @service organizationManager;
  @service intl;

  CLIENT_KINDS = CLIENT_KINDS;

  get client() {
    return this.args.client;
  }

  get isItalianOrga() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  @cached
  get provinceObjects() {
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get provinceObject() {
    return this.provinceObjects.find(({ key }) => key === this.client.provinceCode);
  }

  set provinceObject(provinceObject) {
    this.client.provinceCode = provinceObject?.key;
  }

  @action
  onCountryUpdate(countryCode) {
    this.client.countryCode = countryCode;

    let newLocaleCode =
      COUNTRIES_TO_LOCALES_ARRAY.find(
        ({ country_code }) => country_code === countryCode?.toLowerCase()
      )?.code ?? DEFAULT_LOCALE_CODE;

    this.client.localeObject = this.intl.getLocales.find(({ code }) => code === newLocaleCode);
  }
}
