/* import __COLOCATED_TEMPLATE__ from './page.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';
import { dropTask, waitForQueue } from 'ember-concurrency';

import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ConnectPageComponent extends Component {
  searchField = SearchFieldWithDebounce;

  @service intl;
  @service organizationManager;
  @service segment;
  @service userManager;
  @service connectManager;

  @tracked scrolledTop = true;
  @tracked detailsRouteName = 'settings.connect-hub.applications.hub-application.details.index';
  @tracked scrollableElement = null;
  @tracked isScrolling = false;

  constructor() {
    super(...arguments);

    next(() => {
      this.setScrollableElement();
    });
  }

  async setScrollableElement() {
    await waitForQueue('afterRender');

    this.scrollableElement = document.querySelector('[data-scrollable]');
  }

  onSearch = query => {
    this.args.onSearch(query);
    this.segment.track('connect_app_filtered', { keyword: query });
    this._scrollListToTop();
  };

  get showFilters() {
    let { showFilters, searchQuery } = this.args;

    return showFilters && !searchQuery;
  }

  get showHighlightedIntegrations() {
    let { highlighedIntegrations, searchQuery } = this.args;

    return highlighedIntegrations?.length && !searchQuery;
  }

  get filteredIntegrationsTitle() {
    let { searchResultsCount, filteredIntegrationsTitle, filteredIntegrations } = this.args;

    return searchResultsCount
      ? this.intl.t('connect.results-list.title', {
          count: searchResultsCount ?? filteredIntegrations.length,
        })
      : filteredIntegrationsTitle;
  }

  get showFooter() {
    return this.args.filteredIntegrations.length;
  }

  get isLoading() {
    return (
      !this.isScrolling &&
      (this.connectManager.getIntegrationsTask.isRunning ||
        this.connectManager.searchIntegrationsTask.isRunning)
    );
  }

  @action
  onCategorySelect(category) {
    this.args.onCategorySelect(category);
    this._scrollListToTop();
  }

  @action
  onStakeholderSelect(category) {
    this.args.onStakeholderSelect(category);
    this._scrollListToTop();
  }

  @action
  onFilterReset(category) {
    this.args.onFilterReset(category);
    this._scrollListToTop();
  }

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }

  _scrollListToTop() {
    let element = document.getElementById('integrations-list');

    scrollIntoView(element, { offsetTop: 32 });
  }

  onLoadMoreTask = dropTask(async () => {
    this.isScrolling = true;

    await this.args.onLoadMore();

    this.isScrolling = false;
  });
}
