export default {
  "attachments-suggested-sidebar": "RH",
  "aside-section": "RO",
  "search-section": "Rm",
  "scrolled-top": "Rp",
  "aside-title": "Rx caption-bold",
  "search-input": "Rw",
  "toolbar-row": "RN",
  "invoices-list-section": "Rv",
  "attachments-suggested-sidebar-footer": "Ro",
  "pagination-wrapper": "Rn",
  "pagination-loading": "Ri",
  "cta-wrapper": "RA",
  "cancel-button": "RY",
  "content": "Rh",
  "illustration": "Rf mb-32",
  "title": "RK mb-8 title-4",
  "subtitle": "RG body-2"
};
