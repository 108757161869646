/* import __COLOCATED_TEMPLATE__ from './fnci-result.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';

export default class ChecksFNCIResult extends Component {
  disclaimerBlock = Disclaimer.Block;

  @tracked isFNCIresultOpened = false;

  transition = fade;

  get isGreen() {
    return this.args.result.color === 'green';
  }

  get isOrange() {
    return this.args.result.color === 'orange';
  }

  get isRed() {
    return this.args.result.color === 'red';
  }

  @action
  openFNCIresult() {
    this.isFNCIresultOpened = !this.isFNCIresultOpened;
  }
}
