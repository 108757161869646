/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class FlowsApprovalWorkflowSidebarHeader extends Component {
  disclaimerInline = Disclaimer.Inline;
  @service intl;
  @service abilities;

  get shouldDisplayMilageSubtitle() {
    return (
      this.abilities.can('use mileages request') || this.abilities.can('review mileage request')
    );
  }

  get headerContent() {
    switch (this.args.requestType) {
      case 'supplier_invoice':
        return {
          icon: 'supplier-invoice',
          title: this.intl.t(
            'approval-workflows.sidebar.header.type.supplier-invoice-requests.title'
          ),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.supplier-invoice-requests.subtitle'
          ),
        };
      case 'reimbursement':
        return {
          icon: 'reimbursement',
          title: this.intl.t('approval-workflows.sidebar.header.type.reimbursement-requests.title'),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.reimbursement-requests.subtitle',
            {
              canReadMileage: this.shouldDisplayMilageSubtitle,
            }
          ),
        };
      case 'transfer':
      default:
        return {
          icon: 'feature_transfer_direct-l',
          title: this.intl.t('approval-workflows.sidebar.header.type.transfer-requests.title'),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.transfer-requests.subtitle'
          ),
        };
    }
  }
}
